import { Request } from "../config/requests";
import { getToken } from "../config/token";

const token = getToken();

export async function getPets() {
  return Request({
    method: "get",
    token: token,
    route: `api/users/v2/pets`,
  })
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      return {
        data: json.data,
        error: null,
      }
    })
    .catch((error) => {
      console.error(error);
      return {
        data: null,
        pets: null,
        error: true,
      }
    });
}