import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import { Request } from "../../../../config/requests";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { isMobile } from "react-device-detect";
import i18n from "../../../../lang/i18n";
import useTranslations from "../../../../hooks/useTranslations";
import PetImage from "../../../common/components/PetImage";
import { existingClaimsDropwodns, existingClaimsDropwodns_DE } from "../../../../config/mockData";
import useQuestionContainer from "../../../../hooks/useQuestionContainer";
import useExistingFlowCheck from "../../../../hooks/useExistingFlowCheck";

function DropdownQuestion({
  data,
  setData,
  details,
  showButtons = true,
  onUpdate,
  width,
  single = false,
}) {
  const [answer, setAnswer] = useState(details.answer || "");
  const [dropdown, setDropdown] = useState(null);
  const { getTranslatedText } = useTranslations();

  const { isExistingFlow } = useExistingFlowCheck();

  const [questionContainer, buttonContainer] = useQuestionContainer();

  const handleAnswer = (e) => {
    setAnswer(e.target.value);

    details["answer"] = e.target.value;
    if (details.answer) {
      let item = data.manual?.find((i) => i.name === details.title);
      if (item) {
        setData({
          ...data,
          manual: data.manual.map((i) =>
            i.name === details.title
              ? { ...i, value: details.answer, selected: details.selected }
              : i
          ),
        });
      } else {
        item = {
          name: details.title,
          value: details.answer,
          selected: details.selected,
        };
        setData({
          ...data,
          manual: [...data.manual, item],
        });
      }
    }
  };

  const getDropdown = () => {
    if (isExistingFlow) {
      const arrOfOptions = Object.values(existingClaimsDropwodns);

      const dropdownValues = {
        options: arrOfOptions,
        translations: {
          initial_language: "en",
          translations: { en: existingClaimsDropwodns, de: existingClaimsDropwodns_DE },
        },
      };

      setDropdown(dropdownValues);
      return;
    }

    const currentLanguage = i18n.language;
    Request({
      method: "get",
      route: `api/dropdowns/${details.selected}?lang=${currentLanguage}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setDropdown(json.data);
      });
  };

  useEffect(() => {
    getDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QuestionContainer width={width} ref={questionContainer}>
      <Box className="InvoiceTableCointainer" ref={questionContainer}>
        {showButtons && (
          <div
            className="NavButtonContainer NavButtonContainer_initial"
            style={{ margin: "0" }}
            ref={buttonContainer}
          >
            <FormButtonFM name="Back" route="back" />

            <div className="Distance" />

            <FormButtonFM
              name="Next"
              route="next"
              onUpdate={onUpdate}
              data={data}
              disabled={!answer}
            />
          </div>
        )}

        <Box className="flow-item-wrapper flow-content-spacing">
          <Box>
            <div
              className="TitleQuestion"
              style={single ? { padding: "0 40px" } : {}}
            >
              {getTranslatedText(details.title)}
              {details.description && (
                <div className="TooltipGap">
                  <Tooltip
                    title={getTranslatedText(details.description)}
                    placement="right"
                  >
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              )}
            </div>

            <div
              className="AnswersContainer"
              style={single ? { padding: "0 40px" } : {}}
            >
              <div>
                <FormControl
                  fullWidth
                  sx={{
                    padding: "0 !important",
                    height: "56px !important",
                    width: "100%",
                  }}
                >
                  {/* <InputLabel id="demo-simple-select-label">
                  {dropdown?.name}
                </InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getTranslatedText(answer, dropdown?.translations?.translations)}
                    className="dropdown-select"
                    label={getTranslatedText(dropdown?.name)}
                    onChange={handleAnswer}
                    renderValue={(selected) => (
                      <div
                        style={{
                          maxWidth: isMobile
                            ? "180px"
                            : width === "Full width"
                              ? "40vw"
                              : "17vw",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          textAlign: "start",
                        }}
                      >
                        {selected}
                      </div>
                    )}
                    MenuProps={{
                      PaperProps: {
                        className: "dropdown-select-paper",
                      },
                      classes: {
                        list: "dropdown-select-list",
                      },
                    }}
                  >
                    {dropdown?.options.map((i) => (
                      <MenuItem value={i}>
                        {getTranslatedText(
                          i,
                          dropdown?.translations?.translations
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>
          {single && <PetImage />}
        </Box>
      </Box>
    </QuestionContainer>
  );
}

export default DropdownQuestion;
