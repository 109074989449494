import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ElipseSendBill from "../../../assets/elipses/el_send_bill.svg";
import ElipseResendDocs from "../../../assets/elipses/el_resend_docs.svg";
import ElipseCostCalc from "../../../assets/elipses/el_cost_calc.svg";
import ElipseLink from "../../../assets/elipses/el_link.svg";
import ElipseReportLiab from "../../../assets/elipses/el_liability.svg";
import ArrowLeftSmall from "../../../assets/PetPage/arrow_left_dashed_small.svg";
import PandaHeadBothHands from "../../../assets/header/panda-logo-both-hands.svg";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import ModalExistingClaim from "./ModalExistingClaim";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFlowTypeToSkip, updateFlowCurrentStep, updateFlowQuestions, updateWtdModalOpen } from "../../../config/actions";
import { projectSettings } from "../../../config/setting";
import { getToken } from "../../../config/token";
import { Request } from "../../../config/requests";
import Loading from "../../../components/common/Loading";
import { showToast } from "../../../config/helpers";

function ModalWhatToDo({
  setOpenModalWhatToDo,
  petId,
}) {
  const token = getToken();
  const { t } = useTranslation();
  const router = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState(null);
  const [openModalExistingClaim, setOpenModalExistingClaim] = useState(false);
  const [isAllFlowsAvailable, setIsAllFlowsAvailable] = useState(false);

  const getAvailableFlows = async () => {
    setIsLoading(true);
    return Request({
      method: "get",
      token: token,
      route: `api/claims/claims_exist/${petId}`
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .then((json) => {
        setIsAllFlowsAvailable(json.data);
      })
      .catch((error) => {
        console.error(error);
        showToast(t(error.message || "somethingWentWrong"), { error: true });
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  function handleClose() {
    setOpenModalWhatToDo(false);
    dispatch(updateWtdModalOpen(false));
  };

  useEffect(() => {
    getAvailableFlows();
  }, [])

  return (
    <>
      <div className="modal-wtd FastShow">
        <div className="modal-wtd__background">
          {isLoading
            ? <div className="modal-wtd__background__loader">
              <Loading noText fast />
            </div>
            : <>
              <div className={`modal-wtd__button ${isMobileOnly && "mobile"} ${isTablet && "tablet"} no-pointer`}>
                <img src={PandaHeadBothHands} />
                <div
                  className={isMobileOnly ? "button_red_mobile dark" : "button_red_common dark"}
                >
                  {t("selectOption2")}
                </div>
              </div>
              <div
                className={`modal-wtd__type send_bill ${isMobileOnly && "mobile"} ${isTablet && "tablet"}`}
                onClick={() => {
                  setReportType('General');
                  dispatch(updateFlowCurrentStep(0));
                  dispatch(setSelectedFlowTypeToSkip("General"))
                  if (projectSettings.existingClaim && isAllFlowsAvailable) {
                    setOpenModalExistingClaim(true);
                  } else {
                    router(`/contact/${petId}/claim?type=${encodeURIComponent("General")}`);
                    setOpenModalWhatToDo(false);
                    dispatch(updateWtdModalOpen(false));
                  }
                }}
              >
                <img src={ElipseSendBill} />
                <span>{t("sendBill")}</span>
              </div>
              <div
                className={`modal-wtd__type report_liab ${isMobileOnly && "mobile"} ${isTablet && "tablet"}`}
                onClick={() => {
                  setReportType('Liability claim');
                  dispatch(updateFlowCurrentStep(0));
                  dispatch(setSelectedFlowTypeToSkip("Liability claim"));
                  if (projectSettings.existingClaim && isAllFlowsAvailable) {
                    setOpenModalExistingClaim(true);
                  } else {
                    router(`/contact/${petId}/claim?type=${encodeURIComponent("Liability claim")}`);
                    setOpenModalWhatToDo(false);
                    dispatch(updateWtdModalOpen(false));
                  }
                }}
              >
                <img src={ElipseReportLiab} />
                <span>{t("reportLiab")}</span>
              </div>
              {projectSettings.existingClaim &&
                <div
                  className={`modal-wtd__${isAllFlowsAvailable ? "type" : "type-disabled"} resend_docs ${isMobileOnly && "mobile"} ${isTablet && "tablet"}`}
                  onClick={() => {
                    if (isAllFlowsAvailable) {
                      dispatch(updateFlowCurrentStep(0));
                      dispatch(setSelectedFlowTypeToSkip(""));
                      router(`/contact/${petId}/claim?type=${encodeURIComponent('Existing Claim')}`);
                      setOpenModalWhatToDo(false);
                      dispatch(updateWtdModalOpen(false));
                    } else { }
                  }}
                >
                  <img src={ElipseResendDocs} />
                  <span>{t("resendDocs")}</span>
                </div>}
              <div
                className={`modal-wtd__type cost_calc ${isMobileOnly && "mobile"} ${isTablet && "tablet"}`}
                onClick={() => {
                  setReportType('Cost estimation');
                  dispatch(updateFlowCurrentStep(0));
                  dispatch(setSelectedFlowTypeToSkip("Cost estimation"));
                  if (projectSettings.existingClaim && isAllFlowsAvailable) {
                    setOpenModalExistingClaim(true);
                  } else {
                    router(`/contact/${petId}/claim?type=${encodeURIComponent("Cost estimation")}`);
                    setOpenModalWhatToDo(false);
                    dispatch(updateWtdModalOpen(false));
                  }
                }}
              >
                <img src={ElipseCostCalc} />
                <span>{t("costCalc")}</span>
              </div>
              <div className={`modal-wtd__type bill_dir ${isMobileOnly && "mobile"} ${isTablet && "tablet"}`}
                onClick={() => {
                  setReportType('General to Vets');
                  dispatch(updateFlowCurrentStep(0));
                  dispatch(setSelectedFlowTypeToSkip("General to Vets"));
                  if (projectSettings.existingClaim && isAllFlowsAvailable) {
                    setOpenModalExistingClaim(true);
                  } else {
                    router(`/contact/${petId}/claim?type=${encodeURIComponent("General to Vets")}`);
                    setOpenModalWhatToDo(false);
                    dispatch(updateWtdModalOpen(false));
                  }
                }}
              >
                <img src={ElipseLink} />
                <span>{t("billDir")}</span>
              </div>
              <div
                className={`modal-wtd__back_bar ${isMobileOnly && "mobile"} ${isTablet && "tablet"}`}
                onClick={() => handleClose()}
              >
                <img src={ArrowLeftSmall} />
                <span>{t("back")}</span>
              </div>
            </>}
        </div>
      </div>

      {openModalExistingClaim && (
        <ModalExistingClaim
          openModalExistingClaim={openModalExistingClaim}
          setOpenModalExistingClaim={setOpenModalExistingClaim}
          petId={petId}
          reportType={reportType}
          setOpenModalWhatToDo={setOpenModalWhatToDo}
        />
      )}
    </>
  );
}

export default ModalWhatToDo;
