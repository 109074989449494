import { getToken } from "./token";
import configureStore from "./store";

const {persistor} = configureStore();

export const Request = ({method, values, route, token, formData}) => {
  let content = {}
  if (formData) {
    content = {
      method: method.toUpperCase(),
      headers: {Accept: "application/json, text/plain, */*"},
      body: formData
    }
  } else {
    content = {
      method: method.toUpperCase(),
      headers: {
        Accept: "application/json, text/plain, */*", 
        "Content-Type": "application/json"
      },
      contentType: 'application/json; charset=utf-8'
    }
    if (values) {
      content["body"] = JSON.stringify(values)
      content["dataType"] = 'json'
    } 
  }

  if (token) {
    content["headers"]["Authorization"] = `Bearer ${JSON.parse(token).token}`
  } else {
    if (getToken()) {
      content["headers"]["Authorization"] = `Bearer ${JSON.parse(getToken()).token}`
    }
  }
  return fetch("/" + route, content).then((res) => {
    if (res.status === 401) {
      localStorage.clear();
      persistor.purge().then(() => {
        window.location.reload();
      });
    }

    return res;
  })
}
