import React, { useEffect, useState } from "react";
import { initData } from "../common/routes/InitValues";
import { useDispatch, useSelector } from "react-redux";
import { updateData, updateLogo, updateMode } from "../../config/actions";
import useLocalStorage from "use-local-storage";
import { getToken, getUser, setToken } from "../../config/token";
import { ToastContainer } from "react-toastify";
import { setStyleAttribute } from "../../config/helpers";
import { useTranslation } from "react-i18next";
import { Request } from "../../config/requests";
import UserModeSwitcher from "../common/UserModeSwitcher";
import ErrorBoundary from "./ErrorBoundry";
import BubbleChat from "./BubbleChat";

export default function MainLayout({ children }) {
  const [data] = React.useState({ ...initData });
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  dispatch(updateData(data));
  const [themeSetting] = useLocalStorage("theme", "panda");
  const [font] = useLocalStorage("font", "Roboto");
  const [primaryColor] = useLocalStorage("primaryColor", "#fe4b4e");
  const [secondaryColor] = useLocalStorage("secondaryColor", "#fff9f0");
  const language = useSelector((s) => s.form.language);

  const [expiration, setExpiration] = useState(
    localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token")).expiration
      : null
  );
  const [currentDate] = useState(new Date());

  useEffect(() => {
    // getLogo();
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setExpiration(JSON.parse(localStorage.getItem("token")).expiration);
    }
    if (expiration && currentDate.getTime() > expiration) {
      localStorage.clear();
      setToken(null);
    }
  }, [expiration, currentDate]);

  useEffect(() => {
    let bodyTag = document.getElementsByTagName("body")[0];
    bodyTag.setAttribute("data-theme", themeSetting);
  }, [themeSetting]);

  useEffect(() => {
    setStyleAttribute("--primary-font", font);
    setStyleAttribute("--primary-color", primaryColor);
    setStyleAttribute("--secondary-color", secondaryColor);
  }, [font, primaryColor, secondaryColor]);

  const body = getComputedStyle(document.body);
  const background = body.getPropertyValue("--b-color-text-primary-2").trim();

  useEffect(() => {
    document.body.style.backgroundColor = background;
  });

  return (
    <ErrorBoundary>
      <div data-theme={themeSetting}>
        <ToastContainer position="top-right" autoClose={2000} />
        {children}
        {/* <UserModeSwitcher /> */}
        <BubbleChat />
      </div>
    </ErrorBoundary>
  );
}
