export const initData = {
    customer: {
        id: "87a7583c-c230-4cd6-a2b0-e517ae584b83",
        petId: "0a6bd10c-6d78-4dff-92b5-7b5d5e740d05",
        firstName: "Renaud",
        lastName: "Viot",
        address: "Karl-Popp-Strasse 5/144",
        zipCode: "1100",
        city: "Wien",
        country: "Österreich"
    },
    pets: {
        id: "0a6bd10c-6d78-4dff-92b5-7b5d5e740d05",
        customerId: "87a7583c-c230-4cd6-a2b0-e517ae584b83",
        list: [
            {
                name: "Gina",
                insurance: "s"
            },
            {
                name: "Akila",
                insurance: "m"
            }
        ]
    }
}