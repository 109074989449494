export const projectSettings = {
    // Flow
    chipCheck: true,
    diagCheck: true,
    duplicatesCheck: true,
    existingClaim: true,


    // Login
    loginBgImages: true,

    // Product
    

    // Assets
    aseetsPath: 'panda',


    // Footer
    footer: {
        titles: {
            'Commercial': "Commercial Register:",
        },
    }
}   

export const MazarsProjectSetting = {
    diagCheck: true,
    chip: false,
}