import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { FMSwitch } from "../../../components/forms/Forms";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { FormComponentsWithImage } from "../../../constants/Forms";

const SingleQuestion = ({
  data,
  setData,
  handleInput,
  flowQuestions,
  onUpdate,
  updateClaim
}) => {
  const flowStep = useSelector((s) => s.flow.flowCurrentStep);
  const [searchParams] = useSearchParams();
  const ocrDetails = useSelector((s) => s.flow.uploadValues);

  const isWithImage = FormComponentsWithImage[flowQuestions[0]?.type];

  useEffect(() => {
    const targetDiv = document.getElementsByClassName("custom_container")[0];
    setTimeout(() => {
        targetDiv.classList.add("SlowShow");
    }, 1);
    return () => {
        targetDiv.classList.remove("SlowShow");
      }
  }, [flowStep]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [flowStep]);

  return (
    <div className={isWithImage ? "wave-bg " : "full-width-flow"}>
      <div class="custom_container">
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              justifyItems: "center",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <FMSwitch
              data={data}
              setData={setData}
              key={`question-${flowQuestions[0].uniqueId}`}
              isActive={flowStep + 1}
              type={flowQuestions[0]?.type}
              details={flowQuestions[0]?.content}
              claimId={searchParams.get("claimId")}
              ocrDetails={ocrDetails[flowQuestions[0]?.content?.uploadId] || {}}
              handleInput={handleInput}
              index={flowStep}
              onUpdate={onUpdate}
              width={"Full width"}
              single={true}
              ocrKey={flowQuestions[0]?.content?.uploadId}
              updateClaim={updateClaim}
            />
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default memo(SingleQuestion);
