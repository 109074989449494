import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isMobile, isMobileOnly } from "react-device-detect";

function QuestionContainer({ children, width }) {
  const container = useRef(null);
  const route = useSelector((state) => state.flow.questionRoute);

  useEffect(() => {
    // animate();

    return () => {
      container.current = null;
    };
  }, []);

  function animate() {
    if (route === "next") container.current?.classList.add("nextQ");
    else if (route === "back") container.current?.classList.add("backQ");

    setTimeout(() => {
      container.current?.classList.remove(route === "next" ? "nextQ" : "backQ");
    }, 1000);
  }

  return (
    <div className={(width !== "Half width" || isMobileOnly) ? "QuestionContainer" : "HalfQuestionContainer"} ref={container}>
      {children}
    </div>
  );
}

export default QuestionContainer;
