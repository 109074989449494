import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { t } from "i18next";
import TileTable, { TileRow } from "../../../../components/table/TileTable";
import { TileTableContext } from "../../../../config/context";
import { isMobile, isMobileOnly } from "react-device-detect";
import TableRestyle from "./TableRestyle";
import Exclamation from "../../../../assets/iconsSmall/exclamation_mark_white.svg";
import ModalEditableInvoice from "../../../claims/modals/ModalEditableInvoice";
import TileEditRowsTable from "../../../../components/table/TileEditRowsTable";
import { useSelector } from "react-redux";
import { USER_MODE } from "../../../../constants/ApplicationConstants";
import { useNavigate } from "react-router-dom";
import { FLOW_TYPES } from "../../../../constants/Flow";

const TileContent = ({ data, mode }) => {
  const { currentClaim } = useContext(TileTableContext);
  const index = useMemo(() => currentClaim - 1, [currentClaim]);
  const row = useMemo(() => data[index], [index, data]);

  return (
    <>
      <TileRow label={t("date")}>{row?.Date || t("noInfo")}</TileRow>
      <TileRow label={t("desc")}>{row?.Description || t("noInfo")}</TileRow>
      {mode === USER_MODE.ADMIN && (
        <TileRow label={t("got")}>{row?.GOT || t("noInfo")}</TileRow>
      )}
      <TileRow label={t("quantity2")} red>
        {row?.Quantity || t("noInfo")}
      </TileRow>
      <TileRow label={t("Amount")} red>
        {row?.Amount + (row?.Currency == "$" ? " $" : " €") || t("noInfo")}
      </TileRow>
    </>
  );
};

const TableContainerComponent = forwardRef(
  (
    {
      data,
      gotDetails,
      gotMatch,
      tableDetails,
      handleUpdate,
      handleDelete,
      handleNew,
      themeSetting,
      setTableDetails,
      selectedTableDetails,
      resultTableDetails,
      initialTableDetails,
      rowSelectHandler,
      gotList,
      isOnlySelectable = false,
      onBackHandler = null,
    },
    ref
  ) => {
    const history = useNavigate();
    const [openModalEditableInvoice, setOpenModalEditableInvoice] =
      useState(false);
    const [isEditRows, setIsEditRows] = useState(false);
    const [showResultTable, setShowResultTable] = useState(false);
    const selectedFlowTypeToSkip = useSelector(
      (s) => s.flow.selectedFlowTypeToSkip
    );
    const mode = useSelector((s) => s.form.mode);
    const flowQuestions = useSelector((s) => s.flow.flowQuestions);
    const flowCurrentStep = useSelector((s) => s.flow.flowCurrentStep);
    const petInfo = useSelector((s) => s.flow.selectedPetInfo);

    const [isTableCalculated, setIsTableCalculated] = useState(false);

    const flowType = useSelector((state) => state.flow.flowType);
    console.log(flowType);

    function flowSkippedTypeHandler(type) {
      switch (type) {
        default:
        case "General":
        case "General to Vets":
        case "Liability claim":
        case "Existing Claim":
          return t("pleaseSelectClaim_G");
        case "Cost estimation":
          return t("pleaseSelectClaim_CE");
      }
    }

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, [openModalEditableInvoice, isEditRows, showResultTable]);

    return (
      <div className="InvoiceTable">
        {openModalEditableInvoice && (
          <ModalEditableInvoice
            openModalEditableInvoice={openModalEditableInvoice}
            setOpenModalEditableInvoice={setOpenModalEditableInvoice}
            gotDetails={gotDetails}
            tableDetails={tableDetails}
            setTableDetails={setTableDetails}
            initialTableDetails={initialTableDetails}
            themeSetting={themeSetting}
            handleUpdate={handleUpdate}
            selectedTableDetails={selectedTableDetails}
            resultTableDetails={resultTableDetails}
            gotList={gotList}
            handleNew={handleNew}
            mode={mode}
            petName={petInfo?.name}
          />
        )}

        {(selectedTableDetails.length > 0 &&
          flowType === FLOW_TYPES["Existing Claim"]) ||
        flowType !== FLOW_TYPES["Existing Claim"] ? (
          <>
            <div className="invoice-table-title">
              {!isEditRows && !showResultTable && !isOnlySelectable && (
                <>
                  <div className="invoice-table-title__title">
                    {t("invoiceDetails")}
                  </div>
                  <div className="invoice-table-title__name">
                    {petInfo?.name || t("Name")}
                  </div>
                </>
              )}
              {isEditRows && (
                <div className="invoice-table-title__title">
                  {t("areNotRefund")}
                </div>
              )}
              {showResultTable && (
                <div className="invoice-table-title__title">
                  {t("invoicesForVerification")}
                </div>
              )}
              {isOnlySelectable && (
                <div className="invoice-table-title__title">
                  {t("Your benefit cases")}
                </div>
              )}
            </div>

            {isEditRows
              ? !showResultTable && (
                  <div
                    className="invoice-table-message column-centered"
                    style={{ margin: "5vh 0 0 0" }}
                  >
                    {t("pleaseRemove")}
                  </div>
                )
              : !showResultTable && (
                  <>
                    <div className="invoice-table-message column-centered">
                      {isOnlySelectable
                        ? flowSkippedTypeHandler(selectedFlowTypeToSkip)
                        : t("pleaseCheckData")}
                    </div>
                  </>
                )}

            <>
              {isMobile ? (
                <div className="tile-table-wrapper">
                  {!isEditRows && !showResultTable && !isOnlySelectable && (
                    <TileTable data={tableDetails}>
                      <TileContent
                        data={
                          showResultTable
                            ? resultTableDetails
                            : selectedTableDetails
                        }
                        mode={mode}
                      />
                    </TileTable>
                  )}
                  {(isEditRows || showResultTable || isOnlySelectable) && (
                    <TileEditRowsTable
                      rowSelectHandler={rowSelectHandler}
                      isOnlySelectable={isOnlySelectable}
                      tableData={
                        showResultTable
                          ? resultTableDetails
                          : selectedTableDetails
                      }
                      editRows={isEditRows || isOnlySelectable}
                    />
                  )}
                </div>
              ) : (
                <TableRestyle
                  initialTableDetails={initialTableDetails}
                  tableDetails={tableDetails}
                  setTableDetails={setTableDetails}
                  editRows={isEditRows || isOnlySelectable}
                  showResultTable={showResultTable}
                  rowSelectHandler={rowSelectHandler}
                  selectedTableDetails={selectedTableDetails}
                  resultTableDetails={resultTableDetails}
                  gotList={gotList}
                  handleUpdate={handleUpdate}
                  isOnlySelectable={isOnlySelectable}
                  ref={ref}
                  mode={mode}
                  isTableCalculated={isTableCalculated}
                />
              )}
            </>

            {isOnlySelectable ? (
              <div className="invoice-table-buttons">
                <div
                  className={`button_red_invoice ${
                    isMobileOnly ? "mobile" : ""
                  }`}
                  onClick={() => {
                    // setIsEditRows(false)
                    console.log("onBackHandler", onBackHandler);
                    if (onBackHandler) {
                      onBackHandler();
                    }
                  }}
                >
                  {t("Back")}
                </div>

                <button
                  className={`button_red_invoice ${
                    isMobileOnly ? "mobile" : ""
                  }`}
                  disabled={
                    !selectedTableDetails?.length ||
                    selectedTableDetails?.length === resultTableDetails?.length
                  }
                  onClick={() => {
                    if (ref.current) {
                      let res = resultTableDetails.map((item) => {
                        const { selected, ...rest } = item;
                        return rest;
                      });
                      setTableDetails(res);
                      setTimeout(() => {
                        ref.current.click();
                      }, 1000);
                    }
                  }}
                >
                  {t("Next2")}
                </button>
              </div>
            ) : (
              <>
                {!isEditRows && !showResultTable && (
                  <div
                    className={`invoice-table-info ${isMobile ? "mobile" : ""}`}
                  >
                    <div>
                      <img src={Exclamation} alt="Info-image" />
                    </div>
                    {t("attention")}
                  </div>
                )}

                <div className="invoice-table-buttons">
                  {!isEditRows && !showResultTable && (
                    <button
                      className={`button_red_invoice ${
                        isMobileOnly ? "mobile" : ""
                      }`}
                      onClick={() => setOpenModalEditableInvoice(true)}
                    >
                      {t("correctData")}
                    </button>
                  )}
                  {isEditRows && (
                    <button
                      className={`button_red_invoice ${
                        isMobileOnly ? "mobile" : ""
                      }`}
                      onClick={() => setIsEditRows(false)}
                    >
                      {t("Back")}
                    </button>
                  )}
                  {showResultTable && (
                    <button
                      className={`button_red_invoice ${
                        isMobileOnly ? "mobile" : ""
                      }`}
                      onClick={() => {
                        setShowResultTable(false);
                        setIsEditRows(true);
                        setIsTableCalculated((prev) => !prev);
                      }}
                    >
                      {t("Back")}
                    </button>
                  )}

                  {!isEditRows && !showResultTable && (
                    <button
                      className={`button_red_invoice ${
                        isMobileOnly ? "mobile" : ""
                      }`}
                      onClick={() => setIsEditRows(true)}
                      disabled={!selectedTableDetails.length}
                    >
                      {t("Next2")}
                    </button>
                  )}
                  {isEditRows && (
                    <button
                      className={`button_red_invoice ${
                        isMobileOnly ? "mobile" : ""
                      }`}
                      disabled={
                        !selectedTableDetails.length ||
                        !resultTableDetails?.length
                      }
                      onClick={() => {
                        setIsEditRows(false);
                        setShowResultTable(true);
                        setIsTableCalculated((prev) => !prev);
                      }}
                    >
                      {t("Next2")}
                    </button>
                  )}
                  {showResultTable && (
                    <button
                      className={`button_red_invoice ${
                        isMobileOnly ? "mobile" : ""
                      }`}
                      disabled={!resultTableDetails.length}
                      onClick={() => {
                        if (ref.current) {
                          let res = resultTableDetails.map((item) => {
                            const { selected, ...rest } = item;
                            return rest;
                          });
                          setTableDetails(res);
                          setTimeout(() => {
                            ref.current.click();
                          }, 1000);
                        }
                      }}
                    >
                      {flowCurrentStep === flowQuestions.length - 1
                        ? t("Finish")
                        : t("Next2")}{" "}
                    </button>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <div class="SlowShow show-final-body">
            <div className="invoice-table-message column-centered">
              {t("noClaimsToAdd")}
            </div>
            <div className="button_red_main" onClick={() => history("/")}>
              {t("toStartPage")}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default TableContainerComponent;
