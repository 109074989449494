import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  updateIsAdminLoggedAsUser,
  updateIsAdminViewMode,
  updateIsUserModalSelect,
  updateMode,
  updateUser,
} from "../../../config/actions";
import { TextField } from "@mui/material";
import Loading from "../../../components/common/Loading";
import { getToken, setAdminTempToken, setToken } from "../../../config/token";
import { Markup } from "interweave";
import { USER_MODE } from "../../../constants/ApplicationConstants";

function ModalUserSelect() {
  const { t } = useTranslation();
  const router = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isValidAdminEmail, setIsValidAdminEmail] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [problemEmail, setProblemEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [veificationCode, setVeificationCode] = useState("");

  const isModalOpen = useSelector((s) => s.admin.isUserSelectModalOpen);
  const isAdminLoggedAsUser = useSelector((s) => s.admin.isAdminLoggedAsUser);

  const closeModal = () => {
    dispatch(updateIsUserModalSelect(false));
    setEmailSent(false);
    setCodeLoading(false)
    setEmail("")
    setIsValidAdminEmail(false)
    setVeificationCode("")
    setProblemEmail(false);
  };

  const changeEmail = (e) => {
    const inputValue = e.target.value.trim();
    setEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidAdminEmail(isValid);
  };

  const EmailConfirm = async () => {
    if (isValidAdminEmail) {
      setCodeLoading(true);

      const token = getToken() ? JSON.parse(getToken()) : undefined;

      fetch("api/users/email_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.token}`,
        },
        body: JSON.stringify({ email: email }),
      })
        .then((response) => {
          if (response.status === 200) {
            setProblemEmail(false);
            setEmailSent(true);
          } else {
            setProblemEmail(true);
            setEmailSent(false);
          }

          return response;
        })
        .then((response) => response.json())
        .then((res) => setVeificationCode(res.message))
        .finally(() => {
          setCodeLoading(false);
        });
    }
  };

  const changeVerificationCode = (e) => {
    setVeificationCode(e.target.value.trim());
  };

  const codeConfirm = async () => {
    const response = await fetch("api/users/verification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: veificationCode }),
    });

    const prevToken = getToken() ? JSON.parse(getToken()) : undefined;
    setAdminTempToken(JSON.stringify({ token: prevToken.token }));

    const newTokenResponse = await response.json();
    setToken(JSON.stringify({ token: newTokenResponse.token }));
    await getUserInfo(newTokenResponse.token);
    dispatch(updateIsAdminLoggedAsUser(true));
    dispatch(updateIsUserModalSelect(false));
    dispatch(updateMode(USER_MODE.CLIENT));
    

    closeModal();
    router("/");
    setTimeout(() => {
      window.location.reload();
    }, 100)
  };

  const getUserInfo = async (curToken) => {
    return await fetch("api/users/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${curToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        dispatch(updateUser(json.data));
      });
  };

  return (
    <ModalWrapper
      headerTitle={t("LoginAsUser")}
      openModal={isModalOpen}
      setModalWrapper={() => closeModal()}
    >
      <div
        className="modal_container__body__text"
        style={{
          maxWidth: "540px",
          margin: "0 0 5vh 0",
        }}
      >
        {/* {t("cantFindDiag")} */}
      </div>
      <div class="modal_container__body__column-modal modal_container__body__column-modal_full-width">
        {!emailSent && (
          <>
            {codeLoading ? (
              <Loading noText fast />
            ) : (
              <>
                <TextField
                  label={"Email"}
                  value={email}
                  onChange={(e) => changeEmail(e)}
                  sx={{ width: "100%", maxWidth: "400px" }}
                  className="admin-modal__email"
                />
                <button
                  className={`button_red_small ${isMobileOnly && "mobile"}`}
                  onClick={EmailConfirm}
                  disabled={!isValidAdminEmail}
                >
                  {t("Submit")}
                </button>
              </>
            )}
          </>
        )}
        {emailSent && (
          <>
            <TextField
              label={"Code"}
              value={veificationCode}
              onChange={(e) => changeVerificationCode(e)}
              sx={{ maxWidth: "400px" }}
              className="admin-modal__email"
            />
            <button
              className={`button_red_small ${isMobileOnly && "mobile"}`}
              onClick={codeConfirm}
              disabled={!isValidAdminEmail}
            >
              {t("Submit")}
            </button>
          </>
        )}

        {problemEmail && (
          <div
            className="login__body__alert"
            style={{ color: "red", marginTop: 0 }}
          >
            <Markup
              content={t("errorAdminEmail")}
              className="small text-centered"
            />
          </div>
        )}
      </div>
    </ModalWrapper>
  );
}

export default ModalUserSelect;
