import React, { useEffect } from 'react';
import Skeleton from "@mui/material/Skeleton";
import UploadImg from "../../assets/Insurances/upload_image.svg";
import CatDog from "../../assets/Insurances/cat_dog.svg";
import leftArrow from "../../assets/iconsSmall/left-arrow.svg";
import SendArrow from "../../assets/iconsSmall/send-arrow.svg";
import LeftArrow from "../../assets/iconsSmall/arrow_left_solid_red.svg";
import RightArrow from "../../assets/iconsSmall/arrow_right_solid_red.svg";
import { isMobile } from 'react-device-detect';

const PetSlider = ({
    petData,
    petLoading,
    handlePetClick,
    selectedPet,
    setSelectedPetName,
    selectedPetIndex
}) => {
    const sliderStyles = {
        position: 'relative',
        display: "flex",
        gap: '2vw',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        margin: '3vh 0 9vh 0',
        height: '289px',
        overflow: 'visible',
        transition: 'transform 0.3s ease-in-out'
    };
    const sliderStylesMobile = {
        position: 'relative',
        display: "flex",
        justifyContent: 'space-evenly',
        alignItems: 'center',
        margin: '5vh 0',
        height: 'auto',
        overflow: 'hidden',
        transition: 'transform 0.3s ease-in-out',
        gap: '1vw',
    };

    const [currentIndex, setCurrentIndex] = React.useState(0);

    const petChunk = petData.slice(currentIndex, currentIndex + (isMobile ? 2 : 3));

    const handleNext = () => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, petData.length - 1));
    };
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleSetSelectedPet = (id, name) => {
        handlePetClick(id, name)
        setSelectedPetName(name)
    }

    useEffect(() => {
        if (petData.length > 2) {
            setCurrentIndex(Math.min(selectedPetIndex, petData.length - (isMobile ? 2 : 3)) || 0);
        }
    }, [selectedPetIndex]);

    return (
        <div style={isMobile ? sliderStylesMobile : sliderStyles} id={'petSliderContainer'}>
            {petLoading
                ? <Skeleton variant="rounded" width={isMobile ? "96%" : "100%"} height={256} style={{ margin: "0 0 5vh 0" }} />
                : <>
                    {isMobile
                        ? <div
                            className={currentIndex === 0 ? 'sliderBtnMobile__disabled' : 'sliderBtnMobile'}
                            onClick={(currentIndex === 0) ? (() => { }) : handlePrev}
                        >
                            <img src={LeftArrow} width={'20px'} />
                        </div>
                        : <button
                            className={currentIndex === 0 ? 'sliderBtn__disabled' : 'sliderBtn'}
                            onClick={handlePrev}
                            disabled={currentIndex === 0}
                        >
                            <img src={leftArrow} />
                        </button>}
                    {petChunk?.map((pet) => (
                        <div
                            key={pet?.id}
                            className={"damages-pet-photo"}
                            onClick={() => handleSetSelectedPet(pet?.policyId, pet?.attributes?.name)}
                        >
                            <div
                                className={pet?.policyId === selectedPet ? "pet_container__wrapper" : "pet_container__wrapper-inactive"}
                                style={{ display: "flex" }}
                            >
                                <div>
                                    {pet?.image
                                        ? <img src={`data:image/jpeg;base64, ${pet.image}`} alt={pet?.attributes?.name} style={{ objectFit: "cover" }} width={'100%'} height={'100%'} />
                                        : <img src={CatDog} alt={pet?.attributes?.name} width={'96%'} />}
                                </div>
                            </div>
                            <span className={'pet_container__name'}>
                                {pet?.attributes.name}
                                {petData.filter(el => el.attributes.name === pet.attributes.name).length > 1 &&
                                    <div>{pet?.policyId}</div>}
                            </span>
                        </div>
                    ))}
                    {isMobile ?
                        <div
                            className={currentIndex >= petData.length - 2 ? 'sliderBtnMobile__disabled' : 'sliderBtnMobile'}
                            onClick={(currentIndex >= petData.length - 2) ? (() => { }) : handleNext}
                        >
                            <img src={RightArrow} width={'20px'} />
                        </div>
                        : <button
                            className={currentIndex >= petData.length - 3 ? 'sliderBtn__disabled' : 'sliderBtn'}
                            onClick={handleNext}
                            disabled={currentIndex >= petData.length - 3}
                        >
                            <img src={SendArrow} />
                        </button>}
                </>
            }
        </div>
    );
};

export default PetSlider;