import {
  Input,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  invoiceTableHeaders,
  existingClaimsHeaders,
  getDate,
  reduceTotalAmount,
  getCurrencyFromInvoice,
} from "../../../../config/helpers";
import { t } from "i18next";
import NotChecked from "../../../../assets/iconsSmall/check_not_selected.png";
import Checked from "../../../../assets/iconsSmall/check_selected.png";
import CheckedMark from "../../../../assets/iconsSmall/check_selected_mobile.png";
import SelectedDocument from "../../../../assets/iconsSmall/document.svg";
import ShowFileIcon from "../../../../assets/iconsSmall/document-white.svg";
import Plus from "../../../../assets/iconsSmall/add_line.svg";

import CustomSelect from "../../../../components/inputs/select/CustomSelect";
import { useTableScroll } from "../../../../hooks/useTableScroll";

import { useEffect, useState } from "react";
import ModalPreview from "../../../claims/modals/ModalPreview";
import { USER_MODE } from "../../../../constants/ApplicationConstants";

const TableRestyle = ({
  initialTableDetails,
  handleUpdate,
  editValues = false,
  editRows = false,
  showResultTable = false,
  rowSelectHandler,
  selectedTableDetails,
  resultTableDetails,
  gotList,
  isOnlySelectable,
  handleNew,
  mode,
  isTableCalculated
}) => {
  const { bodyRef, headerRef, btnRef, syncScroll, listOfItemsRef, recalculate } = useTableScroll();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  // console.log("TR > selectedTableDetails", selectedTableDetails);

  useEffect(() => {
    recalculate()
  }, [isTableCalculated])

  const headerCells = isOnlySelectable
    ? existingClaimsHeaders
    : (mode === USER_MODE.ADMIN)
      ? invoiceTableHeaders
      : invoiceTableHeaders.filter(el => el.title !== "got");

  return (
    <div className={`invoice-table ${isOnlySelectable && "existing"}`}>
      {isModalOpen && (
        <ModalPreview
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          row={selectedRow}
        />
      )}

      <TableContainer
        className="reset-table-spacing table-reset-header"
        sx={{ margin: "0 0 -3px 0" }}
        ref={headerRef}
      >
        <Table
          className={`SlowShow reset-table-new ${editRows ? "modal-restyle-edit-rows" : "modal-restyle"}`}
          sx={{
            minWidth: 900,
          }}
        >
          <TableHead>
            <TableRow
              className={`reset-table-new-header ${editRows ? "edit-rows" : ""
                }`}
            >
              {(editRows || isOnlySelectable) && <TableCell className="cell-transparent"></TableCell>}
              {headerCells.map((header, index) => (
                <TableCell
                  key={`header-${index}`}
                  align={index > 0 ? "inherit" : "left"}
                >
                  <div>
                    <span>{t(header.title)}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <TableContainer
        className="reset-table-spacing"
        sx={{ maxHeight: 314 }}
        ref={bodyRef}
        onScroll={syncScroll}
      >
        <Table
          className={`SlowShow reset-table-new ${editRows ? "modal-restyle-edit-rows" : "modal-restyle"}`}
          sx={{
            minWidth: 900,
          }}
        >
          <TableBody ref={listOfItemsRef}>
            {(showResultTable ? resultTableDetails : selectedTableDetails)?.map(
              (row, index) => {
                const reducedAmount = reduceTotalAmount(row);
                const rowAmount = reducedAmount
                  ? reducedAmount + " " + getCurrencyFromInvoice(row)
                  : t("noInfo");

                return (
                  <>
                    {isOnlySelectable
                      ? (
                        <TableRow
                          key={`row-${index}`}
                          className={`reset-table-new-body inverted-colors ${editRows ? "edit-rows" : ""} color-modal 
                        ${editRows
                              ? row?.selected
                                ? "edit-selected"
                                : "edit-unselected"
                              : "no-pointer"
                            }`}
                          onClick={() => {
                            rowSelectHandler(index);
                          }}
                        >
                          <TableCell key={0} className="cell-transparent">
                            <img
                              src={row?.selected ? CheckedMark : NotChecked}
                              alt="not-checked"
                            />
                          </TableCell>
                          <TableCell key={1}>
                            <div>{getDate(row?.incidentDate) || t("noInfo")}</div>
                          </TableCell>
                          <TableCell key={2}>
                            <div>
                              <span>
                                {row?.claimId || t("noInfo")}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell key={3}>
                            <div>
                              <span>
                                {row?.additionalProperties?.additionalProperties
                                  ?.veterinarian || t("noInfo")}
                              </span>
                            </div>
                          </TableCell>
                          <TableCell key={4}>
                            <div>
                              <span>{row?.state.toUpperCase()}</span>
                            </div>
                          </TableCell>
                          <TableCell key={5}>
                            <div>
                              <span>{rowAmount}</span>
                            </div>
                          </TableCell>
                          <TableCell key={6}>
                            <div>
                              {row?.attachments &&
                                row?.attachments[0]?.hasOwnProperty(
                                  "attachmentId"
                                ) ? (
                                <Tooltip title={t("seeInitDoc")} placement="top">
                                  <div
                                    className={`back_icon_red ${row?.selected ? "back_icon_white" : ""
                                      }`}
                                    style={{ marginRight: "10px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedRow(row);
                                      setIsModalOpen(true);
                                    }}
                                  >
                                    <img
                                      src={
                                        row?.selected
                                          ? ShowFileIcon
                                          : SelectedDocument
                                      }
                                      style={{
                                        color: "#FFFAF0",
                                        top: "5px",
                                        position: "relative",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip title={t("seeInitDoc")} placement="top">
                                  <div
                                    className={`back_icon_red ${row?.selected ? "back_icon_white" : ""
                                      }`}
                                    style={{
                                      marginRight: "10px",
                                      opacity: "50%",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <img
                                      src={
                                        row?.selected
                                          ? ShowFileIcon
                                          : SelectedDocument
                                      }
                                      style={{
                                        color: "#FFFAF0",
                                        top: "5px",
                                        position: "relative",
                                        opacity: "50%",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow
                          key={`row-${index}`}
                          className={`reset-table-new-body ${editRows ? "edit-rows" : ""
                            } color-modal ${editRows
                              ? row?.selected
                                ? "edit-selected"
                                : "edit-unselected"
                              : "no-pointer"
                            }`}
                          onClick={
                            editRows
                              ? () => rowSelectHandler(index)
                              : (e) => e.stopPropagation()
                          }
                        >
                          {editRows && (
                            <TableCell
                              key={0}
                              className="cell-transparent"
                            >
                              <img
                                src={row?.selected ? Checked : NotChecked}
                                alt="not-checked"
                              />
                            </TableCell>
                          )}

                          <TableCell key={1}>
                            {editValues ? (
                              <div>
                                <Input
                                  value={row?.Date}
                                  sx={{
                                    width: "100%",
                                    color: "#312F2F",
                                    fontFamily: "Rooneysans",
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    fontVariantNumeric:
                                      "lining-nums proportional-nums",
                                    opacity:
                                      initialTableDetails[index]?.[
                                        "Date"
                                      ] !== row.Date
                                        ? 1
                                        : editValues
                                          ? 0.5
                                          : 1,
                                    '& input': {
                                      textAlign: 'center',
                                    },
                                  }}
                                  align="center"
                                  disableUnderline
                                  onChange={(e) =>
                                    handleUpdate(index, "Date", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div>{row?.Date}</div>
                            )}
                          </TableCell>

                          <TableCell key={2}>
                            {editValues ? (
                              <div>
                                <Input
                                  value={row?.Description}
                                  sx={{
                                    width: "100%",
                                    color: "#312F2F",
                                    fontFamily: "Rooneysans",
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    fontVariantNumeric:
                                      "lining-nums proportional-nums",
                                    opacity:
                                      initialTableDetails[index]?.[
                                        "Description"
                                      ] !== row.Description
                                        ? 1
                                        : editValues
                                          ? 0.5
                                          : 1,
                                    '& textarea': {
                                      textAlign: 'center',
                                    },
                                  }}
                                  multiline
                                  maxRows={4}
                                  disableUnderline
                                  onChange={(e) =>
                                    handleUpdate(index, "Description", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div>{row?.Description}</div>
                            )}
                          </TableCell>

                          {mode === USER_MODE.ADMIN &&
                            <TableCell key={3}>
                              {editValues ? (
                                <div>
                                  <CustomSelect
                                    variant="invoice-table-restyle"
                                    options={gotList}
                                    formatCreateLabel={(i) => i.label}
                                    placeholder={row?.GOT || "-"}
                                    value={row.GOT || ""}
                                    onChange={(e) =>
                                      handleUpdate(index, "GOT", e.value)
                                    }
                                    isClearable={false}
                                    isOpacity={
                                      initialTableDetails[index]?.["GOT"] !== row.GOT
                                        ? 1
                                        : editValues
                                          ? 0.5
                                          : 1
                                    }
                                  />
                                </div>
                              ) : (
                                <div>{row?.GOT}</div>
                              )}
                            </TableCell>}

                          <TableCell key={4}>
                            {editValues ? (
                              <div>
                                <Input
                                  sx={{
                                    width: "50px",
                                    color: "#312F2F",
                                    fontFamily: "Rooneysans",
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    fontVariantNumeric:
                                      "lining-nums proportional-nums",
                                    opacity:
                                      initialTableDetails[index]?.["Quantity"] !==
                                        row.Quantity
                                        ? 1
                                        : editValues
                                          ? 0.5
                                          : 1,
                                  }}
                                  value={row?.Quantity}
                                  type="number"
                                  disableUnderline
                                  onChange={(e) =>
                                    handleUpdate(index, "Quantity", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div>{row?.Quantity}</div>
                            )}
                          </TableCell>

                          <TableCell key={5}>
                            {editValues ? (
                              <div>
                                <Input
                                  sx={{
                                    width: "100px",
                                    color: "#B83739",
                                    fontFamily: "Rooneysans",
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    fontVariantNumeric:
                                      "lining-nums proportional-nums",
                                    opacity:
                                      initialTableDetails[index]?.["Amount"] !==
                                        row.Amount
                                        ? 1
                                        : editValues
                                          ? 0.5
                                          : 1,
                                  }}
                                  value={row?.Amount}
                                  type="number"
                                  disableUnderline
                                  startAdornment={
                                    <InputAdornment position="start">
                                      {row?.Currency == "$" ? "$" : "€"}
                                    </InputAdornment>
                                  }
                                  onChange={(e) =>
                                    handleUpdate(index, "Amount", e)
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={
                                  editRows
                                    ? row?.selected
                                      ? "CellAmountRed"
                                      : ""
                                    : "CellAmountRed"
                                }
                              >
                                {row?.Amount + (row?.Currency == "$" ? " $" : " €")}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                  </>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        className="reset-table-spacing table-reset-header"
        ref={btnRef}
        onScroll={syncScroll}
      >
        {editValues &&
          <Table
            className={`SlowShow reset-table-new ${editRows ? "modal-restyle-edit-rows" : "modal-restyle"}`}
            sx={{
              minWidth: 900,
            }}
          >
            <TableBody>
              <TableRow
                key={`row-add-line`}
                className={`reset-table-new-body ${editRows ? "edit-rows" : ""} cell-add-line `}
                onClick={handleNew}
              >
                <TableCell key={"add-line"}>
                  <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                    <img
                      src={Plus}
                      alt="Plus"
                    />
                    <div>{t("addNewLine")}</div>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>}
      </TableContainer>
    </div>
  );
};

export default TableRestyle;
