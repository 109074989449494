import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import DropdownModal from "./DropdownModal";
import { Request } from "../../config/requests";
import { getToken } from "../../config/token";
import AreYouSure from "./AreYouSure";
import { Translate } from "@mui/icons-material";
import { useTableScroll } from "../../hooks/useTableScroll";

const headers = ["Name", "Options", "actions"];

export default function DropdownPage() {
  const { t } = useTranslation();
  const history = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const fetchDropdowns = () => {
    Request({
      method: "get",
      token: getToken(),
      route: `api/dropdowns`,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setTableData(res.data || []);
      });
  };

  useEffect(() => {
    fetchDropdowns();
  }, []);

  const onEdit = (row) => {
    setCurrentRow(row);
    setIsOpen(true);
  };

  const onDelete = (row) => {
    setCurrentRow(row);
    setIsOpenDelete(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setCurrentRow(null);
  };

  const onDeleteRow = () => {
    Request({
      method: "delete",
      token: getToken(),
      route: `api/dropdowns/${currentRow.id}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((_) => {
        setIsOpenDelete(false);
        setCurrentRow(null);
        fetchDropdowns();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { bodyRef, headerRef, syncScroll, listOfItemsRef } = useTableScroll();

  return (
    <div className="main-container">
      <Header />
      <div className="page-content">
        <div className="claims">
          <div className="w-100 my-3 d-flex justify-content-between dark_red_title">
            {t("Dropdowns")}
            <Box sx={{ display: "flex" }}>
              <button
                className="button_red"
                style={{ minWidth: "150px", margin: "0 10px" }}
                onClick={() => history("/management")}
              >
                {t("flows")}
              </button>
              <button
                className="button_red"
                style={{ minWidth: "150px", margin: "0 10px" }}
                onClick={() => setIsOpen(true)}
              >
                {t("createDropdown")}
              </button>
            </Box>
          </div>

          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <TableContainer
              className="reset-table-spacing table-reset-header"
              sx={{ margin: "0 0 -3px 0" }}
              ref={headerRef}
            >
              <Table
                className={`SlowShow reset-table-new table-dropdowns`}
                sx={{ minWidth: "unset", minWidth: 600 }}
              >
                <TableHead>
                  <TableRow className="reset-table-new-header">
                    {headers.map((header, index) => (
                      <TableCell
                        key={`header-${index}`}
                        align={index > 0 ? "inherit" : "left"}
                      >
                        <div>
                          <span>{t(header)}</span>
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>

            <TableContainer
              className="reset-table-spacing"
              sx={{ maxHeight: 314 }}
              ref={bodyRef}
              onScroll={syncScroll}
            >
              <Table
                className={`SlowShow reset-table-new table-dropdowns`}
                sx={{ minWidth: 600 }}
              >
                {tableData.length > 0 && (
                  <TableBody ref={listOfItemsRef}>
                    {tableData.map((row, index) =>
                      <TableRow
                        key={`row-${index}`}
                        className="reset-table-new-body"
                      >
                        <TableCell>
                          <div>
                            <span>
                              {row.name}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div>
                            <span>
                              {row.options.join(", ")}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell sx={{ padding: "0 5px" }}>
                          <div className="d-flex flex-row justify-content-center">
                            <div
                              className="back_icon_red"
                              style={{ marginRight: "10px" }}
                            >
                              <EditOutlinedIcon
                                sx={{
                                  color: "#FFFAF0",
                                  top: "5px",
                                  position: "relative",
                                }}
                                onClick={() => onEdit(row)}
                              />
                            </div>
                            <div
                              className="back_icon_red"
                              style={{ marginRight: "10px" }}
                            >
                              <DeleteOutlineOutlinedIcon
                                sx={{
                                  color: "#FFFAF0",
                                  top: "5px",
                                  position: "relative",
                                }}
                                onClick={() => onDelete(row)}
                              />
                            </div>
                            <div className="back_icon_red">
                              <Translate
                                sx={{
                                  color: "#FFFAF0",
                                  top: "5px",
                                  position: "relative",
                                }}
                                onClick={() =>
                                  history(
                                    `/management/translations?dropdown_id=${row.id}`
                                  )
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
                {!tableData.length && (
                  <div className="NoTableData">{t("No data")}</div>
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      {isOpen && (
        <DropdownModal
          open={isOpen}
          handleClose={handleClose}
          fetchData={fetchDropdowns}
          isEdit={!!currentRow}
          currentRow={currentRow}
        />
      )}

      {isOpenDelete && (
        <AreYouSure
          open={isOpenDelete}
          handleClose={() => {
            setIsOpenDelete(false);
            setCurrentRow(null);
          }}
          handleYes={onDeleteRow}
        />
      )}
      <Footer />
    </div>
  );
}
