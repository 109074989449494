const defaultState = {
    isUserSelectModalOpen: false,
    isAdminLoggedAsUser: false,
    isAdminViewMove: false,
};

function adminReducer (state = defaultState, action = {}) {
  switch (action.type) {
    case "IS_USER_SELECT_OPEN":
      return {
        ...state,
        isUserSelectModalOpen: action.data,
      };
      case "IS_ADMIN_LOGGED_AS_USER":
      return {
        ...state,
        isAdminLoggedAsUser: action.data,
      };
      case "IS_ADMIN_VIEW_MODE":
      return {
        ...state,
        isAdminViewMove: action.data,
      };
    default:
      return state;
  }
}

export default adminReducer
