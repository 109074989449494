import {
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Box,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { uploadImages } from "../../../../config/actions";
import Attach from "../../../../assets/iconsSmall/paper_clip.svg";

const MultipleFilesTable = ({
  ocrDetails,
  groupsList,
  showOnePicture,
  setGroupsList,
  setOcrDetails,
  picGroup,
  setPicGroup,
  setFileType,
  setSelectedFile,
  history,
  setHistory,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const removeInvoice = (
    index,
    ocrDetails,
    setOcrDetails,
    pictures,
    picGroup,
    setPicGroup,
    fileName
  ) => {
    const newHistory = structuredClone(history);
    const indexOfDeletedFile = newHistory.findLastIndex(
      (historyItem) =>
        historyItem.file.name === fileName && historyItem.isDeleted !== true
    );

    newHistory[indexOfDeletedFile].isDeleted = true;
    setHistory(newHistory);

    const files = newHistory
      .filter((obj) => obj.isDeleted === false)
      .map((obj) => obj.file);
    setOcrDetails({ ...ocrDetails, picture: files });

    if (groupsList[0]?.picGroup.length === 0) {
      setGroupsList([]);
    }

    setPicGroup(pictures);
    dispatch(uploadImages(files));
  };

  const showPDF = (e, index, row) => {
    e.preventDefault();

    setFileType(row.file.type);
    const foundPic = ocrDetails.picture.findLast(
      (file) => file.name === row.file.name
    );
    setSelectedFile(foundPic);
  };

  return (
    <>
      {(ocrDetails?.picture.length > 1 ||
        (groupsList.length > 0 && ocrDetails?.picture.length > 0) ||
        (!showOnePicture && ocrDetails?.picture.length !== 0)) && (
        <Box className="ocr-upload-table__wrapper">
          <Typography className="ocr-upload-table__title">
            {t("uploadedDocuments")}
          </Typography>
          <TableContainer>
            <Table sx={{ overflowX: "auto" }} aria-label="simple table">
              {history.map((row, index) => (
                <TableBody>
                  {row.isDeleted === false ? (
                    <TableRow
                      key={index}
                      sx={{
                        verticalAlign: "top",
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell className="Cells" align="left">
                        <Box className="aligned-cell">
                          <img
                            src={Attach}
                            className="pointer"
                            style={{ marginRight: 15 }}
                            onClick={(e) => showPDF(e, index, row)}
                            width={30}
                            height={30}
                            alt="Preview"
                          />
                          <span className="ocr-upload-table__item-name ">
                            {row.file.name.split(".")[0].length > 10
                              ? row.file.name.split(".")[0].slice(0, 10) +
                                "..." +
                                row.file.name.split(".")[0].slice(-10)
                              : row.file.name.split(".")[0]}

                            <CloseOutlined
                              className="ocr-upload-table__delete-btn"
                              size={18}
                              onClick={() =>
                                removeInvoice(
                                  index,
                                  ocrDetails,
                                  setOcrDetails,
                                  ocrDetails.picture,
                                  picGroup,
                                  setPicGroup,
                                  row.file.name
                                )
                              }
                            />
                          </span>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default MultipleFilesTable;
