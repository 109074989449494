import React, { useEffect, useRef, useState } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import { useTranslation } from "react-i18next";
import PandaMain from "../assets/logos_main/PandaMain.png";
import PawUI from "../components/ui/PawUI";
import { useSelector } from "react-redux";
import ModalEditPD from "../features/claims/modals/ModalEditPD";
import { isMobile, isMobileOnly } from "react-device-detect";
import { getToken } from "../config/token";
import { Request } from "../config/requests";
import { compareObjects, showToast } from "../config/helpers";
import { Box } from "@mui/material";

export default function Account() {
  const token = getToken();
  const { t } = useTranslation();
  const initialUserInfo = useSelector((s) => s.form.user);
  const [openModalEditPD, setOpenModalEditPD] = useState(false);
  const firstInputRef = useRef(null);

  const [userInfo, setUserInfo] = useState({});
  const [isChangeRequestBtnDisabled, setIsChangeRequestBtnDisabled] =
    useState(false);

  useEffect(() => {
    setUserInfo(initialUserInfo);
  }, [initialUserInfo]);

  const changeUserInfo = (value, key) => {
    setUserInfo((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const sendChangeRequest = () => {
    const editedLines = compareObjects(initialUserInfo, userInfo);
    console.log("compare", editedLines);
    const fd = new FormData();
    fd.append("data", JSON.stringify(editedLines));
    setIsChangeRequestBtnDisabled(true);

    Request({
      method: "post",
      token: token,
      route: "api/users/change_data",
      formData: fd,
    })
      .then(() => {})
      .catch((err) => {
        showToast(t(err.message || "somethingWentWrong"));
      })
      .finally(() => {
        showToast(t("dataChangesConfirmed"));
        setIsChangeRequestBtnDisabled(false);
      });
  };

  const submitPD = (type, value) => {
    const fd = new FormData();
    fd.append(
      "data",
      JSON.stringify({
        "Change Type": type,
        "Updated value": value,
      })
    );

    Request({
      method: "post",
      token: token,
      route: "api/users/change_data",
      formData: fd,
    })
      .then(() => {})
      .catch((err) => {
        showToast(t(err.message || "somethingWentWrong"));
      })
      .finally(() => {
        showToast(t("dataChangesConfirmed"));
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // useEffect(() => {
  //   firstInputRef.current.focus();
  // }, [])

  return (
    <div className="main-container SlowShow">
      <Header />
      <div className="account-content-container relative">
        <div className="first-last-name relative">
          <div className={`welcome_text ${!isMobile && "margin-0"}`}>
            {userInfo.firstName} {userInfo.lastName}
          </div>
          {isMobile ? (
            <PawUI
              top="1vh"
              right="17vw"
              rotation="right"
              width="22vw"
              zIndex="6"
            />
          ) : (
            <PawUI
              top="-8vh"
              left="7vw"
              rotation="right"
              width="130px"
              zIndex="0"
            />
          )}
        </div>
        <div className="account page-content container-user-info relative">
          <div className="container relative">
            <div className="container-form">
              <img
                src={PandaMain}
                alt="Panda_Main_Logo"
                className="main-logo-account"
              />
              <div className="form-wrapper">
                <div className="form-body">
                  <div className="form-body__group">
                    <div className="form-body__group-title">
                      {t("personalData")}
                    </div>
                    <div className="form-body__item">
                      <span>{t("title")}:</span>
                      <input
                        className="form-body__item-input"
                        // disabled
                        value={userInfo.gender}
                        ref={firstInputRef}
                        type="text"
                        onChange={(e) =>
                          changeUserInfo(e.target.value, "gender")
                        }
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t("firstName")}:</span>
                      <input
                        className="form-body__item-input"
                        // disabled
                        value={userInfo.firstName}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, "firstName")
                        }
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t("lastName")}:</span>
                      <input
                        // disabled
                        className="form-body__item-input"
                        value={userInfo.lastName}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, "lastName")
                        }
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t("birthDate")}:</span>
                      <span className="form-body__item__readonly">
                        {"23.12.1987"}
                      </span>
                    </div>
                  </div>
                  <div className="form-body__group">
                    <div className="form-body__group-title">{t("address")}</div>
                    <div className="form-body__item">
                      <span>{t("city")}:</span>
                      <input
                        // disabled
                        className="form-body__item-input"
                        value={userInfo.city}
                        onChange={(e) => changeUserInfo(e.target.value, "city")}
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t("street")}:</span>
                      <input
                        // disabled
                        className="form-body__item-input"
                        value={userInfo.street}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, "street")
                        }
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t("houseNumber")}:</span>
                      <input
                        // disabled
                        className="form-body__item-input"
                        value={userInfo.houseNumber}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, "houseNumber")
                        }
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t("postcode")}:</span>
                      <input
                        // disabled
                        className="form-body__item-input"
                        value={userInfo.postCode}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, "postCode")
                        }
                      />
                    </div>
                  </div>
                  <div className="form-body__group">
                    <div className="form-body__group-title">
                      {t("contactDetails")}
                    </div>
                    <div className="form-body__item">
                      <span>{t("email")}:</span>
                      <span className="form-body__item__readonly">
                        {userInfo.email}
                      </span>
                    </div>
                    <div className="form-body__item">
                      <span>{t("phone")}:</span>
                      <input
                        // disabled
                        className="form-body__item-input"
                        value={userInfo.phone}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, "gender")
                        }
                      />
                    </div>
                  </div>
                </div>
                <Box sx={{ display: 'flex', gap: '1em' }}>
                  <div
                    className={
                      isMobileOnly ? "button_account_mobile" : "button_account"
                    }
                    style={
                      isMobile ? { margin: "3.5vh 0" } : { margin: "5vh 0" }
                    }
                    onClick={() => setOpenModalEditPD(true)}
                  >
                    {t("askForEdit")}
                  </div>
                  <button
                    className={
                      isMobileOnly ? "button_account_mobile" : "button_account"
                    }
                    style={
                      isMobile ? { margin: "3.5vh 0" } : { margin: "5vh 0" }
                    }
                    disabled={isChangeRequestBtnDisabled}
                    onClick={() => sendChangeRequest()}
                  >
                    {t("sendChangeRequest")}
                  </button>
                </Box>
                {/* <div
                  className={`${isMobileOnly ? "button_account_mobile" : "button_account"} ${true && "disabled"}`}
                  style={{ marginBottom: "0" }}
                  onClick={() => { }}
                >
                  {t("saveChanges")}
                </div> */}
              </div>
            </div>
          </div>
          {!isMobile && (
            <>
              <PawUI
                top="10vh"
                right="8vw"
                rotation="right"
                width="200px"
                zIndex="-1"
              />
              <PawUI
                bottom="35vh"
                left="0vw"
                rotation="left"
                width="350px"
                zIndex="-1"
              />
            </>
          )}
        </div>
        {isMobile && (
          <PawUI
            bottom="-15vh"
            left="-5vw"
            rotation="right"
            width="42vw"
            zIndex="5"
          />
        )}
      </div>
      <Footer />
      {openModalEditPD && (
        <ModalEditPD
          openModalEditPD={openModalEditPD}
          setOpenModalEditPD={setOpenModalEditPD}
          submitPD={submitPD}
        />
      )}
    </div>
  );
}
