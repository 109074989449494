import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../../config/requests";
import { getToken } from "../../config/token";
import { updateSelectedPetInfo, updateFlowCurrentStep } from "../../config/actions";
import { Markup } from "interweave";
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import Footer from "../../components/common/Footer";
import Header from "../../components/common/Header";
import PawUI from "../../components/ui/PawUI";
import Skeleton from '@mui/material/Skeleton';
import CustomTooltip from "../../components/ui/CustomTooltip";
import UploadImg from "../../assets/Insurances/upload_image.svg";
import CatDog from "../../assets/Insurances/upload_image.svg";
import ArrowLeft from "../../assets/PetPage/arrow_left_dashed.svg";
import ArrowLeftSmall from "../../assets/PetPage/arrow_left_dashed_small.svg";
import ArrowLeftBig from "../../assets/elipses/arrow_white_left_dashed.svg";
import BubbleWhite from "../../assets/PetPage/bubble_white.svg";
import BubbleRed from "../../assets/PetPage/bubble_red.svg";
import BubbleInfo from "../../assets/PetPage/bubble_info.svg";
import DownloadIcon from "../../assets/PetPage/download_icon.svg";
import PandaHead from "../../assets/UserInfo/panda-logo-main.svg";
import RedCheck from "../../assets/iconsSmall/red_check.svg";
import BlackCross from "../../assets/PetPage/black_cross.svg";
import ArrowUp from "../../assets/PetPage/arrow_up.svg";
import UploadPhoto from "../../assets/PetPage/cat_dog_upload.svg";
import Attach from "../../assets/iconsSmall/attach.svg";
import PandaThink from "../../assets/PetPage/panda_think.svg";
import Question from "../../assets/iconsSmall/red_question.svg";
import ElipseSendBill from "../../assets/elipses/el_send_bill.svg";
import ElipseResendDocs from "../../assets/elipses/el_resend_docs.svg";
import ElipseCostCalc from "../../assets/elipses/el_cost_calc.svg";
import ElipseLink from "../../assets/elipses/el_link.svg";
import CircleBack from "../../assets/elipses/circle_back_de.svg";
import PandaHeadBothHands from "../../assets/header/panda-logo-both-hands.svg";
import ModalWhatToDo from "../claims/modals/ModalWhatToDo";
import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import ProduktEN from "../../assets/PetPage/produkt_en.svg";
import ProduktDE from "../../assets/PetPage/produkt_de.svg";
import ProduktMobileEN from "../../assets/PetPage/produkt_mobile_en.svg";
import ProduktMobileDE from "../../assets/PetPage/produkt_mobile_de.svg";
import ModalEditData from "../claims/modals/ModalEditData";
import { mockTariff } from "./components/MockTariff";
import CheckDetails from "./components/CheckDetails";
import CrossDetails from "./components/CrossDetails";
import breedListDogs from "../../assets/dog_breeds.json";
import breedListCats from "../../assets/cat_breeds.json";
import { showToast } from "../../config/helpers";


export default function PetPage() {
  const token = getToken();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const history = useNavigate();
  const { id } = useParams();
  const buttonRef = useRef(null);
  const ref = useRef(null);
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const userInfo = useSelector((s) => s.form.user);
  // const showOcrInvoicePopup = useSelector((i) => i.flow.showOcrInvoicePopup);
  // const userPets = useSelector((s) => s.flow.selectedPetInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [openModalWhatToDo, setOpenModalWhatToDo] = useState(false);
  const [isCheckOpen, setIsCheckOpen] = useState(false);
  const [isCrossOpen, setIsCrossOpen] = useState(false);
  const [isLeft, setIsLeft] = useState(true);
  const [userPets, setUserPets] = useState(null);
  const [openModalEditData, setOpenModalEditData] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(false)

  const firstBreed = userPets?.attributes?.firstBreed || userPets?.attributes?.firstDogBreed || userPets?.attributes?.firstCatBreed || null;
  const secondBreed = userPets?.attributes?.secondBreed || userPets?.attributes?.secondDogBreed || userPets?.attributes?.secondCatBreed || null;
  
  // console.log("PP > mockT", mockTariff)
  // console.log("PP > userInfo", userInfo)
  // console.log("PP > userPets", userPets)
  // console.log("PP > firstBreed", firstBreed)
  // console.log("PP > secondBreed", secondBreed)

  const handleClick = () => {
    setOpenModalWhatToDo(true);

    buttonRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  async function getPetInfo() {
    return Request({ method: "get", token: token, route: `api/users/v2/pet?policy_id=${id} ` })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .then((json) => {
        setUserPets(json);
        // setFile(`data:image/jpeg;base64, ${json?.image}`)
        // console.log("PetPage > getPetInfo > json", json)
        // Since report can be started from every page - hidden dispatch below
        // dispatch(updateSelectedPetInfo({ ...json.attributes, type: json.type }));
        setIsLoading(false);
      })
      .catch((error) => {
        setError(true);
      });
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(updateFlowCurrentStep(0));
    getPetInfo();
  }, []);

  const onSelectFile = () => {
    ref.current.click();
  };

  const postFile = (selectedFile) => {
    setIsLoading(true);
    if (!selectedFile) return Promise.reject("No file selected")
    console.log(selectedFile)
    const fd = new FormData();
    fd.append("file", selectedFile);
    fd.append("similar", userPets.related_products.map((r) => r.id));

    return Request({
      method: "post",
      route: `api/users/pet_image/${userPets.id}`,
      token,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      formData: fd,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Request failed with status ${res.status}`);
        }
        return res.json();
      })
      .catch((error) => {
        setError(true)
        throw error;
      })
      .finally(() => {
        getPetInfo();
      });
  };

  // const getFile = () => {
  //   return Request({
  //     method: "get",
  //     route: `api/users/pet_image/${userPets.id}`,
  //     token,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => console.log(res))
  // }

  const onFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFile(URL.createObjectURL(selectedFile));
      postFile(selectedFile);
    }
  };

  const getBreedTitle = (code) => {
    if (code === "Unbekannt" || code === "CAT_1") return t("Unbekannt");
    let breedName = (userPets.type === "DOG" ? breedListDogs : breedListCats).find(el => el.value === code)?.value_name;
    return breedName;
  }

  const submitPetData = (changedData, file) => {
    // console.log("PetPage > changedData", changedData)
    // console.log("PetPage > file[0]", file[0])

    const fd = new FormData();
    fd.append("data", JSON.stringify(changedData));
    fd.append("type", "petData");
    fd.append("policyId", id);
    if (file) fd.append('file', file[0]);

    Request({
      method: "post",
      token: token,
      route: "api/users/change_data",
      formData: fd,
    })
      .then(() => {})
      .catch((err) => {
        showToast(t(err.message || "somethingWentWrong"));
      })
      .finally(() => {
        showToast(t("dataChangesConfirmed"))
      });
  };

  return (
    <div className="main-container SlowShow">
      <Header />
      <div className="paws_layer relative">
        <div className="insurances_container">
          {isMobile && (
            <div
              className="button_red_mobile back"
              onClick={() => history("/")}
            >
              <img src={ArrowLeft} />
              {t("back")}
            </div>
          )}
          {userInfo
            ? (
              <div className="welcome_text">
                {t("hallo")},
                <br />
                <span>{userInfo.firstName} {userInfo.lastName}</span>
              </div>
            )
            : (
              <div className="welcome_text">
                <Skeleton variant="rounded" width={180} height={50} />
              </div>
            )}
          <div className={isMobile ? "pet_main mobile" : "pet_main"}>
            {!isMobile &&
              <div className="arrow_back" onClick={() => history("/")}>
                <img src={ArrowLeft} width={32} height={88} />
                <div className="d-flex flex-column">
                  {t("backTo")}
                  <span>{t("Selection")}</span>
                </div>
              </div>
            }
            <div className="pet_container__item">
              {userPets && !isLoading
                ? (
                  <div className="pet_container__single">
                    <div style={{ position: 'relative' }}>
                      <div
                        className="pet_container__single__parent"
                        onClick={onSelectFile}
                      >
                        {userPets.image
                          ? <img src={`data:image/jpeg;base64, ${userPets?.image}`} height="100%" />
                          : <img src={CatDog} height="95%" width="95%" />}
                      </div>
                      {!userPets.image &&
                        <div className={`pet_container__single__upload ${isMobile && "mobile"}`}>
                          {t("uploadPhoto")}
                        </div>}
                      <input
                        ref={ref}
                        onChange={onFileChange}
                        type="file"
                        hidden
                      />
                    </div>
                  </div>
                )
                : (
                  <Skeleton variant="rounded" width={isMobileOnly ? 138 : 242} height={isMobileOnly ? 174 : 312} sx={{ borderRadius: "20px" }} />
                )}
            </div>
            <div className="pet_main__info">
              {userPets
                ? (
                  <div className="hl2_white_big">
                    {userPets.attributes.name}
                  </div>
                )
                : (
                  <div className="hl2_white_big">
                    <Skeleton variant="rounded" width={isMobileOnly ? 80 : 120} height={isMobileOnly ? 25 : 40} />
                  </div>
                )}
              <div className="gradient_bar"></div>
              <div className="pet_main__table">
                <div className="pet_main__table__left">
                  <div className="white_fat_rooney">{t("birthDate")}:</div>
                  <div className="white_fat_rooney">{secondBreed !== null ? t("firstBreed") : t("breed")}:</div>
                  {firstBreed !== secondBreed && secondBreed !== null && <div className="white_fat_rooney">{t("secondBreed")}:</div>}
                  <div className="white_fat_rooney">{t("chipNumber")}:</div>
                </div>
                <div className="pet_main__table__right">
                  <div className="white_slim_rooney">
                    {userPets
                      ? (userPets.attributes.birthDate || "No info")
                      : (<Skeleton variant="rounded" width={isMobile ? 150 : 250} height={26} />)}
                  </div>
                  <div className="white_slim_rooney">
                    {userPets
                      ? (getBreedTitle(userPets.attributes?.firstBreed || userPets.attributes?.firstDogBreed || userPets.attributes?.firstCatBreed) || "No info")
                      : (<Skeleton variant="rounded" width={isMobile ? 150 : 250} height={26} />)}
                  </div>
                  {firstBreed !== secondBreed && secondBreed !== null &&
                    <div className="white_slim_rooney">
                      {userPets
                        ? (getBreedTitle(userPets.attributes?.secondBreed || userPets.attributes?.secondDogBreed || userPets.attributes?.secondCatBreed) || "No info")
                        : (<Skeleton variant="rounded" width={isMobile ? 150 : 250} height={26} />)}
                    </div>}
                  <div className="white_slim_rooney">
                    {userPets
                      ? (userPets.attributes.identificationNumber || "No info")
                      : (<Skeleton variant="rounded" width={isMobile ? 150 : 250} height={26} />)}
                  </div>
                </div>
              </div>
              {userPets && <div
                className={`bubble-change-data ${(currentLanguage == 'de') ? "DE" : "EN"}`}
                onClick={() => setOpenModalEditData(!openModalEditData)}
              ></div>}
            </div>
          </div>

          {/* "WhatToDo" btn with Panda logo */}

          {userPets &&
            <div
              className={`insurances_container__toDoBtn relative ${isMobile && "mobile"}`}
              onClick={() => handleClick()}>
              <img src={PandaHeadBothHands} />
              <div
                ref={buttonRef}
                className={isMobileOnly ? "button_red_mobile" : "button_red_common"}
              >
                {t("whatToDo")}
              </div>
            </div>
          }
          {userPets
            ? (
              <div className="pet_product_header">
                <div className="headline1_red_title width100">
                  {userPets.attributes.name}{t("petProduct")}
                </div>
                <div className="hl2_black_big">
                  {t("firstSight")}
                </div>
              </div>
            )
            : (
              <div className="pet_product_header">
                <Skeleton variant="rounded" width={isMobileOnly ? 200 : 300} height={isMobileOnly ? 60 : 100} />
              </div>
            )}
          <div className={`${isLeft
            ? "insurances_container__left_active"
            : "insurances_container__right_active"} ${isMobile && "mobile"} relative SlowShow`}>
            <div className="insurances_container__wrapper">
              {isLeft && (isMobile
                ? <>
                  <PawUI top="12vh" right="-13vw" rotation="left" width="40%" zIndex="-1" />
                  <PawUI top="44vh" left="-14vw" rotation="right" width="45%" zIndex="-1" />
                  <PawUI top="80vh" right="-8vw" rotation="left" width="45%" zIndex="-1" />
                  <PawUI top="130vh" left="-5vw" rotation="right" width="45%" zIndex="-1" />
                </>
                : <>
                  <PawUI top="60vh" right="-4vw" rotation="left" width="350px" zIndex="-1" />
                  <PawUI top="140vh" left="-7vw" rotation="right" width="400px" zIndex="-1" />
                  <PawUI top="240vh" right="-8vw" rotation="left" width="450px" zIndex="-1" />
                  <PawUI top="320vh" left="-5vw" rotation="right" width="350px" zIndex="-1" />

                </>)}
              <div
                className={`insurances_container__bookmark_active${isMobile ? "" : "__desktop"}`}
                style={isLeft ? { color: "#B83739" } : { color: "#979593" }}
                onClick={() => { setIsLeft(true) }}>
                {userPets
                  ? (t("krankenExklusiv"))
                  : (<Skeleton variant="rounded" height={isMobileOnly ? 30 : 80} />)}
              </div>
              {isLeft &&
                <>
                  {userPets
                    ?
                    <div className={`insurances_container__status_${userPets.state === "ACTIVE" ? 'active' : 'inactive'} ${isMobile ? "mobile" : ""}`}>
                      {t(userPets.state === "ACTIVE" ? 'active' : 'canceled')}
                    </div>
                    : (
                      isMobile
                        ? <Skeleton variant="rounded" width={80} height={35} sx={{ position: "relative", top: "0", right: "0" }} />
                        : <Skeleton variant="rounded" width={120} height={45} sx={{ position: "absolute", top: "30vh", right: "10vw" }} />
                    )}

                  {/* Product missing bubble */}

                  {/* {userPets &&
                    <div className={`insurances_container__product ${isMobile ? "mobile" : ""}`}>
                      <div className={`bubble-product-missing ${(currentLanguage == 'de') ? "DE" : "EN"}`}></div>
                    </div>} */}
                  {userPets
                    ?
                    <div onClick={() => { }} className="insurances_container__header">
                      {t("krankenExklusiv")}
                    </div>
                    :
                    <Skeleton
                      variant="rounded"
                      width={isMobileOnly ? 200 : 350}
                      height={isMobileOnly ? 25 : 45}
                      sx={{ margin: "5vh 0 0 0" }}
                    />
                  }
                  <div className="gap_centered">
                    <div className="gap_centered__left_items">
                      <div className="gap_centered__item">{t("commOfIns")}:</div>
                      <div className="gap_centered__item">{t("renewsOn")}:</div>
                      <div className="gap_centered__item">{t("customerNumber")}:</div>
                      <div className="gap_centered__item">{t("insNumber")}:</div>
                      <div className="gap_centered__item">{t("insPremium")}:</div>

                      {/* Payment method  */}

                      {/* <div className="gap_centered__item" style={{ margin: "20px 0 0 0" }}>{t("paymentMethod")}:</div> */}
                      {/* <div className="gap_centered__item">{t("iban")}:</div> */}
                    </div>
                    <div className="gap_centered__right_items">
                      {userPets
                        ?
                        <div className="gap_centered__item">
                          {userPets.coverage.coverStart.split("T")[0]}
                          {/* <CustomTooltip name={"tooltip4"} /> */}
                        </div>
                        :
                        <div className="gap_centered__item">
                          <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                        </div>
                      }
                      {userPets
                        ? (
                          <div className="gap_centered__item">
                            {userPets.coverage.renewalDate.split("T")[0]}
                            <CustomTooltip name={`${t("tooltip5")} ${userPets.coverage.renewalDate.split("T")[0]}`} />
                          </div>
                        )
                        : (
                          <div className="gap_centered__item">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                      {userPets
                        ? (<div className="gap_centered__item">{userPets.customerId}</div>)
                        : (
                          <div className="gap_centered__item">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                      {userPets
                        ? (<div className="gap_centered__item">{userPets.documents?.policy_item?.policyId}</div>)
                        : (
                          <div className="gap_centered__item">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                      {userPets
                        ? (<div className="gap_centered__item">{userPets.premium} €/{t("month")}</div>)
                        : (
                          <div className="gap_centered__item">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}

                      {/* Payment method  */}

                      {/* {userPets
                        ? (<div className="gap_centered__item" style={{ margin: "20px 0 0 0" }}>Kreditkarte</div>)
                        : (
                          <div className="gap_centered__item" style={{ margin: "20px 0 0 0" }}>
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )}
                      {userPets
                        ? (<div className="gap_centered__item relative">
                          ****4242


                          <div
                            className={`bubble-change-payment ${(currentLanguage == 'de') ? "DE" : "EN"}`}
                            onClick={() => { }}
                          ></div>
                        </div>)
                        : (
                          <div className="gap_centered__item">
                            <Skeleton variant="rounded" width={isMobileOnly ? 150 : 250} height={isMobileOnly ? 20 : 30} />
                          </div>
                        )} */}
                    </div>
                  </div>


                  {userPets
                    ? (
                      <div className="documents_download">
                        <div className="documents_download__title">{t("downloadDoc")}</div>
                        <div
                          className="documents_download__link"
                          onClick={() => window.open(userPets.documents?.policy_item?.shortTimeAccessUrl, '_blank')}
                        >
                          <div className="documents_download__link__circle">
                            <img src={DownloadIcon} />
                          </div>
                          <div className="documents_download__link__bar">
                            {userPets.documents?.policy_item?.filename}
                          </div>
                        </div>
                        <div className="documents_download__link">
                          <div className="documents_download__link__circle">
                            <img src={DownloadIcon} />
                          </div>
                          <div
                            className="documents_download__link__bar"
                            onClick={() => window.open(userPets.documents?.policy_total?.url, '_blank')}
                          >
                            {userPets.documents?.policy_total?.fileName}
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div className="documents_download">
                        <div className="documents_download__title">{t("downloadDoc")}</div>
                        <Skeleton variant="rounded" width={isMobileOnly ? 300 : 500} height={isMobileOnly ? 30 : 60} />
                        <Skeleton variant="rounded" width={isMobileOnly ? 300 : 500} height={isMobileOnly ? 30 : 60} sx={isMobileOnly ? { margin: "0" } : { margin: "20px 0 0 0" }} />
                      </div>
                    )}

                  {/* Tariff`s cover is hidden below */}

                  {/* {userPets
                    ? (
                      <div className="tariff_cover">
                        <Markup className="tariff_cover__title" content={t("whatCovered")} />
                        <div className="tariff_cover__table">
                          <img src={PandaHead} width={180} height={100} />
                          {mockTariff.tarif_info.map(el => (
                            <div className="tariff_cover__item">
                              <div className="tariff_cover__left">{t(el.itemName)}</div>
                              <div className="tariff_cover__right_container">
                                {el.children.length > 1
                                  ? el.children.map(child => (
                                    <div className="tariff_cover__right_few">
                                      <div className="tariff_cover__right__details">
                                        {child.title &&
                                          <div className="tariff_cover__right__details__title">
                                            {t(child.title)}
                                          </div>}
                                        {child.content &&
                                          <div className="tariff_cover__right__details__content">
                                            {t(child.content)}
                                          </div>}
                                      </div>
                                      {child.tooltip &&
                                        <CustomTooltip name={child.tooltip} />}
                                    </div>
                                  ))
                                  : <div className="tariff_cover__right">
                                    <div className="tariff_cover__right__details">
                                      {el.children[0].title &&
                                        <div className="tariff_cover__right__details__title"
                                          style={{ fontSize: "21px" }}
                                        >
                                          {t(el.children[0].title)}
                                        </div>}
                                      {el.children[0].content &&
                                        <div className="tariff_cover__right__details__content">
                                          {t(el.children[0].content)}
                                        </div>}
                                    </div>
                                    {el.children[0].tooltip &&
                                      <CustomTooltip name={el.children[0].tooltip} />}
                                  </div>
                                }
                              </div>
                            </div>
                          ))}
                        </div> */}

                  {/* "Change contract" button */}

                  {/* <div
                          className={isMobile ? "button_red_mobile" : "button_red_common"}
                          style={isMobile ? { margin: "5vh 0 8vh 0" } : { margin: "5vh 0" }}>
                          {t("changeContract")}
                        </div> */}

                  {/* div below shows until upper btn is hidden */}
                  {/* <div style={{ height: '5vh' }}></div>
                      </div>
                    )
                    : (
                      <div className="tariff_cover">
                        <Markup className="tariff_cover__title" content={t("whatCovered")} />
                        <Skeleton variant="rounded" width={isMobile ? "100%" : 620} height={isMobileOnly ? 300 : 500} sx={{ margin: "20px 0 20px 0" }} />
                      </div>
                    )} */}

                  {/* Tariff`s details is hidden below */}

                  {/* {userPets
                    ? (
                      <div className="tariff_details">
                        <div className="tariff_details__title">{t("tariffInfo")}</div>
                        <div className="tariff_details__wrapper">
                          <div className="tariff_details__item">
                            <div className="tariff_details__item__left">
                              <img src={RedCheck} width={34} height={26} />
                            </div>
                            <div className="tariff_details__item__right">
                              <div className="tariff_details__item__right__header" onClick={() => setIsCheckOpen(!isCheckOpen)}>
                                {t("specialWaitingPreiod")}
                                <img src={ArrowUp} style={isCheckOpen ? ({}) : ({ rotate: "180deg" })} />
                              </div>
                              {isCheckOpen && !isMobile &&
                                <CheckDetails tariff={mockTariff} />}
                            </div>
                          </div>
                          {isCheckOpen && isMobile &&
                            <CheckDetails tariff={mockTariff} />}
                          <div className="tariff_details__item">
                            <div className="tariff_details__item__left">
                              <img src={BlackCross} width={23} height={27} />
                            </div>
                            <div className="tariff_details__item__right">
                              <div className="tariff_details__item__right__header" onClick={() => setIsCrossOpen(!isCrossOpen)}>
                                {t("notInsured")}
                                <img src={ArrowUp} style={isCrossOpen ? ({}) : ({ rotate: "180deg" })} />
                              </div>
                              {isCrossOpen && !isMobile && <CrossDetails tariff={mockTariff} />}
                            </div>
                          </div>
                          {isCrossOpen && isMobile && <CrossDetails tariff={mockTariff} />}
                        </div>
                      </div>
                    )
                    : (
                      <div className="tariff_details">
                        <div className="tariff_details__title">{t("tariffInfo")}</div>
                        <Skeleton variant="rounded" width={isMobile ? "100%" : 620} height={isMobile ? 40 : 70} sx={isMobile ? { margin: "0" } : { margin: "20px 0 0 0" }} />
                        <Skeleton variant="rounded" width={isMobile ? "100%" : 620} height={isMobile ? 40 : 70} sx={isMobile ? { margin: "5px 0 0 0" } : { margin: "20px 0 0 0" }} />
                      </div>
                    )} */}
                </>}
              {!isLeft && (
                <div>
                  Some info
                </div>
              )}
            </div>
          </div>
        </div>
        {isMobile
          ? <>
            <PawUI top="-28vh" right="9vw" rotation="right" width="37%" zIndex="-1" />
            <PawUI top="42vh" left="0" rotation="right" width="42%" zIndex="-1" />
          </>
          : <>
            <PawUI top="3vh" right="1vw" rotation="right" width="215px" zIndex="-1" />
            <PawUI top="82vh" left="-5vw" rotation="right" width="350px" zIndex="-1" />
          </>}
      </div>

      <Footer />

      {openModalWhatToDo && (
        <ModalWhatToDo
          setOpenModalWhatToDo={setOpenModalWhatToDo}
          petId={id}
        />
      )}
      {openModalEditData && (
        <ModalEditData
          openModalEditData={openModalEditData}
          setOpenModalEditData={setOpenModalEditData}
          petInfo={userPets}
          submitPetData={submitPetData}
        />
      )}
    </div>
  );
}
