import React, { useEffect, useState } from "react";
import Loading from "../../../components/common/Loading";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Request } from "../../../config/requests";
import { getToken } from "../../../config/token";
import { Select, OutlinedInput, MenuItem, Box, Button, FormControlLabel, Switch, FormControl, Input, InputLabel, TextField, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import useLocalStorage from "use-local-storage";
import {
  initialFormsOCR,
  CLAIM_PROPS,
} from "../../../constants/ApplicationConstants";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import uuidv4 from "react-uuid";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { showToast } from "../../../config/helpers";


function AuditOcr() {
  const { t } = useTranslation();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [ocr, setOcr] = useState({ "claims": [] });
  const [inputNameFields, setInputNameFields] = React.useState(["petName"]);
  const [inputChipFields, setInputChipFields] = React.useState(["chipNumber"]);
  const [formData, setFormData] = useState(initialFormsOCR);
  const [tableDetails, setTableDetails] = React.useState([]);
  const [gotDetails, setGotDetails] = useState([]);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [diagValues, setDiagValues] = useState([]);
  const [initialPetName, setInitialPetName] = React.useState(null);
  const [initialChipNumber, setInitialChipNumber] = React.useState(null);
  const [initialDiagValues, setInitialDiagValues] = React.useState(null);
  const [showDiagTrigger, setShowDiagTrigger] = React.useState(false);
  const [isStatisticLoading, setIsStatisticLoading] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [noDagnosis, setNoDagnosis] = useState(false);
  const [notInvoice, setNotInvoice] = useState(false);
  const [variant, setVariant] = useState(null);
  // const [attempts, setAttempts] = useState(0);
  let attempts = 0;

  const inputInvoiceFields = ["invoiceNumber", "invoiceDate", "veterinarian", "taxId"];
  const variantsList = [
    "Report Profendoctor",
    "Foto",
    "Operationsbericht",
    "Krankengeschichte",
    "Anamnese",
    "Unvollständige Rechnung (Seiten fehlen)",
    "Bericht Tierarzt",
    "Anderes Dokument",
  ]

  const filter = createFilterOptions();


  const classes = {
    gotRoot: {
      backgroundColor: "var(--b-color-text-primary-2)",
      height: "100%",
      width: "1300px",
      padding: "4px 4px 5px 4px !important",
      fontSize: "12px",
      borderRadius: "5px",
      border: "thin solid var(--b-shadow-main)",
      //   marginRight: "20px",
    },
    datePickerInput: {
      margin: "0px !important",
    },
    descriptionRoot: {
      paddingTop: "0px",
    },
    descriptionInput: {
      backgroundColor: "var(--b-color-text-primary-2)",
      height: "20px",
      width: "200px",
      padding: "4px 4px 5px 4px",
      fontSize: "12px",
      borderRadius: "5px",
      border: "thin solid var(--b-shadow-main)",
    },
    gotInput: {
      cursor: "pointer",
      border: "1px solid var(--b-color-bg-additional-2)",
      padding: "10px 14px",
      borderRadius: "4px",
      width: "100px",
    },
    gotSelect: {
      backgroundColor: "var(--b-color-text-primary-2)",
      fontSize: "12px",
      borderRadius: "5px",
    },
    selection: {
      fontSize: "12px",
    },
    selectionSelected: {
      // color: [themeCss.textPrimary, "!important"].join(" "),
      // backgroundColor: [themeCss.textSecondary, "!important"].join(" "),
      // fontFamily: themeCss.fontSecondary,
    },
    menuStyles: {
      maxHeight: "80%",
    },
  };

  function getStatistics() {
    setIsStatisticLoading(true);
    return Request({ method: "get", route: "api/claims/audit_ocr/statistics" })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setStatistics([json.data.checked, json.data.not_checked]);
      })
      .finally(() => {
        setIsStatisticLoading(false);
      });
  };

  function getDiagList() {
    return Request({ method: "get", route: "api/diagnosis" })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setDiagnosisList(json.data.data);
      })
  };

  function getGOT() {
    return Request({ method: "get", route: "api/claims/got" })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setGotDetails(json.got);
      })
  };

  function getOcr() {
    return Request({
      method: "get",
      route: "api/claims/audit_ocr",
      token: getToken(),
    })
      .then((res) => {
        if (res && attempts <= 5) {
          console.log("ATT >>>", attempts)
          if (res.status === 500) {
            console.log("STATUS 500");
            showToast(t("errorReloading"), { position: "top-center" });
            // throw new Error("Server error: " + res.status);
            setTimeout(() => {
              reloadData();
            }, 2000);
          }
          if (res.status === 200) {
            if (!res.ok) {
              console.log("Res not OK, but 200");
              showToast(t("errorReloading"), { position: "top-center" });
              // throw new Error("Response error: " + res.status);
              setTimeout(() => {
                reloadData();
              }, 3000);
            } else {
              showToast(t("ocrLoaded"), { position: "top-center" });
              return res.json();
            }
          }
        } else {
          if (attempts <= 5) {
            showToast(t("errorReloading"), { position: "top-center" });
            setTimeout(() => {
              reloadData();
            }, 2000);
          } else {
            showToast(t("Something going wrong..."), { position: "top-center" });
            history("/audit");
          }
        }

      })
      .catch((err) => {
        console.error(err.message);
        if (err.message.includes('net::ERR_NETWORK_CHANGED')) {
          console.log("ERROR >>> err.message:", err.message)
          reloadData();
        }
      })
      .then(async (json) => {
        if (json.data) {
          setOcr(json.data);
          if (json.data.ocr.PetName?.value) {
            if (json.data.ocr.PetName?.value?.length != 0) {
              setInputNameFields(["petName"]);
            }
          } else {
            setInputNameFields(["petName", "petNameTrigger"]);

          }
          if (json.data.ocr.ChipNumber?.value) {
            if (json.data.ocr.ChipNumber?.value?.length != 0) {
              setInputChipFields(["chipNumber"]);
            }
          } else {
            setInputChipFields(["chipNumber", "chipNumberTrigger"]);
          }
          setInitialPetName(json.data.ocr.PetName?.value || null);
          setInitialChipNumber(json.data.ocr.ChipNumber?.value || null);
          setInitialDiagValues(json.data.ocr.Diagnosis?.values || []);
          setDiagValues(json.data.ocr.Diagnosis?.values || []);
          setFormData((prevFormData) => ({
            ...prevFormData,
            invoiceNumber: json.data.ocr.InvoiceId?.value || "No info",
            invoiceDate: json.data.ocr.InvoiceDate?.value || "No info",
            veterinarian: json.data.ocr.VendorName?.value || "No info",
            taxId: json.data.ocr.VendorTaxId?.value || "No info",
            petName: json.data.ocr.PetName?.value || "No info",
            chipNumber: json.data.ocr.ChipNumber?.value || "No info",
            totalNet: json.data.ocr.SubTotal?.value?.amount || null,
            totalTax: json.data.ocr.TotalTax?.value?.amount || null,
            totalGross: json.data.ocr.InvoiceTotal?.value?.amount || null,
            diagnosis: json.data.ocr.Diagnosis?.values || "No info",
          }));

          let restructuredData = json.data.ocr?.Items?.value
            .map(el => el.value)
            .map(item => {
              return {
                Id: uuidv4(),
                Amount: item.Amount?.value.amount || "",
                Date: item.Date?.value || "",
                Description: item.Description?.content || "",
                Quantity: item.Quantity?.value || "",
                GOT: item.Got?.value || "",
                UnitPrice: item.UnitPrice?.value.amount || "",
              };
            }) || [];
          setTableDetails(restructuredData);
        } else {
          console.log("Empty DATA")
          showToast(t("errorReloading"), { position: "top-center" });
          setTimeout(() => {
            reloadData();
          }, 2000);
        }
      })
      .finally(() => { setIsLoading(false) });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (["totalNet", "totalTax", "totalGross"].includes(name)) {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: Number(value) }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }

  };

  function handleUpdate(index, key, event) {
    let newTableDetails = [...tableDetails];
    let val = null;
    if (key === CLAIM_PROPS.GOT) {
      newTableDetails[index][key] = event;
    } else if (key === CLAIM_PROPS.DATE) {
      const date = new Date(event);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      newTableDetails[index][key] = `${year}-${month}-${day}`;
    } else {
      val = event.target.value;
      if ([CLAIM_PROPS.QUANTITY, CLAIM_PROPS.UNIT_PRICE, CLAIM_PROPS.AMOUNT].includes(key)) {
        newTableDetails[index][key] = Number(val);
      } else if (key === CLAIM_PROPS.DESCRIPTION) {
        newTableDetails[index][key] = val;
      }
    }
    setTableDetails(newTableDetails);
  }

  async function getData() {
    setIsLoading(true);
    try {
      await Promise.all([getStatistics(), getOcr(), getGOT(), getDiagList()]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      // showToast(t("ocrLoaded"), {position: "top-center"});
    }
  };

  const increaseAttempts = () => {
    // setAttempts(prevAttempts => prevAttempts + 1);
    attempts++;
  };

  async function reloadData() {
    increaseAttempts();
    setIsLoading(true);
    setIsConfirming(false);
    setTableDetails([]);
    setFormData(initialFormsOCR);
    setInputNameFields(["petName"]);
    setInputChipFields(["chipNumber"]);
    try {
      await Promise.all([getStatistics(), getOcr(), getDiagList()]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      // showToast(t("ocrLoaded"), {position: "top-center"});
    }
  };

  useEffect(() => {
    getData();
    console.log("calling OCR...");
  }, []);

  useEffect(() => {
    if (formData.petName.length == 0) {
      setInputNameFields(["petName", "petNameTrigger"])
    }
  }, [formData.petName]);

  useEffect(() => {
    if (formData.petName.length == 0) {
      setInputNameFields(["petName", "petNameTrigger"]);
    } else if (formData.petName !== initialPetName) {
      setInputNameFields(["petName", "petNameTrigger"]);
    } else {
      setInputNameFields(["petName"]);
    }
  }, [formData.petName, initialPetName]);

  useEffect(() => {
    if (formData.chipNumber.length == 0) {
      setInputChipFields(["chipNumber", "chipNumberTrigger"]);
    } else if (formData.chipNumber !== initialChipNumber) {
      setInputChipFields(["chipNumber", "chipNumberTrigger"]);
    } else {
      setInputChipFields(["chipNumber"]);
    }
  }, [formData.chipNumber, initialChipNumber]);

  function handleDelete(index) {
    let newTableDetails = [...tableDetails];
    newTableDetails.splice(index, 1);
    setTableDetails(newTableDetails);
  }

  function handleNew() {
    let newTableDetails = [...tableDetails];
    newTableDetails.push({
      Id: uuidv4(),
    });
    setTableDetails(newTableDetails);
  }

  function handleConfirm() {
    setIsLoading(true);
    setIsStatisticLoading(true);
    Request({
      method: "post",
      route: "api/claims/audit_ocr",
      token: getToken(),
      values: {
        "id": ocr.id,
        "manual": {
          ...Object.fromEntries(Object.entries(formData).filter(([key]) => key !== 'diagnosisTrigger')),
          "items": [...tableDetails],
          "diagnosis": {
            "values": [...diagValues],
            "trigger": formData["diagnosisTrigger"],
            "isEmpty": diagValues.length === 0
          },
          "attachment_type": variant === null ? "Invoice" : variant,
        },
      }
    })
      .then((res) => {
        return res.json();
      })
      .then(() => {
        showToast(t("confirmed"), { position: "top-center" });
      })
      .finally(() => {
        setIsConfirming(false);
        setNotInvoice(false);
        setVariant(null);
        setTableDetails([]);
        setFormData(initialFormsOCR);
        setInputNameFields(["petName"]);
        setInputChipFields(["chipNumber"]);
        getOcr();
        getStatistics();
        getDiagList();
      });
  }

  function allDatesHandler() {
    let tempDate = tableDetails[0]?.Date || null;
    let editedTableDetails = [...tableDetails].map((el) => ({ ...el, Date: tempDate }));
    setTableDetails(editedTableDetails);
  }

  function noDiagHandler() {
    setShowDiagTrigger(false);
    setDiagValues([]);
    setNoDagnosis(true);
  }

  return (
    <div className="FMContainer">
      <div className="AuditContent">
        <div className="DuplicateWrap">
          <div className="HeaderContainerDuplicates">
            <div className="HeaderDuplicates">
              {t("auditOCR")}
            </div>
            {!isStatisticLoading && (
              <div className="CountContainer">
                {statistics[1]} {t("isOfPending")} {statistics[0] + statistics[1]}
              </div>
            )}
            <div
              className="ButtonReverse Enabled"
              onClick={() => history("/audit")}>
              {t("auditClaimsBack")}
            </div>
          </div>
          {isLoading
            ? (<Loading noText fast />)
            : (<div className="DuplicateDocWrap">
              <div
                className="PdfDoc"
              >
                <ReactPanZoom
                  image={`data:image/jpeg;base64, ${ocr?.image?.content}`}
                  alt="Image alt text"
                />
              </div>
              <div className="checkDoc">
                <div className="inputTitle"> {t("invoiceInfo")} </div>
                <div className="inputsOCR">
                  {inputInvoiceFields.map((el, key) => (
                    <div>
                      <FormControl
                        key={key}
                        sx={{
                          padding: "0 !important",
                          height: "46px !important",
                          margin: "5px 10px",
                        }}
                      >
                        <InputLabel
                          id={`demo-multiple-name-label-${el}`}
                          sx={{ background: "var(--b-color-text-primary-2)" }}
                        >
                          {t(el)}
                        </InputLabel>
                        <Input
                          id={`demo-multiple-name-${el}`}
                          classes={{
                            root: classes.gotRoot,
                            input: classes.gotInput,
                          }}
                          type="text"
                          name={el}
                          onChange={handleInputChange}
                          value={formData[el]}
                          sx={{
                            margin: "0 !important",
                            height: "100% !important",
                            width: "200px",
                            border: "thin solid var(--b-shadow-main)",
                            fontSize: "12px",
                            borderRadius: "5px",
                            backgroundColor: "var(--b-color-text-primary-2)",
                            padding: "0 15px"
                          }}
                          disableUnderline
                        />
                      </FormControl>
                    </div>
                  ))}
                  <Autocomplete
                    freeSolo
                    clearOnBlur
                    disableClearable
                    options={diagnosisList.map(el => el)}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== "") {
                        filtered.push(`"${t("add")}" ${params.inputValue}`);
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option;
                    }}
                    renderInput={(params) => (
                      <TextField
                        label={t("diagnosis")}
                        {...params}
                        style={{
                          width: 420,
                          padding: "0 !important",
                          height: "46px !important",
                          margin: "5px 10px",
                        }}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          style: { minHeight: "46px" }
                        }}
                      />
                    )}
                    value={diagValues}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.length > 0) {
                        let newTagValue = newValue[newValue.length - 1];
                        let existingTags = diagValues.map((tag) =>
                          tag.toLowerCase()
                        );
                        if (
                          !existingTags.includes(newTagValue.toLowerCase())
                        ) {
                          if (newTagValue.startsWith('"Add"')) {
                            newTagValue = newTagValue.substring(6);
                          }
                          if (newTagValue.startsWith('"Hinzufügen"')) {
                            newTagValue = newTagValue.substring(13);
                          }
                          setDiagValues(prev => [...prev, newTagValue]);
                        } else {
                          setDiagValues(newValue);
                        }
                      } else {
                        setDiagValues(newValue);
                      }
                      (JSON.stringify(newValue) !== JSON.stringify(initialDiagValues))
                        ? setShowDiagTrigger(true)
                        : setShowDiagTrigger(false);
                      setNoDagnosis(false);
                    }}
                    multiple
                    filterSelectedOptions={false}
                  />
                  {showDiagTrigger && (
                    <FormControl
                      key={"diagnosisTrigger"}
                      sx={{
                        padding: "0 !important",
                        height: "46px !important",
                        margin: "5px 10px",
                      }}
                    >
                      <InputLabel
                        id={`demo-multiple-name-label-diagnosisTrigger`}
                        sx={{ background: "var(--b-color-text-primary-2)" }}
                      >
                        {t("diagnosisTrigger")}
                      </InputLabel>
                      <Input
                        id={`demo-multiple-name-diagnosisTrigger`}
                        classes={{
                          root: classes.gotRoot,
                          input: classes.gotInput,
                        }}
                        type="text"
                        name={"diagnosisTrigger"}
                        onChange={handleInputChange}
                        value={formData["diagnosisTrigger"]}
                        sx={{
                          margin: "0 !important",
                          height: "100% !important",
                          width: "200px",
                          border: "thin solid var(--b-shadow-main)",
                          fontSize: "12px",
                          borderRadius: "5px",
                          backgroundColor: "var(--b-color-text-primary-2)",
                          padding: "0 15px"
                        }}
                        disableUnderline
                      />
                    </FormControl>
                  )}
                  {!noDagnosis
                    ? (<div
                      className="ButtonReverse Enabled"
                      style={{
                        margin: "5px 10px",
                        height: "46px",
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-start",
                      }}
                      onClick={noDiagHandler}
                    >
                      {t("noDiag")}
                    </div>)
                    : (<div
                      className="ButtonReverse ButtonDisabledRed"
                      style={{
                        margin: "5px 10px",
                        height: "46px",
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-start",
                      }}
                    >
                      {t("noDiag")}
                    </div>)}
                </div>
                <div className="inputTitle"> {t("petInfo")} </div>
                <div className="inputsOCR">
                  {inputNameFields.map((el, key) => (
                    <div>
                      <Tooltip title={el == "petNameTrigger" ? t("petTriggerTooltip") : ""} placement="right">
                        <FormControl
                          key={key}
                          sx={{
                            padding: "0 !important",
                            height: "46px !important",
                            margin: "5px 10px",
                          }}
                        >
                          <InputLabel
                            id={`demo-multiple-name-label-${el}`}
                            sx={{ background: "var(--b-color-text-primary-2)" }}
                          >
                            {t(el)}
                          </InputLabel>
                          <Input
                            id={`demo-multiple-name-${el}`}
                            classes={{
                              root: classes.gotRoot,
                              input: classes.gotInput,
                            }}
                            type="text"
                            name={el}
                            onChange={handleInputChange}
                            value={formData[el]}
                            sx={{
                              margin: "0 !important",
                              height: "100% !important",
                              width: "200px",
                              border: "thin solid var(--b-shadow-main)",
                              fontSize: "12px",
                              borderRadius: "5px",
                              backgroundColor: "var(--b-color-text-primary-2)",
                              padding: "0 15px"
                            }}
                            disableUnderline
                          />
                        </FormControl>
                      </Tooltip>
                    </div>
                  ))}
                </div>
                <div className="inputsOCR">
                  {inputChipFields.map((el, key) => (
                    <div>
                      <Tooltip title={el == "chipNumberTrigger" ? t("chipTriggerTooltip") : ""} placement="right">
                        <FormControl
                          key={key}
                          sx={{
                            padding: "0 !important",
                            height: "46px !important",
                            margin: "5px 10px",
                          }}
                        >
                          <InputLabel
                            id={`demo-multiple-name-label-${el}`}
                            sx={{ background: "var(--b-color-text-primary-2)" }}
                          >
                            {t(el)}
                          </InputLabel>
                          <Input
                            id={`demo-multiple-name-${el}`}
                            classes={{
                              root: classes.gotRoot,
                              input: classes.gotInput,
                            }}
                            type="text"
                            name={el}
                            onChange={handleInputChange}
                            value={formData[el]}
                            sx={{
                              margin: "0 !important",
                              height: "100% !important",
                              width: "200px",
                              border: "thin solid var(--b-shadow-main)",
                              fontSize: "12px",
                              borderRadius: "5px",
                              backgroundColor: "var(--b-color-text-primary-2)",
                              padding: "0 15px"
                            }}
                            disableUnderline
                          />
                        </FormControl>
                      </Tooltip>
                    </div>
                  ))}
                </div>
                <div className="inputTitle"> {t("itemInfo")} </div>
                <div>
                  {/* table */}
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="HeaderTableQuoteDetails d-flex align-items-center" align="left">
                            {t("date")}
                            <div
                              style={{
                                display: "flex",
                                width: "auto",
                                margin: "0 10px",
                                padding: "5px !important",
                              }}
                              className={tableDetails[0]?.Date ? "ButtonReverse Enabled" : "ButtonReverse ButtonDisabledRed"}
                              onClick={allDatesHandler}
                            >
                              {t("allDatesSame")}
                            </div>
                          </TableCell>
                          {[
                            // "date",
                            "desc",
                            "got",
                            "count",
                            "unitPrice",
                            "total",
                            "",
                          ].map((h) => (
                            <TableCell className="HeaderTableQuoteDetails" align="left">
                              {t(h)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableDetails?.map((row, index) => (
                          <TableRow
                            key={row.Id}
                            sx={{
                              verticalAlign: "top",
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell classes={{ input: classes.datePickerInput }}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  value={new Date(row?.Date)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        ".MuiInputBase-root": {
                                          padding: "0 !important",
                                          height: "31px !important",
                                        },
                                        ".MuiInputBase-input": {
                                          backgroundColor: "var(--b-color-text-primary-2) !important",
                                          height: "20px !important",
                                          padding: "4px 0 5px 4px !important",
                                          fontSize: "12px !important",
                                          width: "65px !important",
                                        },
                                        ".MuiInputAdornment-root": {
                                          backgroundColor: "var(--b-color-text-primary-2) !important",
                                          height: "70px !important",
                                          margin: "0 !important",
                                          padding: "4px 0 5px 0 !important",
                                          fontSize: "12px !important",
                                          width: "35px !important",
                                        },
                                      }}
                                    />
                                  )}
                                  classes={{ selected: classes.selectionSelected }}
                                  onChange={(e) => handleUpdate(index, "Date", e)}
                                />
                              </LocalizationProvider>
                            </TableCell>
                            <TableCell className="Cells" key={2}>
                              <Input
                                defaultValue={row?.Description}
                                classes={{
                                  root: classes.descriptionRoot,
                                  input: classes.descriptionInput,
                                }}
                                multiline
                                maxRows={4}
                                disableUnderline
                                onChange={(e) => handleUpdate(index, "Description", e)}
                              />
                            </TableCell>
                            <TableCell key={3}>
                              <Tooltip
                                title={
                                  row?.GOT
                                    ? gotDetails.filter(
                                      (g) =>
                                        g["GOT Nummer"]
                                          .toString()
                                          .toLocaleLowerCase()
                                          .replace(/[^a-zA-Z0-9]/g, "") ===
                                        row?.GOT.toString()
                                          .toLocaleLowerCase()
                                          .replace(/[^a-zA-Z0-9]/g, "")
                                    )[0]?.Bezeichnung
                                    : t("addGot")
                                }
                                placement="top"
                              >
                                <FormControl
                                  sx={{ minWidth: 100 }}
                                >
                                  <InputLabel
                                    id="GOT-label"
                                    sx={{ background: "var(--b-color-text-primary-2)" }}
                                  >
                                    GOT
                                  </InputLabel>
                                  <Select
                                    labelId="GOT-label"
                                    id="GOT-name"
                                    value={row?.GOT}
                                    onChange={(e) => handleUpdate(index, "GOT", e.target.value)}
                                    input={
                                      <OutlinedInput
                                        label={t("name")}
                                      />
                                    }
                                    classes={{ select: classes.gotSelect, }}
                                    MenuProps={{ PaperProps: { style: classes.menuStyles } }}
                                  >
                                    <MenuItem
                                      key={`item-noGOT`}
                                      value={""}
                                      classes={{
                                        root: classes.selection,
                                        selected: classes.selectionSelected,
                                      }}
                                    >
                                      No GOT
                                    </MenuItem>
                                    {gotDetails.map((item, idx) => (
                                      <MenuItem
                                        key={`item-${idx}`}
                                        value={item["GOT Nummer"]}
                                        classes={{
                                          root: classes.selection,
                                          selected: classes.selectionSelected,
                                        }}
                                      >
                                        {item["GOT Nummer"]}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Tooltip>
                            </TableCell>
                            {["Quantity", "UnitPrice", "Amount"].map((h, index3) => {
                              return (
                                <TableCell key={[index, index3].join("-")}>
                                  <Input
                                    defaultValue={row?.[h]}
                                    classes={{ root: classes.input }}
                                    disableUnderline
                                    sx={{
                                      ...classes.gotInput,
                                    }}
                                    type="number"
                                    align="right"
                                    startAdornment={
                                      h === "Amount"
                                        ? (<InputAdornment position="start">€</InputAdornment>)
                                        : ("")
                                    }
                                    onChange={(e) => handleUpdate(index, h, e)}
                                  />
                                </TableCell>
                              );
                            })}
                            <TableCell>
                              <Tooltip title="Delete the line" placement="left">
                                <div className="Delete" onClick={() => handleDelete(index)}>
                                  <ClearRoundedIcon
                                    fontSize="small"
                                    color="disabled"
                                    sx={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={7}>
                            <div className="Add" onClick={() => handleNew()}>
                              <AddCircleOutlineRoundedIcon
                                fontSize="small"
                                sx={{ color: "#5edb5a" }}
                              />
                              <span className="AddText">{t("addLine")}</span>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={7}>
                            <div className="inputTitle"> {t("totalInfo")} </div>
                            <div className="Total">
                              <div className="inputsTotal">
                                {["totalNet", "totalTax", "totalGross"].map((el, key) => (
                                  <FormControl
                                    key={key}
                                    sx={{
                                      padding: "0 !important",
                                      height: "46px !important",
                                      margin: "5px 10px",
                                    }}
                                  >
                                    <InputLabel
                                      id={`demo-multiple-name-label-${el}`}
                                      sx={{ background: "var(--b-color-text-primary-2)" }}
                                    >
                                      {t(el)}
                                    </InputLabel>
                                    <Input
                                      id={`demo-multiple-name-${el}`}
                                      classes={{
                                        root: classes.gotRoot,
                                        input: classes.gotInput,
                                      }}
                                      type="number"
                                      name={el}
                                      onChange={handleInputChange}
                                      value={formData[el]}
                                      sx={{
                                        margin: "0 !important",
                                        height: "100% !important",
                                        width: "200px",
                                        border: "thin solid var(--b-shadow-main)",
                                        fontSize: "12px",
                                        borderRadius: "5px",
                                        backgroundColor: "var(--b-color-text-primary-2)",
                                        padding: "0 15px"
                                      }}
                                      disableUnderline
                                    />
                                  </FormControl>
                                ))}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={7}>
                            <FormControl
                              sx={{
                                padding: "0 !important",
                                height: "46px !important",
                                margin: "5px 10px",
                                width: "98%",
                              }}
                            >
                              <TextField
                                multiline
                                name={"comment"}
                                label={`${t("comment")}*`}
                                value={formData.comment}
                                onChange={handleInputChange}
                              // sx={{ width: "100%" }}
                              // rows={2}
                              />
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div
                  className="ButtonReverse Enabled"
                  style={{
                    margin: "15px 10px",
                    width: "200px",
                  }}
                  onClick={() => setNotInvoice(true)}
                >
                  {t("notInvoice")}
                </div>
                {notInvoice && (
                  <FormControl
                    sx={{
                      minWidth: "200px",
                      padding: "0 !important",
                      height: "46px !important",
                      margin: "15px 10px",
                    }}
                    size="small"
                  >
                    <InputLabel
                      id="variants-label"
                      sx={{ background: "var(--b-color-text-primary-2)" }}
                    >
                      {t("variants")}
                    </InputLabel>
                    <Select
                      labelId="variants-label"
                      id="variants-name"
                      value={variant}
                      onChange={(e) => setVariant(e.target.value)}
                      input={
                        <OutlinedInput
                          label={t("name")}
                        />
                      }
                      classes={{ select: classes.gotSelect, }}
                      MenuProps={{ PaperProps: { style: classes.menuStyles } }}
                    >
                      <MenuItem
                        key={`item-noVar`}
                        value={null}
                        classes={{
                          root: classes.selection,
                          selected: classes.selectionSelected,
                        }}
                      >
                        No variant
                      </MenuItem>
                      {variantsList.map((item, idx) => (
                        <MenuItem
                          key={`item-${idx}`}
                          value={item}
                          classes={{
                            root: classes.selection,
                            selected: classes.selectionSelected,
                          }}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <div>
                  {isConfirming
                    ? <Loading noText fast />
                    : <div
                      className="ButtonReverse Enabled"
                      style={{
                        width: "200px",
                        margin: "15px 10px",
                      }}
                      onClick={() => handleConfirm()}
                    >
                      {t("confirm")}
                    </div>
                  }
                </div>
              </div>
            </div>)}
        </div>
      </div>
    </div>
  );
}

export default AuditOcr;
