import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import { showToast } from "../../../config/helpers";
import ModalWrapper from "../../../components/modalWrapper";

function ModalSupport({
  openModalSupport,
  setOpenModalSupport,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmail = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  };

  function handleClose() {
    setOpenModalSupport(false);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    handleClose();
    showToast(t("emailConfirmed"));
  };

  return (
    <ModalWrapper
      headerTitle={t("supportContact")}
      openModal={openModalSupport}
      setModalWrapper={() => setOpenModalSupport(false)}
    >
      <div className="modal_container__body__inputs">
        <input
          className="modal_container__body__input"
          placeholder={t("yourName") + "*"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className="modal_container__body__input"
          style={isValidEmail ? {} : { border: "3px solid red" }}
          placeholder={t("email") + "*"}
          value={email}
          onChange={handleEmail}
        />
        <textarea
          className="modal_container__body__input"
          style={isMobileOnly ? { height: "130px" } : { height: "230px" }}
          placeholder={t("message") + "*"}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div
        className={
          (name && email && message && isValidEmail)
            ? `button_red_small ${isMobileOnly && "mobile"}`
            : `button_red_smal_disabled ${isMobileOnly && "mobile"}`
        }
        onClick={(name && email && message && isValidEmail)
          ? handleSubmit
          : ((e) => e.stopPropagation())}
      >
        {t("toSend")}
      </div>
    </ModalWrapper>
  );
}

export default ModalSupport;
