import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getToken } from "../../config/token";
import { Request } from "../../config/requests";
import ModalPreview from "./modals/ModalPreview";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Fade,
  Pagination,
  PaginationItem,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import ModalChat from "./modals/ModalChat";
import ShowFileIcon from "../../assets/iconsSmall/list.svg";
import ShowDoc from "../../assets/iconsSmall/document.svg";
import Lightning from "../../assets/iconsSmall/lightning.svg";
import PandaMain from "../../assets/UserInfo/panda-logo-main.svg";
import SortAsc from "../../assets/iconsSmall/sortAsc.svg";
import ArrowLeft from "../../assets/PetPage/arrow_left_dashed_small.svg";
import ArrowRight from "../../assets/PetPage/arrow_right_dashed.svg";
import ShowFileIconRed from "../../assets/iconsSmall/line_items_red.svg";
import ShowDocRed from "../../assets/iconsSmall/document_red.svg";
import { ReactComponent as ArrowLeftRed } from "../../assets/iconsSmall/arrow_left_solid_red.svg";
import { ReactComponent as ArrowRightRed } from "../../assets/iconsSmall/arrow_right_solid_red.svg";
import {
  claimsTableHeaders,
  claimsTableHeadersAdmin,
  formatAnyDate,
  formatTableDate,
  getDate,
} from "../../config/helpers";
import { isMobile } from "react-device-detect";
import ModalAdvice from "./modals/ModalAdvice";
import { USER_MODE } from "../../constants/ApplicationConstants";
import { useTableScroll } from "../../hooks/useTableScroll";
import breedListDogs from "../../assets/dog_breeds.json";
import breedListCats from "../../assets/cat_breeds.json";

function PolicyTable({
  data,
  setData,
  themeSetting,
  tableData,
  setCurrentId,
  setOpenModalInfo,
  onUpdateTable,
  policyData,
  mode,
}) {
  const { t } = useTranslation();
  const [preview, setPreview] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAdviceOpen, setIsModalAdviceOpen] = useState(false);
  const [advice, setAdvice] = useState("");
  const [, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [anchorEl] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedData, setSortedData] = useState(policyData);
  const [currentClaim, setCurrentClaim] = useState(1);

  // console.log("PT > sortedData", sortedData)

  const handlePage = (event, value) => {
    setCurrentClaim(value);
  };

  const openMore = (index, e) => {
    e.stopPropagation();
    setCurrentId(index);
    setOpenModalInfo(true);
  };

  const onUpdate = (row) => {
    onUpdateTable(
      tableData.map((i) => {
        if (i.id === row.id) {
          return { ...i, manual: row.manual };
        }
        return i;
      })
    );
  };

  const decreasePageNumber = () => {
    if (currentClaim > 1) {
      setCurrentClaim(currentClaim - 1);
    }
  };

  const increasePageNumber = () => {
    if (currentClaim < sortedData.length) {
      setCurrentClaim(currentClaim + 1);
    }
  };

  const onEditClaim = () => {
    setIsEdit(true);
    setIsPopupOpen(false);
  };

  useEffect(() => {
    setIsPopupOpen(false);
  }, [selectedRow]);

  const handleSort = (column) => {
    const isAsc = sortedColumn === column && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortedColumn(column);
    const sorted = [...policyData].sort((a, b) => {
      let valueA, valueB;

      if (column === "Date") {
        valueA = new Date(
          formatTableDate(
            a?.additionalProperties?.additionalProperties?.dateOfInvoice1 ||
              a?.additionalProperties?.dateOfInvoice1 ||
              a.incidentDate
          )
        );
        valueB = new Date(
          formatTableDate(
            b?.additionalProperties?.additionalProperties?.dateOfInvoice1 ||
              b?.additionalProperties?.dateOfInvoice1 ||
              b.incidentDate
          )
        );
      } else if (column === "claimType") {
        valueA =
          a.additionalProperties?.additionalProperties?.claim_type ||
          a.additionalProperties?.claim_type ||
          t("noInfo");
        valueB =
          b.additionalProperties?.additionalProperties?.claim_type ||
          b.additionalProperties?.claim_type ||
          t("noInfo");
      } else if (column === "diagnosis") {
        valueA =
          a.additionalProperties?.additionalProperties?.diagnose ||
          a.additionalProperties?.diagnose ||
          t("noInfo");
        valueB =
          b.additionalProperties?.additionalProperties?.diagnose ||
          b.additionalProperties?.diagnose ||
          t("noInfo");
      } else if (column === "petName") {
        valueA = a.additionalProperties?.pet?.name || t("noInfo");
        valueB = b.additionalProperties?.pet?.name || t("noInfo");
      } else if (column === "Status") {
        valueA = a.state.toLowerCase();
        valueB = b.state.toLowerCase();
      } else if (column === "veterinarian") {
        valueA =
          a.additionalProperties?.additionalProperties?.veterinarian ||
          a.additionalProperties?.veterinarian ||
          t("noInfo");
        valueB =
          b.additionalProperties?.additionalProperties?.veterinarian ||
          b.additionalProperties?.veterinarian ||
          t("noInfo");
      }

      if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      }

      if (valueA < valueB) return isAsc ? 1 : -1;
      if (valueA > valueB) return isAsc ? -1 : 1;
      return 0;
    });

    setSortedData(sorted);
  };

  const breedParse = (breed) => {
    if (breed) {
      let spices =
        breed.split("_")[0] === "DOG" ? breedListDogs : breedListCats;
      let result = spices.find((el) => el.value === breed)?.value_name;
      if (result) return result === "Unbekannt" ? t("Unbekannt") : result;
    }
    return breed === "Unbekannt" ? t("Unbekannt") : breed;
  };

  useEffect(() => {
    setSortedData([...policyData]);
    handleSort("Date");
    recalculate();
  }, [policyData]);

  const handleSetRow = (id, index) => {
    setExpandedRow(expandedRow === index ? null : index);
    setSelectedRow(id);
    recalculate();
  };

  const { bodyRef, headerRef, syncScroll, listOfItemsRef, recalculate } =
    useTableScroll();

  return (
    <>
      {!isMobile && (
        <img
          src={PandaMain}
          alt="Panda_Main_Logo"
          className="main-logo-policy"
        />
      )}
      {isModalAdviceOpen && (
        <ModalAdvice
          isModalAdviceOpen={isModalAdviceOpen}
          setIsModalAdviceOpen={setIsModalAdviceOpen}
          advice={advice}
        />
      )}
      {isModalOpen && (
        <ModalPreview
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          row={currentRow}
        />
      )}

      {/* Not using now */}

      {/* {isChat && (
          <ModalChat
            isModalOpen={isChat}
            setIsModalOpen={setIsChat}
            row={currentRow}
            showFile={showFile}
            refreshTable={() => onUpdateTable()}
          />
        )} */}

      {/* TABLE */}

      {isMobile ? (
        <div className="SlowShow policy-table-mobile">
          <div className="policy-table-mobile__header">
            <div
              className="policy-table-mobile__back"
              style={sortedData.length < 2 ? { opacity: "0.5" } : {}}
            >
              <div
                className="button_red_mobile back margin-0"
                onClick={decreasePageNumber}
              >
                <img src={ArrowLeft} alt="" />
                {t("back")}
              </div>
            </div>
            <div className="policy-table-mobile__img">
              <img
                src={PandaMain}
                alt="Panda_Main_Logo"
                className="main-logo-policy__mobile"
              />
            </div>
            <div
              className="policy-table-mobile__next"
              style={sortedData.length < 2 ? { opacity: "0.5" } : {}}
            >
              <div
                className="button_red_mobile back margin-0"
                onClick={increasePageNumber}
              >
                {t("next")}
                <img src={ArrowRight} alt="" />
              </div>
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("Date")}</div>
            <div className="policy-table-mobile__right">
              {formatAnyDate(
                getDate(
                  sortedData[currentClaim - 1]?.additionalProperties
                    ?.additionalProperties?.dateOfInvoice1 ||
                    sortedData[currentClaim - 1]?.additionalProperties
                      ?.dateOfInvoice1 ||
                    sortedData[currentClaim - 1]?.incidentDate
                )
              ) || t("noInfo")}
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("claim")}</div>
            <div className="policy-table-mobile__right">
              {sortedData[currentClaim - 1]?.claimId || t("noInfo")}
            </div>
          </div>
          {mode === USER_MODE.ADMIN && (
            <div className="policy-table-mobile__row">
              <div className="policy-table-mobile__left">{t("petName")}</div>
              <div className="policy-table-mobile__right">
                {sortedData[currentClaim - 1]?.additionalProperties?.pet
                  ?.name || t("noInfo")}
              </div>
            </div>
          )}
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("diagnosis")}</div>
            <div className="policy-table-mobile__right">
              {sortedData[currentClaim - 1]?.additionalProperties
                .additionalProperties?.diagnose ||
                sortedData[currentClaim - 1]?.additionalProperties?.diagnose ||
                t("noInfo")}
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("veterinarian")}</div>
            <div className="policy-table-mobile__right">
              {sortedData[currentClaim - 1]?.additionalProperties
                .additionalProperties?.veterinarian ||
                sortedData[currentClaim - 1]?.additionalProperties
                  ?.veterinarian ||
                t("noInfo")}
            </div>
          </div>
          <div className="policy-table-mobile__row">
            <div className="policy-table-mobile__left">{t("Status")}</div>
            <div className="policy-table-mobile__right">
              {sortedData[currentClaim - 1]?.state.toUpperCase() || t("noInfo")}
            </div>
          </div>
          <div
            className="policy-table-mobile__row"
            onClick={(e) =>
              handleSetRow(sortedData[currentClaim - 1]?.id, currentClaim - 1)
            }
          >
            <div className="policy-table-mobile__left">{t("Details")}</div>
            <div
              className="policy-table-mobile__right"
              style={{ padding: "6px 0" }}
            >
              <div className="d-flex justify-content-evenly align-items-center">
                {mode === USER_MODE.ADMIN && (
                  <>
                    {sortedData[currentClaim - 1]?.adjudication ? (
                      <Tooltip title={t("seeAdvice")} placement="top">
                        <div
                          className="back_icon_red mobile"
                          onClick={() => {
                            setAdvice(
                              sortedData[currentClaim - 1]?.adjudication
                            );
                            setIsModalAdviceOpen(true);
                          }}
                        >
                          <img src={Lightning} />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("seeAdvice")} placement="top">
                        <div
                          className="back_icon_red mobile"
                          onClick={(e) => e.stopPropagation()}
                          style={{ opacity: "50%" }}
                        >
                          <img src={Lightning} />
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}
                {sortedData[currentClaim - 1]?.attachments &&
                sortedData[currentClaim - 1]?.attachments[0]?.hasOwnProperty(
                  "attachmentId"
                ) ? (
                  <Tooltip title={t("seeInitDoc")} placement="top">
                    <div
                      className="back_icon_red mobile"
                      onClick={() => {
                        setCurrentRow(sortedData[currentClaim - 1]);
                        setIsModalOpen(true);
                      }}
                    >
                      <img src={ShowFileIcon} />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title={t("seeInitDoc")} placement="top">
                    <div
                      className="back_icon_red mobile"
                      onClick={(e) => e.stopPropagation()}
                      style={{ opacity: "50%" }}
                    >
                      <img src={ShowFileIcon} />
                    </div>
                  </Tooltip>
                )}
                {sortedData[
                  currentClaim - 1
                ]?.additionalProperties?.hasOwnProperty("table_details") ? (
                  <Tooltip title={t("seeLineItems")} placement="top">
                    <div
                      className="back_icon_red mobile"
                      onClick={(e) =>
                        openMore(sortedData[currentClaim - 1].id, e)
                      }
                    >
                      <img src={ShowDoc} />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip title={t("seeLineItems")} placement="top">
                    <div
                      className="back_icon_red mobile"
                      onClick={(e) => e.stopPropagation()}
                      style={{ opacity: "50%" }}
                    >
                      <img src={ShowDoc} />
                    </div>
                  </Tooltip>
                )}
                <button
                  className="btn-go-top btn-go-top__mobile"
                  style={
                    expandedRow === currentClaim - 1
                      ? {}
                      : { transform: "rotate(180deg)" }
                  }
                  onClick={
                    () => {
                      setExpandedRow(null);
                      setSelectedRow(null);
                    }
                    //instead of "false"
                  }
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3.33325L5 8.33325M10 3.33325L15 8.33325M10 3.33325V12.0833M10 16.6666V14.5833"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {expandedRow === currentClaim - 1 && (
            <div className="policy-table-mobile__row">
              <div className="policy-table-mobile__full-width">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ flex: "1 1 50%" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <h5 style={{ marginBottom: 0 }}>{t("petInfo")}</h5>
                    </Box>
                    <ul>
                      <li>
                        {t("petName")}:{" "}
                        {sortedData[currentClaim - 1]?.additionalProperties?.pet
                          ?.name || t("noInfo")}
                      </li>
                      <li>
                        {t("submissionDate")}:{" "}
                        {formatAnyDate(
                          getDate(sortedData[currentClaim - 1]?.incidentDate)
                        )}
                      </li>
                      <li>
                        {t("type")}:{" "}
                        {sortedData[currentClaim - 1]?.additionalProperties?.pet
                          ?.type ||
                          sortedData[currentClaim - 1]?.pet?.type ||
                          t("noInfo")}
                      </li>
                      <li>
                        {t("gender")}:{" "}
                        {sortedData[currentClaim - 1]?.additionalProperties?.pet
                          ?.gender || t("noInfo")}
                      </li>
                      <li>
                        {t("birthDate")}:{" "}
                        {sortedData[currentClaim - 1]?.additionalProperties?.pet
                          ?.birthDate || t("noInfo")}
                      </li>
                      <li>
                        {t("policyID")}:{" "}
                        {sortedData[currentClaim - 1]?.policyId || t("noInfo")}
                      </li>

                      {sortedData[currentClaim - 1]?.additionalProperties
                        ?.pet && (
                        <>
                          <li>
                            {t("firstBreed")}:{" "}
                            {sortedData[currentClaim - 1]?.additionalProperties
                              ?.pet?.firstBreed ||
                              sortedData[currentClaim - 1]?.additionalProperties
                                ?.pet?.firstDogBreed ||
                              sortedData[currentClaim - 1]?.additionalProperties
                                ?.pet?.firstCatBreed}
                          </li>
                          {sortedData[currentClaim - 1]?.additionalProperties
                            ?.pet?.mixedBreed === "true" && (
                            <li>
                              {t("secondBreed")}:{" "}
                              {sortedData[currentClaim - 1]
                                ?.additionalProperties?.pet?.secondBreed ||
                                sortedData[currentClaim - 1]
                                  ?.additionalProperties?.pet?.secondDogBreed ||
                                sortedData[currentClaim - 1]
                                  ?.additionalProperties?.pet?.secondCatBreed}
                            </li>
                          )}
                        </>
                      )}
                      <li>
                        {t("chipNumber")}:{" "}
                        {sortedData[currentClaim - 1]?.additionalProperties?.pet
                          ?.identificationNumber || t("noInfo")}
                      </li>
                    </ul>
                  </div>
                  <div style={{ flex: "1 1 50%" }}>
                    <h5>{t("AdditionalProperties")}</h5>
                    <ul>
                      <li>
                        {t("claimType")}:{" "}
                        {sortedData[currentClaim - 1]?.additionalProperties
                          ?.additionalProperties?.claim_type
                          ? t(
                              sortedData[currentClaim - 1]?.additionalProperties
                                ?.additionalProperties?.claim_type
                            )
                          : sortedData[currentClaim - 1]?.additionalProperties
                              ?.claim_type
                          ? t(
                              sortedData[currentClaim - 1]?.additionalProperties
                                ?.claim_type
                            )
                          : t("noInfo")}
                      </li>
                      {sortedData[currentClaim - 1]?.additionalProperties
                        ?.duplicate_to && (
                        <li>
                          {t("duplicateTo") +
                            sortedData[currentClaim - 1]?.additionalProperties
                              ?.duplicate_to}
                        </li>
                      )}
                      {sortedData[
                        currentClaim - 1
                      ]?.additionalProperties?.manual?.map(
                        (item, i) =>
                          item.value && (
                            <li key={i}>{item?.name + ": " + item?.value}</li>
                          )
                      )}
                    </ul>
                  </div>
                  <div style={{ flex: "1 1 50%" }}>
                    {mode === USER_MODE.ADMIN && (
                      <div style={{ flex: "1 1 50%" }}>
                        <h5>{t("claimChecks")}</h5>
                        <ul>
                          <li>
                            {t("wasChipOnInvoice")}{" "}
                            {sortedData[currentClaim - 1].additionalProperties
                              ?.ocrChipNumberWasFound === "true"
                              ? t("yes")
                              : t("no")}
                          </li>
                          <li>
                            {t("wasDiagOnInvoice")}{" "}
                            {sortedData[currentClaim - 1].additionalProperties
                              ?.ocrDiagnosisFound === "true"
                              ? t("yes")
                              : t("no")}
                          </li>
                          <li>
                            {t("isItPolicyDR")}{" "}
                            {sortedData[currentClaim - 1]?.isInRange
                              ? t("yes")
                              : t("no")}
                          </li>
                          <li>
                            {t("isItDupl")}{" "}
                            {sortedData[currentClaim - 1].additionalProperties
                              ?.duplicate_to
                              ? t("yes")
                              : t("no")}
                          </li>
                          <li>
                            {t("containPrevent")}{" "}
                            {sortedData[currentClaim - 1]?.preventions?.length >
                            0
                              ? t("yes")
                              : t("no")}
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {sortedData.length > 1 && (
            <div className="policy-table-mobile__pagination">
              <Pagination
                className="reset-pagination"
                count={sortedData.length}
                page={currentClaim}
                siblingCount={1}
                boundaryCount={1}
                onChange={handlePage}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowLeftRed, next: ArrowRightRed }}
                    {...item}
                    // sx={{
                    //   color: '#FFF',
                    //   backgroundColor: item.type === 'page' && item.page === currentClaim ? '#FE4B4E' : '#DF9F9B',
                    // }}
                  />
                )}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          <TableContainer
            className="reset-table-spacing table-reset-header"
            sx={{ margin: "0 0 -3px 0" }}
            ref={headerRef}
          >
            <Table
              className={`SlowShow reset-table-new ${
                mode === USER_MODE.ADMIN ? "policy-table-admin" : "policy-table"
              }`}
            >
              <TableHead>
                <TableRow className="reset-table-new-header">
                  {(mode === USER_MODE.ADMIN
                    ? claimsTableHeadersAdmin
                    : claimsTableHeaders
                  ).map((header, index) => (
                    <TableCell
                      key={`header-${index}`}
                      align={index > 0 ? "inherit" : "left"}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <span>{t(header.title)}</span>
                          {header.sort && mode === USER_MODE.ADMIN && (
                            <img
                              alt=""
                              style={{ cursor: "pointer" }}
                              src={SortAsc}
                              onClick={() => handleSort(header.title)}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          <TableContainer
            className="reset-table-spacing"
            sx={{ maxHeight: 314 }}
            ref={bodyRef}
            onScroll={syncScroll}
          >
            <Table
              className={`SlowShow reset-table-new ${
                mode === USER_MODE.ADMIN ? "policy-table-admin" : "policy-table"
              }`}
            >
              <TableBody ref={listOfItemsRef}>
                {sortedData?.map((row, index) => (
                  <>
                    <TableRow
                      key={`row-${index}`}
                      className="reset-table-new-body"
                      onClick={(e) => handleSetRow(row.id, index)}
                    >
                      <TableCell key={0}>
                        <div>
                          <span>
                            {formatAnyDate(
                              getDate(
                                row?.additionalProperties?.additionalProperties
                                  ?.dateOfInvoice1 ||
                                  row?.additionalProperties?.dateOfInvoice1 ||
                                  row?.incidentDate
                              )
                            )}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell key={1}>
                        <div>
                          <span>{row?.claimId || t("noInfo")}</span>
                        </div>
                      </TableCell>
                      <TableCell key={2}>
                        <div>
                          <span>
                            {row?.additionalProperties?.additionalProperties
                              ?.diagnose ||
                              row?.additionalProperties?.diagnose ||
                              t("noInfo")}
                          </span>
                        </div>
                      </TableCell>
                      {mode === USER_MODE.ADMIN && (
                        <TableCell key={3}>
                          <div>
                            <span>
                              {row?.pet?.name ||
                                row.attributes?.name ||
                                row.additionalProperties?.additionalProperties
                                  ?.dogName ||
                                row.additionalProperties?.dogName ||
                                row.additionalProperties?.pet?.name ||
                                row.additionalProperties?.additionalProperties
                                  ?.selectedDog?.name ||
                                row.additionalProperties?.selectedDog?.name ||
                                t("noInfo")}
                            </span>
                          </div>
                        </TableCell>
                      )}
                      <TableCell key={4}>
                        <div>
                          <span>
                            {row?.additionalProperties?.additionalProperties
                              ?.veterinarian ||
                              row?.additionalProperties?.veterinarian ||
                              t("noInfo")}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell key={5}>
                        <div>
                          <span>{row?.state.toUpperCase()}</span>
                        </div>
                      </TableCell>
                      <TableCell key={6} sx={{ padding: "0 5px" }}>
                        <div className="d-flex flex-row justify-content-center">
                          {/*TODO: chat bubble might need later, do not delete*/}
                          {/*<Tooltip title="Chat" placement="top">*/}
                          {/*  <div className="back_icon_red" style={{ marginRight: "10px" }}>*/}
                          {/*    {row.notifications > 0 && (*/}
                          {/*      <span className="chat-btn__notif">*/}
                          {/*        {row.notifications}*/}
                          {/*      </span>*/}
                          {/*    )}*/}
                          {/*    <img*/}
                          {/*      src={ChatIcon}*/}
                          {/*      style={{ color: "#FFFAF0", top: "5px", position: "relative" }}*/}
                          {/*      onClick={(e) => onChat(e, row)}*/}
                          {/*    />*/}
                          {/*  </div>*/}
                          {/*</Tooltip>*/}
                          {mode === USER_MODE.ADMIN && (
                            <>
                              {row?.adjudication ? (
                                <Tooltip title={t("seeAdvice")} placement="top">
                                  <div
                                    className="back_icon_red"
                                    style={{ marginRight: "10px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setAdvice(row?.adjudication);
                                      setIsModalAdviceOpen(true);
                                    }}
                                  >
                                    <img
                                      src={Lightning}
                                      style={{
                                        color: "#FFFAF0",
                                        top: "3px",
                                        position: "relative",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip title={t("seeAdvice")} placement="top">
                                  <div
                                    className="back_icon_red"
                                    style={{
                                      marginRight: "10px",
                                      opacity: "50%",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <img
                                      src={Lightning}
                                      style={{
                                        color: "#FFFAF0",
                                        top: "3px",
                                        position: "relative",
                                        opacity: "50%",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {row?.attachments &&
                          row?.attachments[0]?.hasOwnProperty(
                            "attachmentId"
                          ) ? (
                            <Tooltip title={t("seeInitDoc")} placement="top">
                              <div
                                className="back_icon_red"
                                style={{ marginRight: "10px" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentRow(row);
                                  setIsModalOpen(true);
                                }}
                              >
                                <img
                                  src={ShowFileIcon}
                                  style={{
                                    color: "#FFFAF0",
                                    top: "5px",
                                    position: "relative",
                                  }}
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip title={t("seeInitDoc")} placement="top">
                              <div
                                className="back_icon_red"
                                style={{ marginRight: "10px", opacity: "50%" }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <img
                                  src={ShowFileIcon}
                                  style={{
                                    color: "#FFFAF0",
                                    top: "5px",
                                    position: "relative",
                                    opacity: "50%",
                                  }}
                                />
                              </div>
                            </Tooltip>
                          )}
                          {row?.additionalProperties?.hasOwnProperty(
                            "table_details"
                          ) ? (
                            <Tooltip title={t("seeLineItems")} placement="top">
                              <div
                                className="back_icon_red"
                                style={{ marginRight: "0", opacity: "100%" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openMore(row.id, e);
                                }}
                              >
                                <img
                                  src={ShowDoc}
                                  style={{
                                    color: "#FFFAF0",
                                    top: "5px",
                                    position: "relative",
                                    opacity: "100%",
                                  }}
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <Tooltip title={t("seeLineItems")} placement="top">
                              <div
                                className="back_icon_red"
                                style={{ marginRight: "0", opacity: "50%" }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <img
                                  src={ShowDoc}
                                  style={{
                                    color: "#FFFAF0",
                                    top: "5px",
                                    position: "relative",
                                    opacity: "50%",
                                  }}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                    {expandedRow === index && (
                      <TableRow className="reset-table-new-info relative">
                        <TableCell colSpan={9}>
                          <button
                            className="btn-go-top"
                            onClick={
                              () => {
                                setExpandedRow(null);
                                setSelectedRow(null);
                              }
                              //instead of "false"
                            }
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 3.33325L5 8.33325M10 3.33325L15 8.33325M10 3.33325V12.0833M10 16.6666V14.5833"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div style={{ flex: "1 1 50%" }}>
                              <h5>{t("petInfo")}</h5>
                              <ul>
                                <li>
                                  {t("petName")}:{" "}
                                  {row?.additionalProperties?.pet?.name ||
                                    t("noInfo")}
                                </li>
                                <li>
                                  {t("submissionDate")}:{" "}
                                  {formatAnyDate(getDate(row?.incidentDate))}
                                </li>
                                <li>
                                  {t("type")}:{" "}
                                  {row?.additionalProperties?.pet?.type ||
                                    row?.pet?.type ||
                                    t("noInfo")}
                                </li>
                                <li>
                                  {t("gender")}:{" "}
                                  {row?.additionalProperties?.pet?.gender ||
                                    t("noInfo")}
                                </li>
                                <li>
                                  {t("birthDate")}:{" "}
                                  {row?.additionalProperties?.pet?.birthDate ||
                                    t("noInfo")}
                                </li>
                                <li>
                                  {t("policyID")}:{" "}
                                  {row?.policyId || t("noInfo")}
                                </li>
                                {row?.additionalProperties?.pet && (
                                  <>
                                    <li>
                                      {t("firstBreed")}:{" "}
                                      {breedParse(
                                        row?.additionalProperties?.pet
                                          ?.firstBreed ||
                                          row?.additionalProperties?.pet
                                            ?.firstDogBreed ||
                                          row?.additionalProperties?.pet
                                            ?.firstCatBreed
                                      )}
                                    </li>
                                    {row?.additionalProperties?.pet
                                      ?.mixedBreed === "true" && (
                                      <li>
                                        {t("secondBreed")}:{" "}
                                        {breedParse(
                                          row?.additionalProperties?.pet
                                            ?.secondBreed ||
                                            row?.additionalProperties?.pet
                                              ?.secondDogBreed ||
                                            row?.additionalProperties?.pet
                                              ?.secondCatBreed
                                        )}
                                      </li>
                                    )}
                                  </>
                                )}
                                <li>
                                  {t("chipNumber")}:{" "}
                                  {row?.additionalProperties?.pet
                                    ?.identificationNumber || t("noInfo")}
                                </li>
                              </ul>
                            </div>
                            <div style={{ flex: "1 1 50%" }}>
                              <h5>{t("AdditionalProperties")}</h5>
                              <ul>
                                <li>
                                  {t("claimType")}:{" "}
                                  {row?.additionalProperties
                                    ?.additionalProperties?.claim_type
                                    ? t(
                                        row?.additionalProperties
                                          ?.additionalProperties?.claim_type
                                      )
                                    : row?.additionalProperties?.claim_type
                                    ? t(row?.additionalProperties?.claim_type)
                                    : t("noInfo")}
                                </li>
                                {row?.additionalProperties?.duplicate_to && (
                                  <li>
                                    {t("duplicateTo") +
                                      row?.additionalProperties?.duplicate_to}
                                  </li>
                                )}
                                {row?.additionalProperties?.manual?.map(
                                  (item, i) =>
                                    item.value && (
                                      <li key={i}>
                                        {item?.name + ": " + item?.value}
                                      </li>
                                    )
                                )}
                              </ul>
                            </div>
                            {mode === USER_MODE.ADMIN && (
                              <div style={{ flex: "1 1 50%" }}>
                                <h5>{t("claimChecks")}</h5>
                                <ul>
                                  <li>
                                    {t("wasChipOnInvoice")}{" "}
                                    {row.additionalProperties
                                      ?.ocrChipNumberWasFound === "true"
                                      ? t("yes")
                                      : t("no")}
                                  </li>
                                  <li>
                                    {t("wasDiagOnInvoice")}{" "}
                                    {row.additionalProperties
                                      ?.ocrDiagnosisFound === "true"
                                      ? t("yes")
                                      : t("no")}
                                  </li>
                                  <li>
                                    {t("isItPolicyDR")}{" "}
                                    {row?.isInRange ? t("yes") : t("no")}
                                  </li>
                                  <li>
                                    {t("isItDupl")}{" "}
                                    {row.additionalProperties?.duplicate_to
                                      ? t("yes")
                                      : t("no")}
                                  </li>
                                  <li>
                                    {t("containPrevent")}{" "}
                                    {row?.preventions?.length > 0
                                      ? t("yes")
                                      : t("no")}
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
}

export default PolicyTable;
