import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function useTranslations() {
  const { i18n } = useTranslation();
  const { translations } = useSelector((s) => s.flow.translations);
  // translations should be { en: {key: val}, gr: {key: val} }
  const getTranslatedText = useCallback(
    (key, customTranslations) => {
      const t = customTranslations ? customTranslations : translations;
      const currLangData = t?.[i18n.language];
      return currLangData && currLangData[key] ? currLangData[key] : key;
    },
    [i18n.language, translations]
  );
  return { getTranslatedText };
}
