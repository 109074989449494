import { Request } from "../config/requests";
import { getToken } from "../config/token";

const token = getToken();

export async function attachToExistingClaim(ids, note, images) {
  const formattedIds = ids;
  const fd = new FormData();
  images.forEach((i) => fd.append("files", i));

  try {
    return Request({
      method: "post",
      token: token,
      route: `api/claims/attachments?claims=${formattedIds}&note=${note}`,
      formData: fd,
    });
  } catch (e) {
    console.error(e);
  }
}

export async function createDuplicateClaim(duplicatesList, claimsId) {
  const formattedIds = duplicatesList.split(",");

  try {
    return Request({
      method: "post",
      token: token,
      route: `api/claims/duplicate_claim`,
      values: {
        policy_id: claimsId,
        claim_id: formattedIds[0] || formattedIds,
      },
    });
  } catch (e) {
    console.error(e);
  }
}

export async function checkLastClaim(policyId) {
  try {
    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     return resolve(false)
    //   }, 2000)
    // })
    return Request({
      method: "get",
      token: token,
      route: `api/claims/verify_last_claim/${policyId}`,
    }).then((response) => response.json());
  } catch (e) {
    console.error(e);
  }
}
