import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import { getToken, setToken } from "../config/token";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { updateIsAdminViewMode, updateUser } from "../config/actions";
import { updateMode } from "../config/actions";
import { USER_MODE } from "../constants/ApplicationConstants";
import { Buffer } from "buffer";
import { Markup } from "interweave";
import PandaLogoMain from "../assets/UserInfo/panda-logo-main.svg";
import Dog from "../assets/login/dog.svg";
import Cat from "../assets/login/cat.svg";
import ModalSupport from "./claims/modals/ModalSupport";
import { isMobile } from "react-device-detect";
import Loading from "../components/common/Loading";
import { getSettedTheme, showToast } from "../config/helpers";
import { projectSettings } from "../config/setting";
import { useProjectTheme } from "../hooks/useProjectTheme";

export default function Login() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const router = useNavigate();
  const dispatch = useDispatch();
  const [adminMode, setAdminMode] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [policyEmail, setPolicyEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [veificationCode, setVeificationCode] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidAdminEmail, setIsValidAdminEmail] = useState(false);
  const [problemEmail, setProblemEmail] = useState(false);
  const [openModalSupport, setOpenModalSupport] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const { token } = getToken() ? JSON.parse(getToken()) : {};

  const theme = useProjectTheme();
  const areLoginBgImagesVisible =
    theme === projectSettings.aseetsPath && projectSettings.loginBgImages;

  const handleSubmit = () => {
    if (username && password && isValidAdminEmail) {
      fetchToken();
    }
  };

  const fetchToken = async () => {
    const userToken = await signIn({
      email: username,
      password: password,
    });
    if (userToken.error) {
      showToast(userToken.error);
    }
    if (userToken.token) {
      setToken(JSON.stringify({ token: userToken.token }));
      const user = JSON.parse(
        Buffer.from(userToken.token.split(".")[1], "base64").toString()
      );
      dispatch(updateUser(user));
      dispatch(updateMode(USER_MODE.ADMIN));
      dispatch(updateIsAdminViewMode(true));
      router("/management");
      // router("/");
    }
  };

  const signIn = async (credentials) => {
    return await fetch("/api/users/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((data) => data.json())
      .then((data) => data);
  };

  const handleUserEmail = (e) => {
    const inputValue = e.target.value.trim();
    setPolicyEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  };

  const handleAdminEmail = (e) => {
    const inputValue = e.target.value.trim();
    setUsername(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidAdminEmail(isValid);
  };

  const EmailConfirm = async () => {
    if (isValidEmail) {
      setCodeLoading(true);
      fetch("api/users/email_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: policyEmail }),
      })
        .then((response) => {
          if (response.status === 200) {
            setProblemEmail(false);
            setEmailSent(true);
          } else {
            setProblemEmail(true);
          }
          setCodeLoading(false);
          return response;
        })
        .then((response) => response.json())
        .then((res) => setVeificationCode(res.message));
    }
  };

  const codeConfirm = async () => {
    setCodeLoading(true);
    return await fetch("/api/users/verification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: veificationCode }),
    })
      .then((response) => response.json())
      .then((data) => {
        setToken(JSON.stringify({ token: data.token }));
        return data;
      })
      .then((data) => {
        getUserInfo(data.token);
        // getUserPolicy(data.token);
        // getPetsInfo(data.token);
        dispatch(updateMode(USER_MODE.CLIENT));
      })
      .then(() => {
        router("/");
      })
      .finally(() => {
        setCodeLoading(false);
      });
  };

  const getUserInfo = async (curToken) => {
    return await fetch("/api/users/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${curToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("LOGIN >>> userInfo", json.data);
        dispatch(updateUser(json.data));
      });
  };

  const getUserPolicy = async (curToken) => {
    return await fetch("api/users/policies", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${curToken}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("LOGIN >>> userPolicy", json.data);
        // dispatch(updateUser(json.data));
      });
  };

  useEffect(() => {
    if (token) {
      router("/");
    }
  }, [token]);

  return (
    <div className="main-container">
      <Header />
      <div className="login__container">
        <div className="login__header">
          <img
            src={PandaLogoMain}
            className={
              currentLanguage == "de"
                ? "main-logo-login de"
                : "main-logo-login en"
            }
          />
          <span className="headline1_red_title">{t("loginWelcome")}</span>
        </div>
        <div className="login__main_back">
          {areLoginBgImagesVisible && (
            <img src={Dog} className="login__main_back__dog" />
          )}
          {areLoginBgImagesVisible && (
            <img src={Cat} className="login__main_back__cat" />
          )}
          {adminMode && (
            <>
              <div className="login__body">
                <div className="login__body__title">
                  <span className="hl2_white_big text-centered">
                    {t("register")}
                  </span>
                </div>
                <input
                  name="email"
                  type="email"
                  value={username}
                  onChange={handleAdminEmail}
                  data-cy="login"
                  required
                  placeholder={t("yourEmail")}
                  className="login__body__input text-centered"
                  style={{ margin: "1vh" }}
                />
                <input
                  name="password"
                  type="password"
                  value={password}
                  required
                  onChange={({ target }) => setPassword(target.value)}
                  data-cy="password"
                  placeholder={t("yourPassword")}
                  className="login__body__input text-centered"
                  style={{ margin: "1vh" }}
                />
                <div
                  className={`login__body__button${
                    isMobile ? "_mobile" : ""
                  }  button_red_common ${
                    !isValidAdminEmail || password.length < 1 ? "disabled" : ""
                  }`}
                  style={{
                    minWidth: "170px",
                    height: "63px",
                  }}
                  onClick={() => handleSubmit()}
                >
                  {t("toSend")}
                </div>
              </div>
            </>
          )}
          {!adminMode && (
            <>
              {!emailSent && (
                <div className="login__body">
                  <div className="login__body__title">
                    <span className="hl2_white_big text-centered">
                      {t("register")}
                    </span>
                  </div>
                  <input
                    name="policyEmail"
                    type="text"
                    value={policyEmail}
                    required
                    onChange={handleUserEmail}
                    data-cy="policyEmail"
                    placeholder={t("yourEmail")}
                    className="login__body__input text-centered"
                  />
                  {problemEmail && (
                    <div className="login__body__alert">
                      <Markup
                        content={t("errorEmail")}
                        className="white_slim_rooney small text-centered"
                      />
                      <span
                        className="white_slim_rooney small text-centered"
                        style={{
                          cursor: "pointer",
                          textDecorationLine: "underline",
                        }}
                        onClick={() => setOpenModalSupport(true)}
                      >
                        {t("support")}
                      </span>
                    </div>
                  )}
                  {codeLoading ? (
                    <div className="mt-3">
                      <Loading noText fast />
                    </div>
                  ) : (
                    <div
                      className={`login__body__button${
                        isMobile ? "_mobile" : ""
                      } button_red_common ${!isValidEmail ? "disabled" : ""}`}
                      style={{
                        minWidth: "170px",
                        height: "63px",
                      }}
                      onClick={() => {
                        EmailConfirm();
                      }}
                    >
                      {t("Next2")}
                    </div>
                  )}
                </div>
              )}
              {emailSent && (
                <div className="login__body">
                  <div className="login__body__title">
                    <span className="hl2_white_big text-centered">
                      {t("Login Code")}
                    </span>
                  </div>
                  <div className="login__body__description">
                    <span className="white_slim_rooney text-centered">
                      {t("codeSent")}
                    </span>
                  </div>
                  <input
                    name="code"
                    type="text"
                    value={veificationCode}
                    required
                    onChange={(e) => setVeificationCode(e.target.value.trim())}
                    data-cy="code"
                    placeholder={t("confirmCode")}
                    className="login__body__input text-centered"
                  />
                  {codeLoading ? (
                    <div className="mt-3">
                      <Loading noText fast />
                    </div>
                  ) : (
                    <div
                      className={`login__body__button${
                        isMobile ? "_mobile" : ""
                      } button_red_common ${!emailSent ? "disabled" : ""}`}
                      style={{
                        minWidth: "170px",
                        height: "63px",
                      }}
                      onClick={() => {
                        codeConfirm();
                      }}
                    >
                      {t("Login")}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Footer mode={adminMode} modeHandler={() => setAdminMode(!adminMode)} />
      {openModalSupport && (
        <ModalSupport
          openModalSupport={openModalSupport}
          setOpenModalSupport={setOpenModalSupport}
        />
      )}
    </div>
  );
}
