import * as React from "react";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isMobile, isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";
import CatDog from "../../../assets/Insurances/cat_dog.svg";
import { useNavigate, useParams } from "react-router-dom";
import { updateSelectedPetInfo } from "../../../config/actions";
import Loading from "../../../components/common/Loading";

function ModalPetSelect({
  openModalPetSelect,
  setOpenModalPetSelect,
  // reportType,
  setOpenModalWhatToDo,
  setPetId,
}) {
  const { t } = useTranslation();
  const router = useNavigate();
  const pets = useSelector((s) => s.flow.pets);
  const [selectedPet, setSelectedPet] = useState(null);

  function handleClose() {
    setSelectedPet(null);
    setOpenModalPetSelect(false);
  };

  const RenderPets = () => {
    const rows = [];
    const numPets = pets.length;
    const dispatch = useDispatch();

    for (let i = 0; i < numPets; i += 3) {
      const rowPets = pets.slice(i, i + 3);
      const row = (
        <div key={`row-${i}`} className="pet_container__row-modal">
          {rowPets.map((pet) => (
            <div
              className={`pet_container__item ${pet.policyId !== selectedPet ? "not-selected" : ""}`}
              onClick={() => {
                setSelectedPet(pet?.policyId);
                setPetId(pet?.policyId);
                dispatch(updateSelectedPetInfo({ ...pet.attributes, type: pet.type, policyId: pet.policyId }));
              }}
            >
              <div className="pet_container__wrapper-modal">
                <div>
                  {pet?.image ? (
                    <img
                      src={`data:image/jpeg;base64, ${pet?.image}`}
                      alt="CatDog"
                      height="100%"
                    />
                  ) : (
                    <img src={CatDog} alt="CatDog" width="95%" height="95%" />
                  )}
                </div>
              </div>
              <div className="pet_container__name">
                {pet?.attributes?.name}
                {pets.filter(el => el.attributes.name === pet.attributes.name).length > 1 &&
                  <div>{pet?.policyId}</div>}
              </div>
            </div>
          ))}
        </div>
      );
      rows.push(row);
    }
    return (pets.length > 0 ? rows : <Loading noText fast />);
  };

  return (
    <ModalWrapper
      headerTitle={t("submitInvoice")}
      openModal={openModalPetSelect}
      setModalWrapper={() => handleClose()}
    >
      <>
        <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
          {t("whichPetContinue")}
        </div>
        <div className="modal_container__body__inputs">
          {RenderPets()}
        </div>
        <div
          className={
            (selectedPet !== null)
              ? `button_red_small ${isMobileOnly && "mobile"}`
              : `button_red_smal_disabled ${isMobileOnly && "mobile"}`
          }
          onClick={(selectedPet !== null)
            // ? () => router(`/contact/${selectedPet}/claim?type=${encodeURIComponent(reportType)}`)
            ? () => {
              handleClose();
              setOpenModalWhatToDo(true);
            }
            : ((e) => e.stopPropagation())}
        >
          {t("Next2")}
        </div>
      </>
    </ModalWrapper>
  );
}

export default ModalPetSelect;
