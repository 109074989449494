import { useEffect, useRef } from "react";

const useQuestionContainer = (depsToRecalculate = null) => {
  const questionContainer = useRef(null);
  const buttonContainer = useRef(null);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   window.addEventListener("scroll", onScroll);

  //   return () => {
  //     window.removeEventListener("scroll", onScroll);
  //   };
  // }, []);

  useEffect(() => {
    if (
      questionContainer &&
      buttonContainer &&
      buttonContainer.current &&
      buttonContainer.current.style
    ) {
      questionContainer.current.style.marginTop = "71px";
      buttonContainer.current.style.width =
        questionContainer?.current?.clientWidth + "px";
    }
  }, [questionContainer, buttonContainer.current, depsToRecalculate]);

  // const onScroll = () => {
  //   if (window.pageYOffset >= 130) {
  //     if (
  //       buttonContainer &&
  //       buttonContainer.current &&
  //       buttonContainer.current.style
  //     ) {
  //       buttonContainer.current?.classList.add("NavButtonContainer---sticky");
  //       buttonContainer.current.style.width =
  //         questionContainer?.current?.clientWidth + "px";
  //       // questionContainer.current.style.marginTop = "71px";
  //     }
  //   } else {
  //     if (buttonContainer)
  //       buttonContainer?.current?.classList.remove(
  //         "NavButtonContainer---sticky"
  //       );
  //     if (
  //       questionContainer &&
  //       questionContainer.current &&
  //       questionContainer.current.style
  //     )
  //       questionContainer.current.style.marginTop = "0";
  //   }
  // };

  return [questionContainer, buttonContainer];
};

export default useQuestionContainer;

