export const MISSING_OCR_TYPES = {
  "diagnosis": "diagnosis",
  "chipnumber": "chipnumber",
  "duplicates": "duplicates",
}

export const FLOW_TYPES = {
  'Existing Claim': 'Existing Claim',
  'Cost estimation': 'Cost estimation',
}

export const LAST_CLAIM_ERROR_MSG = "Last claim check error"