import * as React from "react";
import { useState, useEffect } from "react";
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Datepicker from "../../../components/common/components/Datepicker";
import moment from "moment";
import { isMobile, isMobileOnly } from "react-device-detect";
import { showToast } from "../../../config/helpers";
import ModalWrapper from "../../../components/modalWrapper";
import CustomSelect from "../../../components/inputs/select/CustomSelect";

function ModalFormPhone({
  openModalFormPhone,
  setOpenModalFormPhone,
}) {
  const userInfo = useSelector((s) => s.form.user);
  const { t } = useTranslation();
  const [name, setName] = useState(userInfo?.firstName + " " + userInfo?.lastName);
  const [phone, setPhone] = useState(userInfo?.phone);
  const [email, setEmail] = useState(userInfo?.email);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlot, setTimeSlot] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [confirmed, setConfirmed] = useState(false);

  const handleEmail = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  };

  // const changeUserCode = (data) => {
  //   setUserCode(data);
  // }

  function handleClose() {
    setOpenModalFormPhone(false);
  };

  // Delete code below if decide not to detect user's phone code by IP

  // async function getUserCode(){
  //   try {
  //     const url = "https://node-test.panda-tierversicherung.de/api/crm/ip_info";
  //     const response = await fetch(url, {
  // 			method: "GET",
  // 			crossDomain: true,
  // 			headers: {
  // 				"Content-Type": "application/json",
  // 			},
  // 		});
  //     const json = await response.json();
  //     console.log("USER Code >>> ", json.phoneCode)
  //     let userCodeObj = {
  //       label: json.phoneCode,
  //       value: json.phoneCode,
  //     };

  //     setUserCode(userCodeObj);
  //   } catch (e) {
  //     console.log(e);
  //     return { error: true };
  //   }
  // };

  const getClientUTCOffset = () => {
    const clientUTCOffset = moment().utcOffset() / 60;
    return clientUTCOffset;
  }

  const createTimestamp = (date, time) => {
    const timeValue = time.value
    const dateparts = date.split('-');
    const timeparts = timeValue.split(':');
    let timestamp = Date.UTC(dateparts[0], dateparts[1] - 1, dateparts[2], timeparts[0], timeparts[1]);
    timestamp = new Date(timestamp);
    return timestamp.toISOString();
  };

  async function getSlots(date) {
    setSelectedDate(date);
    try {
      const url = `https://node-test.panda-tierversicherung.de/api/slots/available?date=${moment.utc(date).utcOffset(moment().utcOffset()).format('YYYY-MM-DD')}`;
      const response = await fetch(url);
      const json = await response.json();
      // console.log("RES >>>", json)

      // BE sends times for de-Germany (UTC + 1)
      // timestamp, so we should turn in to users local timestamp
      // f.e if user has another UTC time should show correct for him
      const clientsOffset = getClientUTCOffset();
      const offset = clientsOffset > 0 ? clientsOffset - 1 : 1;
      const validOptions = json?.data?.map(t => {
        const hours = t.split(':')[0]
        const minutes = t.split(':')[1]
        const localTime = `${parseInt(hours) + offset}:${minutes}`
        return { label: localTime, value: t };
      });
      setTimeSlots(validOptions || []);
    } catch (e) {
      console.log(e);
      console.log("something went wrong");
      return { error: true };
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const uniqueTimestamp = createTimestamp(moment.utc(selectedDate).utcOffset(moment().utcOffset()).format('YYYY-MM-DD'), timeSlot)
    const formData = {
      pageUrl: window.location.href,
      pageName: 'Insurance registration',
      hubspotutk: '3a8b070ff92f5d99d52461effa42d313',
      name,
      email,
      phone,
      date: uniqueTimestamp
    };
    // console.log("formData >>>", formData)

    try {
      const response = await fetch("https://node-test.panda-tierversicherung.de/requests/callbacks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "authorization": "dfuEUUFHgf",
        },
        body: JSON.stringify(formData),
      });

      const json = await response.json();
      if (response.status == "200") {
        // setConfirmed(true);
        handleClose();
        showToast(t("callbackConfirmed"));
      }
      return json;
    } catch (e) {
      console.log(e);
      console.log("something went wrong");
      return { error: true };
    }
  };


  return (
    <ModalWrapper
      headerTitle={t("arrangeCall")}
      openModal={openModalFormPhone}
      setModalWrapper={() => setOpenModalFormPhone(false)}
    >
      <div
        className="modal_container__body__text"
        style={{ margin: "0 0 15px 0" }}
      >
        {t("willDiscuss")}
      </div>
      <div className="modal_container__body__text">
        <Markup content={t("sendChoose")} />
      </div>
      <div className="modal_container__body__inputs">
        <input
          className="modal_container__body__input"
          placeholder={t("yourName") + "*"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="modal_container__body__row">
          <input
            className="modal_container__body__input"
            placeholder={t("yourPhone") + "*"}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <input
          className="modal_container__body__input"
          style={isValidEmail ? {} : { border: "3px solid red" }}
          placeholder={t("email") + "*"}
          value={email}
          onChange={handleEmail}
        />
        <div className="modal_container__body__row">
          <Datepicker
            date={selectedDate}
            setDate={(date) => getSlots(date)}
            disabled={false}
            type={"reset-dp-modal"}
            disabledPast
          />
          <CustomSelect
            variant="modal-form-phone"
            options={timeSlots}
            formatCreateLabel={(i) => i}
            placeholder={t("time")}
            value={timeSlot}
            onChange={(e) => setTimeSlot(e)}
            isClearable={false}
          />
        </div>
      </div>
      <div style={{ margin: "0 0 20px 0" }}>{t("replyAsap")}</div>
      <div
        className={
          (name && email && selectedDate && timeSlot)
            ? `button_red_small ${isMobile && "mobile"}`
            : `button_red_smal_disabled ${isMobile && "mobile"}`
        }
        onClick={
          (name && email && selectedDate && timeSlot)
            ? handleSubmit
            : ((e) => e.stopPropagation())
        }
      >
        {t("send")}
      </div>
    </ModalWrapper>
  );
}

export default ModalFormPhone;
