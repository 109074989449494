import { Buffer } from "buffer";

export const getToken = () => {
  return localStorage.getItem(process.env.REACT_APP_TOKEN);
};

export const setToken = (token) => {
  localStorage.setItem(process.env.REACT_APP_TOKEN, token);
};

export const setAdminTempToken = (token) => {
  localStorage.setItem(process.env.REACT_APP_ADMIN_TEMP_TOKEN, token);
};

export const getAdminTempToken = () => {
  return localStorage.getItem(process.env.REACT_APP_ADMIN_TEMP_TOKEN);
};

export const getUser = () => {
  const token = getToken();

  if (token) {
    return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
  }

  return null;
};
