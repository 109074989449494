import React, { useEffect, useState } from "react";
import Loading from "../../components/common/Loading";
import Title from "../../components/common/components/Title";
import AuditDuplicated from "./auditDuplicated/AuditDuplicated";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getToken } from "../../config/token";
import { Request } from "../../config/requests";
import ReactApexChart from "react-apexcharts";

function AuditClaims({ themeSetting }) {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isStatisticLoading, setIsStatisticLoading] = useState(false);
  // const [statistics, setStatistics] = useState({"duplicates": []});
  const [duplicatesStatistics, setDuplicatesStatistics] = useState([]);
  const [preventionsStatistics, setPreventionsStatistics] = useState([]);
  const [OCRStatistics, setOCRStatistics] = useState([]);
  const { t } = useTranslation();

  const auditGroups = [
    {
      group: t("auditDuplicated"),
      kpis: [
        {
          count: "1,123",
          name: t("claimsPendingReview"),
        },
        {
          count: "230",
          name: t("claimsReviewed"),
        },
      ],
      href: "/audit-duplicated",
    },
    {
      group: t("auditPrevention"),
      kpis: [
        {
          count: "1,123",
          name: t("claimsPendingReview"),
        },
        {
          count: "230",
          name: t("claimsReviewed"),
        },
      ],
      href: "/audit-prevention",
    },
    {
      group: t("auditOCR"),
      kpis: [
        {
          count: "1,123",
          name: t("claimsPendingReview"),
        },
        {
          count: "230",
          name: t("claimsReviewed"),
        },
      ],
      href: "/audit-ocr",
    },
  ];

  const body = getComputedStyle(document.body);
  const donutColor1 = body.getPropertyValue("--donut-1").trim();
  const donutColor2 = body.getPropertyValue("--donut-2").trim();
  const donutColor3 = body.getPropertyValue("--donut-3").trim();

  const chartData = {
    options: [
      {
        chart: {
          type: "pie",
        },
        labels: [t("identifiedSuccess"), t("identifiedWrong")],
        legend: {
          show: false,
        },
        colors: [donutColor1, donutColor2, donutColor3],
      },
      {
        chart: {
          type: "pie",
        },
        labels: [t("prevTrue"), t("prevFalse"), t("prevNotSure")],
        legend: {
          show: false,
        },
        colors: [donutColor1, donutColor2, donutColor3],
      },
    ],
  };

  function getStatisticsDupl() {
    setIsStatisticLoading(true);
    Request({
      method: "get",
      route: "api/claims/duplicates/statistics",
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (json) => {
        // console.log('AuditClaims > statistics > json.data:', json.data);
        // updateDuplicates([json.data.checked, json.data.left])
        setDuplicatesStatistics([
          json.data.checked,
          json.data.left,
          json.data.duplicates,
          json.data.total,
        ]);
      })
      .finally(() => {
        setIsStatisticLoading(false);
      });
  }

  function getStatisticsPrev() {
    setIsStatisticLoading(true);
    Request({
      method: "get",
      route: "api/claims/preventions/statistics",
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (json) => {
        // console.log('AuditClaims > statistics > json.data:', json.data);
        setPreventionsStatistics([
          json.data.is_prev,
          json.data.not_prev,
          json.data.not_sure + json.data.mixed_bill,
          json.data.total,
        ]);
      })
      .finally(() => {
        setIsStatisticLoading(false);
      });
  }

  function getStatisticsOCR() {
    setIsStatisticLoading(true);
    Request({
      method: "get",
      route: "api/claims/audit_ocr/statistics",
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (json) => {
        console.log("AuditClaims > statistics > json.data:", json.data);
        setOCRStatistics([json.data.checked, json.data.not_checked]);
      })
      .finally(() => {
        setIsStatisticLoading(false);
      });
  }

  function getStatisticsOCR() {
    setIsStatisticLoading(true);
    Request({
      method: "get",
      route: "api/claims/audit_ocr/statistics",
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (json) => {
        console.log("AuditClaims > statistics > json.data:", json.data);
        setOCRStatistics([json.data.checked, json.data.not_checked]);
      })
      .finally(() => {
        setIsStatisticLoading(false);
      });
  }

  // function updateDuplicates(newDuplicates) {
  //     setStatistics(prevStatistics => ({
  //       ...prevStatistics,
  //       duplicates: newDuplicates
  //     }));
  //   };

  useEffect(() => {
    getStatisticsDupl();
    getStatisticsPrev();
    getStatisticsOCR();
    console.log("calling Statistics...");
  }, []);

  return (
    <div className="FMContainer">
      {isLoading && <Loading noText fast />}

      {!isLoading && (
        <div className="AuditContent">
          {auditGroups.map((e) => (
            <div className="AuditGroup">
              <button
                className="ButtonSelection Enabled"
                onClick={() => history(e.href)}
              >
                {e.group}
              </button>
              <div style={{ padding: "0 10px" }}>
                {!isStatisticLoading && e.group == t("auditDuplicated") && (
                  <>
                    {duplicatesStatistics.length > 0 ? (
                      <div>
                        <div className="CountContainer">
                          {duplicatesStatistics[3] - duplicatesStatistics[0]}{" "}
                          {t("isOfPending")} {duplicatesStatistics[3]}
                          <h3>{t("rate")}</h3>
                        </div>
                        <ReactApexChart
                          options={chartData.options[0]}
                          series={[
                            duplicatesStatistics[2],
                            duplicatesStatistics[0] - duplicatesStatistics[2],
                          ]}
                          type="donut"
                          height={300}
                        />
                      </div>
                    ) : (
                      <Loading noText fast />
                    )}
                  </>
                )}
                {!isStatisticLoading && e.group == t("auditPrevention") && (
                  <>
                    {preventionsStatistics.length > 0 ? (
                      <div>
                        <div className="CountContainer">
                          {preventionsStatistics[3] -
                            (preventionsStatistics[0] +
                              preventionsStatistics[1] +
                              preventionsStatistics[2])}{" "}
                          {t("isOfPending")} {preventionsStatistics[3]}
                          <h3>{t("rate")}</h3>
                        </div>
                        <ReactApexChart
                          options={chartData.options[1]}
                          series={[
                            preventionsStatistics[0],
                            preventionsStatistics[1],
                            preventionsStatistics[2],
                          ]}
                          type="donut"
                          height={300}
                        />
                      </div>
                    ) : (
                      <Loading noText fast />
                    )}
                  </>
                )}
                {!isStatisticLoading && e.group == t("auditOCR") && (
                  <div>
                    <div className="CountContainer">
                      {OCRStatistics[1]} {t("isOfPending")}{" "}
                      {OCRStatistics[0] + OCRStatistics[1]}
                      {/* <h3>{t("rate")}</h3> */}
                    </div>
                    {/* <ReactApexChart
                                        options={chartData.options[1]}
                                        series={[preventionsStatistics[0], preventionsStatistics[1], preventionsStatistics[2]]}
                                        type="donut"
                                        height={300}
                                    /> */}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AuditClaims;
