import React from 'react';
import Modal from "@mui/material/Modal";
import Close from "../assets/modal/close-x-white.svg";
import PandaHeader from "../assets/modal/panda-header-logo.svg";
import PandaFooter from "../assets/modal/panda-footer-logo.svg";
import { useTranslation } from "react-i18next";

const ModalWrapper = ({
    headerTitle,
    openModal,
    setModalWrapper,
    children,
    noClose = false
}) => {
    function handleClose() {
        setModalWrapper(false);
    };
    const { t } = useTranslation()
    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            className="custom_blured_background FastShow"
            disableEscapeKeyDown
        >
            <div className="modal_container">
                <div className="modal_container__main">
                    <div className="modal_container__header">
                        {!noClose && <img className="modal_container__header__close" src={Close} onClick={handleClose} />}
                        <img className="modal_container__header__logo" src={PandaHeader} />
                        <div>{headerTitle}</div>
                    </div>
                    <div className="modal_container__body">
                        {children}
                    </div>

                    <img className="modal_container__footer" src={PandaFooter} />
                </div>
            </div>
        </Modal>
    );
};

export default ModalWrapper;