import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PreviewFile = ({ ocrDetails, groupsList, showOnePicture }) => {
	const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

	return (
		<>
			{ocrDetails.picture.length === 1 &&
				groupsList.length === 0 &&
				showOnePicture &&
				ocrDetails.picture[0].type === "application/pdf" && (
					<div className="DocumentHolder" style={{ height: "500px", overflowY: "scroll" }}>
            <Document file={ocrDetails.picture[0]} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={index + 1} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
				)}
		</>
	);
};

export default PreviewFile;
