import React from "react";
import Xarrow from "react-xarrows";

const FlowArrow = ({ subFlow, subFlowId, steps }) => {
  return (
    <React.Fragment>
      {(steps.length > 0 || subFlow) &&
        steps.map((step, idx) => (
          <React.Fragment>
            {idx < steps.length - 1 && (
              <Xarrow
                key={step.uniqueId}
                start={
                  subFlowId
                    ? `items-holder-${idx}-${subFlowId}`
                    : `items-holder-${idx}`
                }
                end={
                  subFlowId
                    ? `items-holder-${idx + 1}-${subFlowId}`
                    : `items-holder-${idx + 1}`
                }
                color="var(--background_2)"
                strokeWidth={2}
                headShape="circle"
                arrowHeadProps={{
                  fill: "transparent",
                  strokeWidth: "0.1",
                  stroke: "var(--background_2)",
                }}
                className="ArrowScale"
              />
            )}
            {/* SUBFLOW */}
            {step.code === "mcq" && (
              <React.Fragment>
                {step.options.map((question, q) => (
                  <>
                    <Xarrow
                      key={`${step.uniqueId}--$${q}`}
                      start={
                        subFlowId
                          ? `items-holder-${idx}-${subFlowId}`
                          : `items-holder-${idx}`
                      }
                      end={
                        subFlowId
                          ? `q-${idx}-${q}-${subFlowId}`
                          : `q-${idx}-${q}`
                      }
                      startAnchor="bottom"
                      endAnchor="top"
                      color="var(--background_2)"
                      strokeWidth={2}
                      headShape="circle"
                      path={"smooth"}
                      curveness={0.5}
                      arrowHeadProps={{
                        fill: "transparent",
                        strokeWidth: "0.1",
                        stroke: "var(--background_2)",
                      }}
                      className="ArrowScale"
                    />
                  </>
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default FlowArrow;
