import React from 'react';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import i18n from "../../../lang/i18n";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enUS } from "date-fns/locale";

const Datepicker = ({ date, setDate, disabled, label, type, disabledPast, disabledFuture, placeholder }) => {
    return (
        <LocalizationProvider
            // dateAdapter={AdapterMoment}
            dateAdapter={AdapterDateFns}
            // adapterLocale={i18n.language}
            locale={enUS}
        >
            <DatePicker
                label={label}
                className={label ? "" : (type ? type : "reset-dp")}
                value={date}
                onChange={setDate}
                format="dd-MM-yyyy"
                views={["year", "month", "day"]}
                disabled={disabled}
                disablePast={disabledPast}
                disableFuture={disabledFuture}
                placeholder={placeholder}
            />
        </LocalizationProvider>
    );
};

export default Datepicker;