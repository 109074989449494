import React, { forwardRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";

const FormButton = forwardRef(({
  name,
  route,
  handleInput,
  selection,
  index,
  details,
  condition,
  hideNext,
}, ref) => {
  const { t } = useTranslation();

  async function onClickHandler() {
    await handleInput(
      selection,
      route === "next" ? index + 1 : index - 1,
      "manual",
      details
    );
    let thisDiv = document.getElementsByClassName("QuestionContainer");
    thisDiv[0].classList.add(route === "next" ? "nextQ" : "backQ");
    setTimeout(() => {
      try {
        thisDiv[0].classList.remove(route === "next" ? "nextQ" : "backQ");
      } catch (e) {
        console.log("Error: ", e);
      }
    }, 1000);
  }

  return (
    <div
      ref={ref}
      className={`ButtonTransparent SlowShow TemporaryStepsButton ${condition ? "" : "DisabledButton"}`}
      style={{
        display: hideNext ? "none" : "flex"
      }}
      onClick={async () => await onClickHandler()}
    >
      {route === "back" && <ArrowBackIcon />}
      <div>{t(name)}</div>
      {route !== "back" && <ArrowForwardIcon />}
    </div>
  );
})

export default FormButton;
