import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import { useTranslation } from "react-i18next";
import useLocalStorage from "use-local-storage";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { setStyleAttribute, showToast } from "../config/helpers";
import { Request } from "../config/requests";
import { getToken } from "../config/token";
import { useDispatch } from "react-redux";
import { updateLogo } from "../config/actions";

const fonts = ["Roboto", "Montserrat"];

export default function Settings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [font, setFont] = useLocalStorage("font", "Roboto");
  const [primaryColor, setPrimaryColor] = useLocalStorage(
    "primaryColor",
    "#fe4b4e"
  );
  const [secondaryColor, setSecondaryColor] = useLocalStorage(
    "secondaryColor",
    "#fff9f0"
  );
  const [logo, setLogo] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSetFont = (e) => {
    const f = e.target.value;
    setStyleAttribute("--primary-font", f);
    setFont(f);
  };

  const onPrimaryColor = (e) => {
    const c = e.target.value;
    setStyleAttribute("--primary-color", c);
    setPrimaryColor(c);
  };

  const onSecondaryColor = (e) => {
    const c = e.target.value;
    setStyleAttribute("--secondary-color", c);
    setSecondaryColor(c);
  };

  const submitSettings = () => {
    const fd = new FormData();
    fd.append("logo", logo);
    setIsSubmitting(true);
    Request({
      method: "post",
      token: getToken(),
      route: "api/users/admin/logo",
      formData: fd,
    })
      .then(() => {
        showToast(t("successfullyUpdated"));
        window.location.reload()
      })
      .catch((err) => {
        showToast(t(err.message || "somethingWentWrong"), { error: true });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="main-container">
      <Header />
      <div className="settings-page">
        <div className="container">
          <div className="row">
            {/* <BackButton /> */}
            <div className="col-12 mx-auto">
              <form>
                <h2>{t("Settings")}</h2>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    {/* <b className="sc-ckMVTt form__top-label">{t("Font")}</b> */}
                    <div className="form-group row ">
                      <label
                        className="col-sm-4 col-xl-4 col-form-label"
                        title={t("Font")}
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {t("Font")}
                      </label>
                      <div className="col-sm-8 col-xl-8">
                        <FormControl fullWidth size="small">
                          <InputLabel id="demo-simple-select-label">
                            {t("Font")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={font ?? ""}
                            label={t("Font")}
                            onChange={onSetFont}
                          >
                            {fonts.map((i) => (
                              <MenuItem value={i}>{i}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <label
                        className="col-sm-4 col-xl-4 col-form-label"
                        title={t("PrimaryColor")}
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {t("PrimaryColor")}
                      </label>
                      <div className="col-sm-8 col-xl-8">
                        <FormControl fullWidth size="small">
                          <Input
                            onChange={onPrimaryColor}
                            type="color"
                            value={primaryColor}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <label
                        className="col-sm-4 col-xl-4 col-form-label"
                        title={t("SecondaryColor")}
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {t("SecondaryColor")}
                      </label>
                      <div className="col-sm-8 col-xl-8">
                        <FormControl fullWidth size="small">
                          <Input
                            onChange={onSecondaryColor}
                            type="color"
                            value={secondaryColor}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="form-group row ">
                      <label
                        className="col-sm-4 col-xl-4 col-form-label"
                        title={t("Logo")}
                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {t("Logo")}
                      </label>
                      <div className="col-sm-8 col-xl-8">
                        <FormControl fullWidth size="small">
                          <Input
                            type="file"
                            onChange={(e) => setLogo(e.target.files[0])}
                          />
                        </FormControl>
                      </div>
                    </div>
                    {/* <input
                      type="button"
                      className="btn btn-primary float-right"
                      data-cy="changeCustomerDataButton"
                      disabled={!logo || isSubmitting}
                      onClick={submitSettings}
                      value={t("updateLogo")}
                    /> */}
                    <button
                      className={
                        (!logo || isSubmitting)
                          ? "ButtonReverse ButtonRight"
                          : "ButtonReverse Enabled ButtonRight"
                      }
                      onClick={submitSettings}
                      disabled={!logo || isSubmitting}
                    >
                      {t("updateLogo")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
