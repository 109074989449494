import React, { useState } from "react";
import { getToken } from "../../config/token";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Markup } from "interweave";
import BlinkWhite from "../../assets/footer/Logo_Animation_weiß_footer.gif";
import SeparatorWhite from "../../assets/footer/separator_vertical_white.svg";
import WienerWhite from "../../assets/footer/wiener_white.svg";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ModalCancelContract from "../../features/claims/modals/ModalCancelContract/ModalCancelContract";

export default function Footer({ mode, modeHandler }) {
  const { token } = getToken() ? JSON.parse(getToken()) : {};
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // Delete this if we decide not to use "Settings"
  // const logo = useSelector((s) => s.form.logo);
  const [openModalCancelContract, setOpenModalCancelContract] = useState(false);

  return (
    <nav data-cy="footer" className="sc-olbas footer">
      <div className="container-footer relative">
        <div className="footer__content">
          <div data-cy="logo-footer" className="sc-lbOyJj footer__logo">
            <div className="footer__logo-icons">
              <img src={BlinkWhite} height={82} alt="BlinkWhite" />
              {/* <img src={SeparatorWhite} height={82} alt="SeparatorWhite" /> */}
              {/* <img src={WienerWhite} height={82} alt="WienerWhite" /> */}
            </div>
            <div className="footer__networks-logo">
              <div style={{ cursor: "pointer" }} onClick={() => window.open("https://www.facebook.com/PandaTierversicherung", "_blank")}>
                <FacebookIcon fontSize="large" />
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => window.open("https://www.instagram.com/panda.hundeversicherung/?hl=de", "_blank")}>
                <InstagramIcon fontSize="large" />
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => window.open("https://at.linkedin.com/company/panda-insurtech", "_blank")}>
                <LinkedInIcon fontSize="large" />
              </div>
            </div>
          </div>
          <div className="footer__about">
            <div className="sc-hiMGwR footer__row__item">
              <Markup content={`<strong>${t("riskTaker")}</strong>`}></Markup>
              <Markup content={t("riskTakerAddress")}></Markup>
            </div>
            <div className="sc-hiMGwR footer__row__item">
              <Markup content={`<strong>${t("commercial")}</strong>`} />
              <Markup content={t("commercialRegister")} />
            </div>
            <div className="sc-hiMGwR footer__row__item">
              <Markup content={`<strong>${t("member")}</strong>`} />
              <Markup content={t("memberBoard")} />
              {/* <div
                style={{ textDecoration: "underline", fontWeight: 700, cursor: "pointer" }}
                onClick={() => setOpenModalCancelContract(true)}
              >
                {t("cancelContract")}
              </div> */}
            </div>
          </div>
        </div>
        <nav className="sc-ehmTmK footer__links nav footer__nav">
          <a
            data-cy="imprint"
            className="sc-gFGZVQ footer__link"
            href="/imprint"
          >
            {t("imprint")}
          </a>
          <p>|</p>
          <a
            data-cy="data-privacy"
            className="sc-gFGZVQ footer__link"
            href="/data-privacy"
          >
            {t("dataProtection")}
          </a>
          {token && (
            <>
              <p>|</p>
              <a data-cy="faq" className="sc-gFGZVQ footer__link" href="/faq">
                {t("faq")}
              </a>
            </>
          )}
        </nav>
        {pathname === "/login" &&
          <div
            className="footer__mode"
            onClick={modeHandler}
          >
            {mode ? t("userLogin") : t("Admin Login")}
          </div>
        }
      </div>
      {openModalCancelContract &&
        <ModalCancelContract
          openModalCancelContract={openModalCancelContract}
          setOpenModalCancelContract={setOpenModalCancelContract}
        />}
    </nav>
  );
}
