import * as React from "react";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import ModalWrapper from "../../../components/modalWrapper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateWtdModalOpen } from "../../../config/actions";

function ModalExistingClaim({
  openModalExistingClaim,
  setOpenModalExistingClaim,
  petId,
  reportType = "General",
  setOpenModalWhatToDo,
}) {
  const { t } = useTranslation();
  const router = useNavigate();
  const dispatch = useDispatch();

  function handleClose() {
    setOpenModalExistingClaim(false);
  };

  function reportTypeHandler(reportType) {
    switch (reportType) {
      case "General":
      case "General to Vets":
        return [t("submitInvoice"), t("invoice")];
      case "Liability claim":
        return [t("submitDocument"), t("document")];
      case "Cost estimation":
        return [t("CostEstimation"), t("costEstimation")];
    }
  }

  return (
    <ModalWrapper
      headerTitle={reportTypeHandler(reportType)[0]}
      openModal={openModalExistingClaim}
      setModalWrapper={() => handleClose()}
    >
      <div
        className="modal_container__body__text"
        style={{
          maxWidth: "540px",
          margin: "0 0 5vh 0",
        }}
      >
        {t("doesBelongExistingClaim1") + reportTypeHandler(reportType)[1] + t("doesBelongExistingClaim2")}
      </div>
      <div class="modal_container__body__row-modal">
        <div
          className={`button_red_small ${isMobileOnly && "mobile"}`}
          onClick={() => {
            setOpenModalWhatToDo(false);
            dispatch(updateWtdModalOpen(false));
            handleClose();
            router(`/contact/${petId}/claim?type=${encodeURIComponent(reportType)}`);
          }}
        >
          {t("no")}
        </div>
        <div
          className={`button_red_small ${isMobileOnly && "mobile"}`}
          onClick={() => {
            setOpenModalWhatToDo(false);
            dispatch(updateWtdModalOpen(false));
            handleClose();
            router(`/contact/${petId}/claim?type=${encodeURIComponent('Existing Claim')}`);
          }}
        >
          {t("yes")}
        </div>
      </div>
    </ModalWrapper>
  );
}

export default ModalExistingClaim;