import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error);
        this.setState({ hasError: true, error });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="main-container SlowShow">
                    <Header />
                    <div className={' page-content relative d-flex flex-column align-items-center justify-content-center'}>
                        <span className={'headline1_red_title'} style={{paddingBottom:0}}>Something went wrong.</span>
                        <span className={'black_fat_rooney'}>{this.state.error.message}</span>
                    </div>
                    <Footer />
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
