import { useSelector } from "react-redux";
import { FLOW_TYPES } from "../constants/Flow";

const useExistingFlowCheck = () => {
  const flowType = useSelector((s) => s.flow.flowType);
  const isExistingFlow = flowType === FLOW_TYPES["Existing Claim"];

  return { isExistingFlow };
};

export default useExistingFlowCheck;
