import React, { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import TooltipArrowRight from "../../assets/iconsSmall/tooltip_red_arrow_right.svg";
import Question from "../../assets/iconsSmall/red_question.svg";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";


const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    margin: 0 0 0 65px !important;
    padding: 20px;
    border-radius: 0 20px 20px 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & .MuiTooltip-arrow {
    color: transparent;
    position: absolute;
    width: 60px !important;
    height: 30px !important;
    top: -4px !important;
    left: -40px !important;

    &::before {
      border-style: solid;
      border-width: 0px 60px 30px 0;
      /* border-color: transparent; */
      /* border-top-left-radius: 10px; */
      /* border-bottom-left-radius: 10px; */
      transform: rotate(0deg);
    }
  }
`;

const BootstrapTooltipMobile = (parrentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log("parrentProps", parrentProps);

  const handleTooltipClose = (e) => {
    setIsOpen(false);
  };

  const handleTooltipOpen = (e) => {
    setIsOpen((open) => !open);
  };

  const StyledMobileTooltip = styled(({ ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: props.className }}
      open={isOpen}
      onClick={handleTooltipOpen}
      onClose={handleTooltipClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    />
  ))`
    & .MuiTooltip-tooltip {
      padding: 20px 20px 20px;
      border-radius: 20px 20px 20px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    & .MuiTooltip-arrow {
      position: absolute;
      width: 30px !important;
      height: 20px !important;
      top: 10px !important;
      right: -15px !important;
      background-image: url(${TooltipArrowRight});
      background-repeat: no-repeat;
      background-size: contain;
      overflow: unset;

      &::before {
        display: none;
      }

      // &:after {
      //   content: '✕';
      //   position: absolute;
      //   top: -5px;
      //   right: 45px;
      //   font-size: 25px;
      //   font-weight: bold;
      //   color: #FFF;
      //   cursor: pointer;
      //   overflow: visible;
      //   }
    }
  `;

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <>
        <StyledMobileTooltip {...parrentProps} />
        {isOpen && (
          <div className="blurred-background" onClick={handleTooltipClose} />
        )}
      </>
    </ClickAwayListener>
  );
};

function CustomTooltip({ name }) {
  const { t } = useTranslation();

  return (
    <>
      {isMobile ? (
        <BootstrapTooltipMobile
          title={t(name)}
          // placement="left-start"
          // arrow
        >
          <img
            src={Question}
            width={isMobileOnly ? 20 : 24}
            height={isMobileOnly ? 20 : 24}
            className="tariff_details__item__right__tooltip"
          />
        </BootstrapTooltipMobile>
      ) : (
        <BootstrapTooltip
          title={t(name)}
          placement="right-start"
          followCursor
          arrow
        >
          <img
            src={Question}
            width={isMobileOnly ? 20 : 24}
            height={isMobileOnly ? 20 : 24}
            className="tariff_details__item__right__tooltip"
          />
        </BootstrapTooltip>
      )}
    </>
  );
}

export default CustomTooltip;
