import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobileOnly } from "react-device-detect";
import { showToast } from "../../../config/helpers";
import ModalWrapper from "../../../components/modalWrapper";
// import CustomSelect from "../../../components/inputs/select/CustomSelect";
import { getToken } from "../../../config/token";
import { Request } from "../../../config/requests";

function ModalChatBubble({
  openModalChatBubble,
  setOpenModalChatBubble,
}) {
  const token = getToken();
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  // const [type, setType] = useState("");
  // const [page, setPage] = useState("");
  const [message, setMessage] = useState("");

  // const typeOptions = [
  //   {label: t("bug"), value: "Bug"},
  //   {label: t("error"), value: "Error"},
  //   {label: t("enhancements"), value: "Enhancements"},
  // ]

  // const pageOptions = [
  //   {label: t("myProducts"), value: "My products"},
  //   {label: t("petPage"), value: "Pet`s page"},
  //   {label: t("personalData"), value: "Personal data"},
  //   {label: t("myInvoices"), value: "My invoices"},
  // ]

  const submitTicket = () => {
    const fd = new FormData();
    fd.append("data", JSON.stringify({
      "Title": title,
      "Message": message,
    }));
    fd.append("type", "bug");

    Request({
      method: "post",
      token: token,
      route: "api/users/change_data",
      formData: fd,
    })
      .then(() => { })
      .catch((err) => {
        showToast(t(err.message || "somethingWentWrong"));
      })
      .finally(() => {
        showToast(t("ticketConfirmed"));
      });
  };

  function handleClose() {
    setOpenModalChatBubble(false);
  };

  async function handleSubmit() {
    submitTicket();
    handleClose();
    // console.log("MCB > title", title)
    // console.log("MCB > type", type?.value)
    // console.log("MCB > page", page?.value)
    // console.log("MCB > message", message)
    // showToast(t("ticketConfirmed"));
  };

  return (
    <ModalWrapper
      headerTitle={t("createTicket")}
      openModal={openModalChatBubble}
      setModalWrapper={() => setOpenModalChatBubble(false)}
    >
      <div className="modal_container__body__inputs">
        <input
          className="modal_container__body__input"
          placeholder={t("title")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {/* <CustomSelect
            variant="modal-edit-data"
            options={typeOptions}
            formatCreateLabel={(i) => i.label}
            placeholder={t("type")}
            value={type}
            onChange={(e) => setType(e)}
            isClearable={false}
          />
        <CustomSelect
            variant="modal-edit-data"
            options={pageOptions}
            formatCreateLabel={(i) => i.label}
            placeholder={t("page")}
            value={page}
            onChange={(e) => setPage(e)}
            isClearable={false}
          /> */}
        <textarea
          className="modal_container__body__input"
          style={isMobileOnly ? { height: "130px" } : { height: "230px" }}
          placeholder={t("message") + "*"}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      {/* <div className="modal_container__body__text" >
            {t("noteScreenshot")}
          </div> */}
      <div className="modal_container__body__text" style={{ margin: "0 0 20px 0" }}>{t("noteScreenshot")}</div>
      <div
        className={
          (title && message)
            ? `button_red_small ${isMobileOnly && "mobile"}`
            : `button_red_smal_disabled ${isMobileOnly && "mobile"}`
        }
        onClick={(title && message)
          ? handleSubmit
          : ((e) => e.stopPropagation())}
      >
        {t("send")}
      </div>
    </ModalWrapper>
  );
}

export default ModalChatBubble;
