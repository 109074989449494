import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import BackButton from "../components/common/BackButton";
import FlowManagement from "./flowManagement/FlowManagement";
import useLocalStorage from "use-local-storage";

export default function Management() {
  const [themeSetting] = useLocalStorage("theme", "panda");
  return (
    <div className="main-container">
      <Header />
      <div className="page-content">
        <div className="claims">
          <FlowManagement themeSetting={themeSetting} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
