import { useTranslation } from "react-i18next";
import ImageUploader from "react-images-upload";

import uploadIcon from "../../../../assets/iconsSmall/upload-btn.png";
import pandaIcon from "../../../../assets/UserInfo/panda-logo-main.png";

import { useRef } from "react";
import useExistingFlowCheck from "../../../../hooks/useExistingFlowCheck";

const Uploader = ({ onDrop, ocrDetails, groupsList, showOnePicture }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const parentRef = useRef(null);

  const uploadClick = () => {
    ref.current.inputElement.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    parentRef.current.style.backgroundColor = "lightgray";
  };

  const handleDragLeave = () => {
    parentRef.current.style.backgroundColor = "transparent";
  };

  const handleDrop = (e) => {
    e.preventDefault();
    parentRef.current.style.backgroundColor = "transparent";
    const files = e.dataTransfer.files;
    onDrop(files);
  };

  return (
    <div className="SelectInvoice"
      ref={parentRef}
      onClick={() => uploadClick()}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <img src={pandaIcon} alt="Panda" className="ocr-upload-panda-bg" />
      <img src={uploadIcon} alt="Upload" className="ocr-upload-img" />

      <div className="flow-subtitle-text">{t("invoiceUploadPlaceholder")}</div>

      <ImageUploader
        ref={ref}
        withIcon={false}
        buttonText={t("invoiceUploadSubmitText")}
        onChange={onDrop}
        imgExtension={[
          ".jpg",
          ".jpeg",
          ".png",
          ".pdf",
          ".heic",
          ".HEIC",
          ".heif",
        ]}
        accept="accept=*"
        maxFileSize={5242880}
        singleImage={!!false}
        buttonClassName="ButtonReverse Enabled"
        label={null}
        fileContainerStyle={{
          backgroundColor: "transparent",
          boxShadow: "none",
          margin: "0",
          padding: "0",
        }}
        labelStyles={{
          position: "absolute",
          opacity: 0,
          visibility: "hidden",
        }}
        buttonStyles={{
          margin: "0",
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export default Uploader;
