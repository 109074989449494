import * as React from "react";
import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isMobile, isMobileOnly } from "react-device-detect";
import { showToast } from "../../../../config/helpers";
import ModalWrapper from "../../../../components/modalWrapper";
import CatDog from "../../../../assets/Insurances/cat_dog.svg";
import Exclamation from "../../../../assets/iconsSmall/exclamation_mark_dark.svg";
import Attach from "../../../../assets/iconsSmall/paper_clip.svg";
import Remove from "../../../../assets/iconsSmall/cross_black.svg";
import Upload from "../../../../assets/iconsSmall/upload_icon.svg";
import { Request } from "../../../../config/requests";
import { Markup } from "interweave";
import { useNavigate, useParams } from "react-router-dom";
import EmailSent from "./components/EmailSent";
import CustomSelect from "../../../../components/inputs/select/CustomSelect";

function ModalCancelContract({
  openModalCancelContract,
  setOpenModalCancelContract,
}) {
  const { t } = useTranslation();
  const history = useNavigate();
  const pets = useSelector((s) => s.flow.pets);
  const [selectedPet, setSelectedPet] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [selectedReason, setSelectedReason] = useState(null);
  const [circumstances, setCircumstances] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const options = [
    { label: t("opt1"), value: t("opt1") },
    { label: t("opt2"), value: t("opt2") },
    { label: t("opt3"), value: t("opt3") },
    { label: t("opt4"), value: t("opt4") },
    { label: t("opt5"), value: t("opt5") },
    { label: t("opt6"), value: t("opt6") },
    { label: t("opt7"), value: t("opt7") },
  ];
  const [dialog, setDialog] = useState("main");

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  function handleClose() {
    setSelectedPet(null);
    setSelectedContract(null);
    setSelectedReason(null);
    setCircumstances(null);
    setSelectedFiles([]);
    setDialog("main");
    setOpenModalCancelContract(false);
  };

  function selectPet() {
    //since no API

    // Request({ method: "get", route: "api/claims/contracts" })
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((json) => {
    //     setPetContracts(json.contracts);
    //   });
    setContracts([
      "OP-Schutz 2303G93GF779",
      "Krankenvollschutz 2303F93G3218",
      "Haftpflichtschutz 2317J93GF733",
    ])
    setDialog("contracts");
  }

  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   handleClose();
  //   showToast(t("emailConfirmed"));
  // };

  const pets2 = pets;
  // const pets2 = pets.slice(0, 2)
  // const pets2 = [...pets, ...pets].slice(0, 5)
  // console.log("ModalCancelContract > pets", pets2)
  // console.log("ModalCancelContract > dialog", dialog)
  // console.log("ModalCancelContract > selectedReason", selectedReason)
  // console.log("ModalCancelContract > selectedFiles", selectedFiles)

  const renderPets = () => {
    const rows = [];
    const numPets = pets2.length;

    for (let i = 0; i < numPets; i += 3) {
      const rowPets = pets2.slice(i, i + 3);
      const row = (
        <div key={`row-${i}`} className="pet_container__row-modal">
          {rowPets.map((pet) => (
            <div
              className={`pet_container__item ${pet.id !== selectedPet ? "not-selected" : ""}`}
              onClick={() => setSelectedPet(pet?.id)}
            >
              <div
                className={
                  rowPets.length < 3
                    ? "pet_container__wrapper-modal"
                    : "pet_container__wrapper-modal small"
                }
              >
                <div>
                  {pet?.image ? (
                    <img
                      src={`data:image/jpeg;base64, ${pet?.image}`}
                      alt="CatDog"
                      height="100%"
                    />
                  ) : (
                    <img src={CatDog} alt="CatDog" width="95%" height="95%" />
                  )}
                </div>
              </div>
              <div className="pet_container__name">
                {pet?.attributes?.name}
                {pets2.filter(el => el.attributes.name === pet.attributes.name).length > 1 &&
                  <div>{pet?.policyId}</div>}
              </div>
            </div>
          ))}
        </div>
      );

      rows.push(row);
    }

    return rows;
  };

  return (
    <ModalWrapper
      headerTitle={t("cancelContract")}
      openModal={openModalCancelContract}
      setModalWrapper={() => handleClose()}
    >
      {dialog === "main" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("whichPetCancel")}
          </div>
          <div className="modal_container__body__inputs">
            {renderPets()}
          </div>
          <div
            className={
              (selectedPet !== null)
                ? `button_red_small ${isMobileOnly && "mobile"}`
                : `button_red_smal_disabled ${isMobileOnly && "mobile"}`
            }
            onClick={(selectedPet !== null)
              ? selectPet
              : ((e) => e.stopPropagation())}
          >
            {t("Next2")}
          </div>
        </>}

      {dialog === "contracts" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("whicContractCancel")}
          </div>
          <div className="modal_container__body__inputs">
            {contracts.map(el => (
              <div
                className={`modal_container__body__button-modal ${selectedContract !== el ? "disabled" : ""}`}
                onClick={() => setSelectedContract(el)}
              >
                {el}
              </div>
            ))}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                setSelectedContract(null);
                setDialog("main");
              }}
            >
              {t("Back")}
            </div>
            <div
              className={
                (selectedContract !== null)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(selectedContract !== null)
                ? (() => setDialog("reason"))
                : ((e) => e.stopPropagation())}
            >
              {t("Next2")}
            </div>
          </div>
        </>}

      {dialog === "reason" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "unset" }}>
            {t("selectOption")}
          </div>
          <div className="modal_container__body__inputs">
            <div
              className={`modal_container__body__button-modal wide ${selectedReason !== "reason1" ? "disabled" : ""}`}
              onClick={() => setSelectedReason("reason1")}
            >
              {t("reason1")}
            </div>
            <div
              className={`modal_container__body__button-modal wide ${selectedReason !== "reason2" ? "disabled" : ""}`}
              onClick={() => setSelectedReason("reason2")}
            >
              {t("reason2")}
            </div>
            <div
              className={`modal_container__body__button-modal wide ${selectedReason !== "reason3" ? "disabled" : ""}`}
              onClick={() => setSelectedReason("reason3")}
            >
              {t("reason3")}
            </div>
            {/* Option below should be visible when the customer is within the revocation period. For "OP" and "Krankenschutz" tariffs, there is a 45-day revocation period, and for "Haftpflicht" there is a 14-day revocation period.*/}
            {true && <div
              className={`modal_container__body__button-modal wide ${selectedReason !== "reason4" ? "disabled" : ""}`}
              onClick={() => setSelectedReason("reason4")}
            >
              {t("reason4")}
            </div>}
            <div className="modal_container__body__info">
              <span>{t("hint")}</span>
              {t("hintModal")}
              <img src={Exclamation} />
            </div>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                setSelectedReason(null);
                setDialog("contracts");
              }}
            >
              {t("Back")}
            </div>
            <div
              className={
                (selectedReason !== null)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(selectedReason !== null)
                ? (() => setDialog("afterReason"))
                : ((e) => e.stopPropagation())}
            >
              {t("Next2")}
            </div>
          </div>
        </>}

      {dialog === "afterReason" && selectedReason === "reason1" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0 0 30px 0" }}>
            {t("afterReason1")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                setDialog("reason");
              }}
            >
              {t("Back")}
            </div>
            <div
              className={
                (selectedReason !== null)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(selectedReason !== null)
                ? (() => setDialog("afterReason2"))
                : ((e) => e.stopPropagation())}
            >
              {t("Next2")}
            </div>
          </div>
        </>}

      {dialog === "afterReason" && selectedReason === "reason2" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0 0 30px 0" }}>
            {t("afterReason2")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                setDialog("reason");
              }}
            >
              {t("Back")}
            </div>
            <div
              className={
                (selectedReason !== null)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(selectedReason !== null)
                ? (() => setDialog("afterReason2"))
                : ((e) => e.stopPropagation())}
            >
              {t("Next2")}
            </div>
          </div>
        </>}

      {dialog === "afterReason" && selectedReason === "reason3" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0" }}>
            {t("afterReason3")}
          </div>
          <div className="modal_container__body__inputs" style={{ margin: "40px 0" }}>
            <CustomSelect
              variant="modal-cancel-contract"
              options={options}
              formatCreateLabel={(i) => i}
              placeholder={t("selectHere")}
              value={circumstances}
              onChange={(e) => setCircumstances(e)}
              isClearable={true}
            />
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                setCircumstances(null);
                setDialog("reason");
              }}
            >
              {t("Back")}
            </div>
            <div
              className={
                (circumstances !== null)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(circumstances !== null)
                ? (() => setDialog("selectUpload"))
                : ((e) => e.stopPropagation())}
            >
              {t("Next2")}
            </div>
          </div>
        </>}

      {dialog === "selectUpload" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px" }}>
            {t("pleaseUpload")}
          </div>
          <div
            className="modal_container__body__uploader"
            style={{ maxWidth: "540px" }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              // accept=".jpg,.png"
              onChange={handleFileChange}
              hidden
              multiple
            />
            <img src={Upload} />
            <span>{t("uploadHere")}</span>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              style={{ width: "unset", padding: isMobile ? "0 20px" : "0 39px" }}
            >
              {t("uploadDocument")}
            </div>
          </div>
          {selectedFiles.length
            ? <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0 0 15px 0" }}>
              {t("uploadedDocuments")}
            </div>
            : null}
          {selectedFiles.length
            ? selectedFiles.map((file, ind) => (
              <div key={ind} className="modal_container__body__attach" style={{ maxWidth: "440px", margin: "0 0 20px 0" }}>
                <img
                  src={Attach}
                  className="pointer"
                  width={isMobile ? 30 : 40}
                  alt="Attach"
                />
                <input
                  readOnly
                  className="modal_container__body__input-file"
                  placeholder={t("addDocument")}
                  value={file.name || ""}
                />
                <img
                  src={Remove}
                  alt="Remove"
                  style={{
                    position: "absolute",
                    right: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter(el => el.name !== file.name))}
                />
              </div>
            ))
            : null}
          <div className="modal_container__body__row-modal" style={{ margin: "10px 0 0 0" }}>
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                setSelectedFiles([]);
                setDialog("afterReason");
                setSelectedReason("reason3");
              }}
            >
              {t("Back")}
            </div>
            <div
              className={
                (selectedReason !== null)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(selectedReason !== null)
                ? (() => setDialog("afterReason2"))
                : ((e) => e.stopPropagation())}
            >
              {t("Next2")}
            </div>
          </div>
        </>}

      {((dialog === "afterReason" && selectedReason === "reason4") || (dialog === "afterReason2")) &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0" }}>
            {t("afterReason2start")}
          </div>
          <div className="modal_container__body__inputs">
            <div
              className={`modal_container__body__button-modal`}
              onClick={() => { }}
            >
              {/* need to clarify which contract to show */}
              {selectedContract || contracts[0]}
            </div>
          </div>
          <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0 0 30px 0" }}>
            <Markup content={t("afterReason2end")} />
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              onClick={() => {
                if (selectedReason === "reason4") {
                  setDialog("reason")
                } else if (dialog === "afterReason2" && selectedReason === "reason3") {
                  setDialog("selectUpload")
                } else {
                  setDialog("afterReason");
                  setSelectedReason(prevSelectedReason => prevSelectedReason);
                }
              }}
            >
              {t("Back")}
            </div>
            <div
              className={
                (selectedReason !== null)
                  ? `button_red_small ${isMobileOnly ? "mobile" : ""}`
                  : `button_red_smal_disabled ${isMobileOnly ? "mobile" : ""}`
              }
              onClick={(selectedReason !== null)
                ? (() => setDialog("emailSent"))
                : ((e) => e.stopPropagation())}
            >
              {t("Next2")}
            </div>
          </div>
        </>}

      {dialog === "emailSent" &&
        <>
          <div className="modal_container__body__text" style={{ maxWidth: "540px", margin: "0 0 30px 0" }}>
            {t("emailSent")}
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly ? "mobile" : ""}`}
              style={{ width: "unset" }}
              onClick={() => {
                handleClose();
                history("/");
              }}
            >
              {t("toStartPage")}
            </div>
          </div>
        </>}

      {/* {dialog === "emailSent" &&
        <EmailSent
          handleClose={handleClose}
        />} */}
    </ModalWrapper>
  );
}

export default ModalCancelContract;
