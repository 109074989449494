import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isMobile, isMobileOnly } from "react-device-detect";
import { showToast } from "../../../config/helpers";
import ModalWrapper from "../../../components/modalWrapper";
import Datepicker from "../../../components/common/components/Datepicker";
import Attach from "../../../assets/iconsSmall/paper_clip.svg";
import Remove from "../../../assets/iconsSmall/cross_black.svg";
import breedListDogs from "../../../assets/dog_breeds.json";
import breedListCats from "../../../assets/cat_breeds.json";
import CustomSelect from "../../../components/inputs/select/CustomSelect";
import moment from "moment";

function ModalEditData({
  openModalEditData,
  setOpenModalEditData,
  petInfo,
  submitPetData
}) {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState("main");
  const [prevDialog, setPrevDialog] = useState("");
  const [birthDate, setBirthDate] = useState(new Date(petInfo?.attributes?.birthDate) || null);
  const initBirthDate = new Date(petInfo?.attributes?.birthDate) || null;
  const [firstBreed, setFirstBreed] = useState(null);
  const [secondBreed, setSecondBreed] = useState(null);
  const initFirstBreedValue = petInfo?.attributes?.firstBreed || petInfo?.attributes?.firstDogBreed || petInfo?.attributes?.firstCatBreed || null;
  const initSecondBreedValue = petInfo?.attributes?.secondBreed || petInfo?.attributes?.secondDogBreed || petInfo?.attributes?.secondCatBreed || null;
  const [chipNumber, setChipNumber] = useState(petInfo?.attributes?.identificationNumber || null);
  const initChipNumber = petInfo?.attributes?.identificationNumber || null;
  const [breedOptions, setBreedOptions] = useState([]);
  const [chipError, setChipError] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  // console.log("MED > firstBreed", firstBreed)
  // console.log("MED > secondBreed", secondBreed)

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some((selectedFile) => selectedFile.name === file.name);
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  useEffect(() => {
    const options = [];
    let breedList = petInfo.type === "DOG" ? breedListDogs : breedListCats;

    breedList.forEach((el) => {
      options.push({ label: el.value_name, value: el.value });
    });
    if (petInfo.type === "DOG") options.unshift({ label: "Unbekannt", value: "Unbekannt" });
    const first = petInfo?.attributes?.firstBreed || petInfo?.attributes?.firstDogBreed || petInfo?.attributes?.firstCatBreed;
    const firstFoundBreed = options.find((i) => i.value === first);
    setFirstBreed(firstFoundBreed);
    if (petInfo?.attributes?.secondBreed || petInfo?.attributes?.secondDogBreed || petInfo?.attributes?.secondCatBreed) {
      const second = petInfo?.attributes?.secondBreed || petInfo?.attributes?.secondDogBreed || petInfo?.attributes?.secondCatBreed;
      const secondFoundBreed = options.find((i) => i.value === second);
      if (firstFoundBreed.value !== secondFoundBreed.value) setSecondBreed(secondFoundBreed);
    }
    setBreedOptions(options);
  }, []);

  // useEffect(() => {
  //   const foundOption = breedOptions.find(
  //     (option) =>
  //       option.label ===
  //       (petInfo?.attributes?.firstBreed || petInfo?.attributes?.firstDogBreed || petInfo?.attributes?.firstCatBreed)
  //   );

  //   if (foundOption) {
  //     setFirstBreed({ label: foundOption.label, value: foundOption.value });
  //   }
  // }, [
  //   petInfo?.attributes?.firstBreed,
  //   petInfo?.attributes?.firstDogBreed,
  //   petInfo?.attributes?.firstCatBreed,
  //   breedOptions,
  // ]);

  function isValidNumber(str) {
    return /^[0-9]{15}$/.test(str);
  }

  const customHandleChange = (e) => {
    const { value } = e.target;
    if (value.length <= 15) {
      if (isValidNumber(value)) {
        setChipNumber(value);
        setChipError(false);
      } else {
        setChipNumber(value);
        setChipError(true);
      }
    }
  };

  function handleClose() {
    setOpenModalEditData(false);
  }

  async function handleSubmit() {
    handleClose();
    let changedData = [];
    if (JSON.stringify(initBirthDate) != JSON.stringify(birthDate)) {
      changedData.push({
        "Change Type": "Birth date",
        "Updated value": moment(birthDate).format('DD-MM-YYYY'),
      })
    }
    if (initFirstBreedValue != firstBreed?.value) {
      changedData.push({
        "Change Type": "First breed",
        "Updated value": `${firstBreed?.label} / ${firstBreed?.value}`,
      })
    }
    if (initSecondBreedValue != secondBreed?.value) {
      changedData.push({
        "Change Type": "Second breed",
        "Updated value": `${secondBreed?.label} / ${secondBreed?.value}`,
      })
    }
    if (initChipNumber != chipNumber) {
      changedData.push({
        "Change Type": "Chip number",
        "Updated value": chipNumber,
      })
    }
    if (prevDialog === "additional") {
      if (message != "") {
        changedData = [
          {
            "Change Type": "Other request",
            "Message": message,
          }
        ]
      }
    }

    if (changedData.length > 0) submitPetData(changedData, selectedFiles);
  }

  return (
    <ModalWrapper
      headerTitle={dialog === "main" ? t("changeData") : t("sendRequest")}
      openModal={openModalEditData}
      setModalWrapper={() => setOpenModalEditData(false)}
    >
      {dialog === "main" && (
        <>
          <div className="modal_container__body__inputs wide-children">
            <div>
              <div className="modal_container__body__text" style={{ textAlign: "start", maxWidth: "unset" }}>
                {t("birthDate")}
              </div>
              <Datepicker
                placeholder={t("birthDate")}
                date={birthDate}
                setDate={(newDate) => setBirthDate(newDate)}
                disabled={false}
                type={(JSON.stringify(initBirthDate) != JSON.stringify(birthDate)) ? "reset-dp-modal" : "reset-dp-modal-opacity"}
                disabledFuture
              />
            </div>
            <div>
              <div className="modal_container__body__text" style={{ textAlign: "start", maxWidth: "unset" }}>
                {t("breed")}
              </div>
              <CustomSelect
                variant="modal-edit-data"
                options={breedOptions}
                formatCreateLabel={(i) => i.label}
                placeholder={t("breed")}
                value={firstBreed}
                onChange={(e) => setFirstBreed(e)}
                isClearable={false}
                fontColor={initFirstBreedValue != firstBreed?.value ? "#312F2F" : "#A09F9D"}
              />
            </div>
            {secondBreed !== null &&
              <div>
                <div className="modal_container__body__text" style={{ textAlign: "start", maxWidth: "unset" }}>
                  {t("secondBreed")}
                </div>
                <CustomSelect
                  variant="modal-edit-data"
                  options={breedOptions}
                  formatCreateLabel={(i) => i.label}
                  placeholder={t("secondBreed")}
                  value={secondBreed}
                  onChange={(e) => setSecondBreed(e)}
                  isClearable={false}
                  fontColor={initSecondBreedValue != secondBreed?.value ? "#312F2F" : "#A09F9D"}
                />
              </div>}
            <div>
              <div className="modal_container__body__text" style={{ textAlign: "start", maxWidth: "unset" }}>
                {t("chipNumber")}
              </div>
              <input
                className={(initChipNumber != chipNumber) ? "modal_container__body__input" : "modal_container__body__input-opacity"}
                style={{ border: chipError ? "3px solid #FE4B4E" : "" }}
                placeholder={t("chipNumber") + "*"}
                value={chipNumber}
                onChange={customHandleChange}
              />
            </div>
            {chipError &&
              <div className="modal_container__body__error FastShow">
                {t("chipWrong")}
              </div>}
          </div>
          <div className="modal_container__body__buttons">
            <div
              style={{ width: "unset" }}
              className={
                !chipError &&
                  ((JSON.stringify(initBirthDate) != JSON.stringify(birthDate)) ||
                    (initFirstBreedValue != firstBreed?.value) ||
                    (initSecondBreedValue != secondBreed?.value) ||
                    (initChipNumber != chipNumber))
                  ? `button_red_small ${isMobileOnly && "mobile"}`
                  : `button_red_smal_disabled ${isMobileOnly && "mobile"}`
              }
              onClick={
                !chipError &&
                  ((JSON.stringify(initBirthDate) != JSON.stringify(birthDate)) ||
                    (initFirstBreedValue != firstBreed?.value) ||
                    (initSecondBreedValue != secondBreed?.value) ||
                    (initChipNumber != chipNumber))
                  ? () => {
                    setPrevDialog("main");
                    setDialog("confirm");
                  }
                  : (e) => e.stopPropagation()
              }
            >
              {t("sendChange")}
            </div>
            <div
              style={{ width: "unset" }}
              className={`button_red_small ${isMobileOnly && "mobile"}`}
              onClick={() => setDialog("additional")}
            >
              {t("otherRequest")}
            </div>
          </div>
        </>
      )}

      {dialog === "additional" && (
        <>
          <div className="modal_container__body__inputs">
            <textarea
              className="modal_container__body__input"
              style={isMobileOnly ? { height: "130px" } : { height: "230px" }}
              placeholder={t("message") + "*"}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {selectedFiles.length
              ? selectedFiles.map((file, ind) => (
                <div
                  key={ind}
                  className="modal_container__body__attach"
                >
                  <img
                    src={Attach}
                    className="pointer"
                    width={isMobile ? 30 : 40}
                    alt="Attach"
                  />
                  <input
                    readOnly
                    className="modal_container__body__input-file"
                    placeholder={t("addDocument")}
                    value={file.name || ""}
                  />
                  <img
                    src={Remove}
                    alt="Remove"
                    style={{
                      position: "absolute",
                      right: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter(el => el.name !== file.name))}
                  />
                </div>
              ))
              : null}
            
            {/* Temporary limit for 1 document for upload */}
            {selectedFiles.length === 0
              ? <div
                className="modal_container__body__attach pointer"
                onClick={handleClick}
              >
                <img
                  src={Attach}
                  className="pointer"
                  width={isMobile ? 30 : 40}
                  alt="Attach"
                />
                <input
                  readOnly
                  className="modal_container__body__input-file"
                  placeholder={t("addDocument")}
                />
                <input
                  ref={fileInputRef}
                  // accept=".jpg,.png"
                  onChange={handleFileChange}
                  type="file"
                  hidden
                />
              </div>
              : null}
          </div>
          <div className="modal_container__body__buttons">
            <div
              className={
                message
                  ? `button_red_small ${isMobileOnly && "mobile"}`
                  : `button_red_smal_disabled ${isMobileOnly && "mobile"}`
              }
              style={{ width: "unset" }}
              onClick={
                message
                  ? () => {
                    setPrevDialog("additional");
                    setDialog("confirm");
                  }
                  : (e) => e.stopPropagation()
              }
            >
              {t("sendRequest")}
            </div>
          </div>
        </>
      )}

      {dialog === "confirm" && (
        <div className="modal_container__body">
          <div className="modal_container__body__inputs">
            <div
              className="modal_container__body__text"
              style={{ textAlign: "left" }}
            >
              {t("confirmChangeData_1")}
              <br />
              <br />
              {t("confirmChangeData_2")}
              <br />
              <br />
              {t("confirmChangeData_3")} &#x1F60A;
            </div>
          </div>
          <div
            className="button_red_small"
            onClick={() => {
              handleSubmit();
            }}
          >
            OK
          </div>
        </div>
      )}
    </ModalWrapper>
  );
}

export default ModalEditData;
