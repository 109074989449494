import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./mui.scss";
import reportWebVitals from "./reportWebVitals";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Ico from "./assets/logos/favicon.ico";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./config/store";
import Favicon from "react-favicon";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/de';

import "./lang/i18n";

const theme = createTheme({
  palette: {
    primary: {
      main: "#555555",
    },
    secondary: {
      main: "rgb(154, 154, 154)",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const { store, persistor } = configureStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <LocalizationProvider
    dateAdapter={AdapterMoment}
    // adapterLocale="de"
  >
    <Favicon url={Ico} />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </LocalizationProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
