import React from "react";
import petDog from "../../../assets/images/pet-dog.png";
import petCat from "../../../assets/images/pet-cat.png";
import { useSelector } from "react-redux";

const PetImage = () => {
  const currentPetInfo = useSelector((s) => s.flow.selectedPetInfo);

  return (
    <div className="pet-image">
      <img
        src={currentPetInfo.type === "DOG" ? petDog : petCat}
        alt="Pet-image"
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </div>
  );
};

export default PetImage;
