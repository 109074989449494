import { useEffect } from "react";

export const useThrottleEffect = (callback, dependencies, delay = 500) => {
  useEffect(() => {
    const throttledCallback = () => {
      let lastRan = Date.now();
      return (...args) => {
        if (Date.now() - lastRan >= delay) {
          callback(...args);
          lastRan = Date.now();
        }
      };
    };

    const throttled = throttledCallback();

    const handler = (...args) => throttled(...args);

    const timeout = setTimeout(handler, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, dependencies);
};
