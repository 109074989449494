import uuidv4 from "react-uuid";
import { toast } from "react-toastify";
import FlagEN from "../assets/images/united-kingdom.png";
import FlagGE from "../assets/images/germany.png";
import { CURRENCY, LANGUAGES } from "../constants/ApplicationConstants";
import moment from "moment";
import { checkLastClaim } from "../services/clams";
import { LAST_CLAIM_ERROR_MSG } from "../constants/Flow";

export const assignUniqueKey = (steps) => {
  if (Array.isArray(steps)) {
    steps = steps.map((i) => {
      if (Array.isArray(i)) return assignUniqueKey(i);
      if (i.content) {
        i.content = assignUniqueKey(i.content);
      }
      return { ...i, uniqueId: i.uniqueId ? i.uniqueId : uuidv4() };
    });
  }
  return steps;
};

export const getStepTitles = (steps) => {
  let titles = [];
  if (Array.isArray(steps)) {
    steps = steps.forEach((i) => {
      if (i.code === "mcq") {
        i.content.forEach((j) => {
          titles = [...titles, ...getStepTitles(j.content)];
        });
      } else titles.push(i.title?.trim());
    });
  }
  return titles;
};

export const setStyleAttribute = (attr, val) => {
  const root = document.documentElement;
  root.style.setProperty(attr, val);
};

export const german = {
  name: "german",
  months: [
    ["Januar", "Jan"],
    ["Februar", "Feb"],
    ["März", "Mär"],
    ["April", "Apr"],
    ["Mai", "Mai"],
    ["Juni", "Jun"],
    ["Juli", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["Oktober", "Okt"],
    ["November", "Nov"],
    ["Dezember", "Dez"],
  ],
  weekDays: [
    ["Samstag", "Sa"],
    ["Sonntag", "So"],
    ["Montag", "Mo"],
    ["Dienstag", "Di"],
    ["Mittwoch", "Mi"],
    ["Donnerstag", "Do"],
    ["Freitag", "Fr"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["vormittags", "AM"],
    ["nachmittags", "PM"],
  ],
};

export const claimsTableHeaders = [
  {
    title: "Date",
    sort: true,
  },
  {
    title: "claim",
    sort: true,
  },
  {
    title: "diagnosis",
    sort: true,
  },
  {
    title: "veterinarian",
    sort: true,
  },
  {
    title: "Status",
    sort: true,
  },
  {
    title: "Details",
    sort: false,
  },
];

export const claimsTableHeadersAdmin = [
  {
    title: "Date",
    sort: true,
  },
  {
    title: "claim",
    sort: true,
  },
  {
    title: "diagnosis",
    sort: true,
  },
  {
    title: "petName",
    sort: true,
  },
  {
    title: "veterinarian",
    sort: true,
  },
  {
    title: "Status",
    sort: true,
  },
  {
    title: "Details",
    sort: false,
  },
];

export const invoiceTableHeaders = [
  {
    title: "date",
    sort: true,
  },
  {
    title: "desc",
    sort: false,
  },
  {
    title: "got",
    sort: false,
  },
  {
    title: "quantity2",
    sort: false,
  },
  {
    title: "Amount",
    sort: false,
  },
];

export const existingClaimsHeaders = [
  {
    title: "date",
    sort: true,
  },
  {
    title: "claim",
    sort: true,
  },
  {
    title: "veterinarian",
    sort: true,
  },
  {
    title: "Status",
    sort: true,
  },
  {
    title: "Amount",
    sort: true,
  },
  {
    title: "Details",
    sort: false,
  },
];

export const showToast = (
  message,
  { theme = "light", position = "top-right", error = false } = {}
) => {
  error
    ? toast.error(message, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: theme,
    })
    : toast.success(message, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: theme,
    });
};

export const getLangIcon = (lang) => {
  switch (lang) {
    case LANGUAGES.EN:
      return FlagEN;
    case LANGUAGES.DE:
      return FlagGE;
    default:
      return "";
  }
};

export const getDate = (date_raw) => {
  return date_raw.split("T")[0];
};

export const formatAnyDate = (inputDateString) =>  {
  const possibleInputFormats = [
    "DD-MM-YYYY",
    "DD.MM.YYYY",
    "DD.MM.YY",
    "MM/DD/YYYY",
    "MM.DD.YYYY",
    "MM/DD/YY",
    "YYYY-MM-DD",
    "YYYY.MM.DD",
    "YY.MM.DD",
    "DD/MM/YYYY",
    "DD/MM/YY",
    "YYYY/MM/DD",
    "YY/MM/DD",
    "DD MM YYYY",
    "DD MM YY",
    "YYYY MM DD",
    "YY MM DD"
  ];

  let parsedDate = null;
  for (let format of possibleInputFormats) {
    parsedDate = moment(inputDateString, format, true);
    // parsedDate = moment(inputDateString, format);
    if (parsedDate.isValid()) {
      break;
    }
  }

  if (parsedDate && parsedDate.isValid()) {
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    // console.log("formattedDate", formattedDate)
    return formattedDate;
  } else {
    return "";
  }
}

// export const formatAnyDate = (inputDateString) => {
//   const possibleInputFormats = [
//     "DD-MM-YYYY",
//     "DD.MM.YYYY",
//     "DD.MM.YY",
//     "MM/DD/YYYY",
//     "MM.DD.YYYY",
//     "MM/DD/YY",
//     "YYYY-MM-DD",
//     "YYYY.MM.DD",
//     "YY.MM.DD",
//     "DD/MM/YYYY",
//     "DD/MM/YY",
//     "YYYY/MM/DD",
//     "YY/MM/DD",
//     "DD MM YYYY",
//     "DD MM YY",
//     "YYYY MM DD",
//     "YY MM DD"
//   ];

//   let formattedDate = "";

//   for (let format of possibleInputFormats) {
//     const parts = inputDateString.split(/[-./ ]/);
//     const dayIndex = format.indexOf("DD");
//     const monthIndex = format.indexOf("MM");
//     const yearIndex = format.indexOf("YYYY");

//     if (parts.length === 3 && dayIndex > -1 && monthIndex > -1 && yearIndex > -1) {
//       const day = parseInt(parts[dayIndex], 10);
//       const month = parseInt(parts[monthIndex], 10) - 1;
//       const year = parseInt(parts[yearIndex], 10);

//       const date = new Date(year, month, day);

//       if (isValidDate(date)) {
//         formattedDate = date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" });
//         console.log("formattedDate", formattedDate)
//         break;
//       }
//     }
//   }

//   return formattedDate;
// };

// function isValidDate(date) {
//   return date instanceof Date && !isNaN(date);
// }

export const formatTableDate = (dateString) => {
  // Check if dateString matches the "YYYY-MM-DD" format
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }
  // Check if dateString matches the "DD-MM-YYYY" format
  else if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      return moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD');
  }
  // Check if dateString matches the "DD.MM.YYYY" format
  else if (/^\d{2}\.\d{2}\.\d{4}$/.test(dateString)) {
    return moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD');
  }
  // Check if dateString matches the "DD/MM/YYYY" format
  else if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    return moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  // Defalut
      return moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');
}

export const incorrectDateRangePicked = (date, dateTwo) => {
  const date2 = moment(dateTwo);
  const date1 = moment(date);
  if (!date1.isValid() || !date2.isValid()) return true;
  else return date1.isSameOrAfter(date2);
};
export const reduceTotalAmount = (row) => {
  const tableDetails = row?.additionalProperties?.table_details;

  if (!tableDetails) return null;

  const amount = tableDetails.reduce((acc, val) => {
    return acc + +val?.Amount;
  }, 0);

  return amount.toFixed(2);
};

export const getCurrencyFromInvoice = (row) => {
  const details = row?.payments[0];

  if (!details) return CURRENCY.EUR;

  return CURRENCY[details?.payment?.currencySymbol] || CURRENCY.EUR;
};

export const isFlowExistingClaim = (searchParams) => {

  return searchParams.get("type") === "Existing Claim";
}

export async function checkLastClaimRecursively(id, attempt = 0) {
  const maxAttempts = 3;
  if (attempt >= maxAttempts) {
      // throw new Error(LAST_CLAIM_ERROR_MSG);
      // Pass user through flow
      return true
  }

  const { data: isLastClaimResolved } = await checkLastClaim(id);

  if (isLastClaimResolved) {
      return isLastClaimResolved;
  } else {
    await new Promise(resolve => setTimeout(resolve, 2000));
    return checkLastClaimRecursively(id, attempt + 1);
  }
}

export const getSettedTheme = () => {
  const bodyTheme = document.body.getAttribute('data-theme');
  return bodyTheme
}

export function compareData(InitialData, ResultData) {
  return ResultData.map(resultObj => {
      const initialObj = InitialData.find((initialObj) => resultObj.IdToCompare === initialObj.IdToCompare);
      // console.log('initialObj', initialObj, initialObj.IdToCompare, 'resultObj', resultObj, resultObj.IdToCompare);
      // return { ...resultObj }
      if (!initialObj) {
          // If object is not found in InitialData, it's a new object
          return { ...resultObj, IsNewLine: true };
      } else {
          // Compare properties of the objects
          const editedFields = Object.keys(resultObj).filter(key => !isEqual(resultObj[key], initialObj[key]));
          // console.log('editedFields', editedFields);
          return { ...resultObj, IsNewLine: resultObj?.IsNewLine || !!editedFields.length || false };
      }
  });
}

export function compareObjects(initialObj, resultObj) {
  const diffArray = [];

  for (const key in initialObj) {
      if (Object.prototype.hasOwnProperty.call(initialObj, key)) {
          if (resultObj.hasOwnProperty(key)) {
              if (initialObj[key] !== resultObj[key]) {
                  diffArray.push({
                      'Change Type': key,
                      'Old value': initialObj[key],
                      'New value': resultObj[key]
                  });
              }
          }
      }
  }

  return diffArray;
}

export function isEqual(obj1, obj2) {
  // Deep equality check for objects
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
