import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import FullScreen from "../../../assets/iconsSmall/full-screen.svg";
import Collapse from "../../../assets/iconsSmall/collapse.svg";
import Close from "../../../assets/iconsSmall/close.svg";
import PandaMain from "../../../assets/UserInfo/panda-logo-main.svg";
import ArrowLeft from "../../../assets/PetPage/arrow_left_dashed_small.svg";
import ArrowRight from "../../../assets/PetPage/arrow_right_dashed.svg";
import { isMobile, isMobileOnly } from "react-device-detect";
import TableRestyle from "../../ocr/invoiceTable/tableComponents/TableRestyle";
import { Input, InputAdornment, Pagination, PaginationItem } from "@mui/material";
import { ReactComponent as ArrowLeftRed } from "../../../assets/iconsSmall/arrow_left_solid_red.svg";
import { ReactComponent as ArrowRightRed } from "../../../assets/iconsSmall/arrow_right_solid_red.svg";
import CustomSelect from "../../../components/inputs/select/CustomSelect";
import { USER_MODE } from "../../../constants/ApplicationConstants";

function ModalEditableInvoice({
  openModalEditableInvoice,
  setOpenModalEditableInvoice,
  themeSetting,
  tableDetails,
  setTableDetails,
  initialTableDetails,
  handleUpdate,
  selectedTableDetails,
  resultTableDetails,
  gotList,
  handleNew,
  mode,
  petName,
}) {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [currentClaim, setCurrentClaim] = useState(1);

  const decreasePageNumber = () => {
    if (currentClaim > 1) { setCurrentClaim(currentClaim - 1) };
  };

  const increasePageNumber = () => {
    if (currentClaim < tableDetails.length) { setCurrentClaim(currentClaim + 1) };
  };

  const handlePage = (event, value) => {
    setCurrentClaim(value);
  };

  const style = {
    position: "absolute",
    // top: "50%",
    left: "50%",
    // transform: isMobile ? "translate(-50%, -50%)" : "translate(-50%, -35%)",
    width: isMobile ? "90%" : "80%",
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? "20px" : "45px",
    backgroundColor: "#FFF9F0 !important",
    padding: isMobile ? "20px" : "40px",
    transform: "translateX(-50%)",
    margin: "5vh 0 0 0",
    outline: 'none',
  };

  const styleFS = {
    position: isMobile ? "absolute" : "relative",
    top: isMobile ? "50%" : "",
    left: isMobile ? "50%" : "",
    transform: isMobile ? "translate(-50%, -50%)" : "",
    width: "100%",
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? "20px" : "45px",
    backgroundColor: "#FFF9F0 !important",
    padding: isMobile ? "20px" : "40px",
    outline: 'none',
  };

  function handleClose() {
    setOpenModalEditableInvoice(false);
  };

  useEffect(() => {
    setIsTouched(JSON.stringify(initialTableDetails) !== JSON.stringify(tableDetails))
  }, [tableDetails])

  return (
    <div className="ContainerVendor" data-theme={themeSetting}>
      <Modal open={openModalEditableInvoice} onClose={handleClose} className="ModalVendorInfo">
        <Box sx={isExpand ? styleFS : style} data-theme={themeSetting}>
          <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "0 0 20px 0" }}>
            <div>
              <button
                className="btn-reset"
                onClick={() => setIsExpand(!isExpand)}
              >
                {isExpand
                  ? <img src={Collapse} width={isMobile ? 18 : 34} height={isMobile ? 18 : 34} />
                  : <img src={FullScreen} width={isMobile ? 18 : 34} height={isMobile ? 18 : 34} />}
              </button>
              <button className="btn-reset"
                onClick={() => {
                  setTableDetails(structuredClone(initialTableDetails));
                  handleClose();
                }}>
                <img src={Close} width={isMobile ? 18 : 34} height={isMobile ? 18 : 34} />
              </button>
            </div>
          </Box>

          <div className="invoice-table-title column-centered">
            <div className="invoice-table-title__title">{t("invoiceDetails")}</div>
            <div className="invoice-table-title__name">{petName || t("Name")}</div>
          </div>
          <div className="invoice-table-message column-centered" style={{ margin: "5vh 0 0 0" }}>{t("justClick")}</div>
          {isMobile
            ? <div className="tile-table-wrapper-modal">
              <div className="SlowShow policy-table-mobile">
                <div className="policy-table-mobile__header">
                  <div className="policy-table-mobile__back" style={tableDetails?.length < 2 ? { opacity: '0.5' } : {}}>
                    <div
                      className="button_red_mobile back margin-0"
                      onClick={decreasePageNumber}
                    >
                      <img src={ArrowLeft} alt="ArrowLeft" />
                    </div>
                  </div>
                  <div className="policy-table-mobile__img">
                    <img
                      src={PandaMain}
                      alt="Panda_Main_Logo"
                      className="main-logo-policy__mobile"
                    />
                  </div>
                  <div className="policy-table-mobile__next" style={tableDetails?.length < 2 ? { opacity: '0.5' } : {}}>
                    <div
                      className="button_red_mobile back margin-0"
                      onClick={increasePageNumber}
                    >
                      <img src={ArrowRight} alt="ArrowRight" />
                    </div>
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">{t("date")}</div>
                  <div
                    className="policy-table-mobile__right"
                    style={{ backgroundColor: "#FCE7D2" }}
                  >
                    <Input
                      value={tableDetails?.[currentClaim - 1]?.Date}
                      sx={{
                        color: "#312f2f",
                        fontFamily: "Rooneysans",
                        fontWeight: 600,
                        fontVariantNumeric: "lining-nums proportional-nums",
                        opacity: (initialTableDetails[currentClaim - 1]?.["Date"] !== tableDetails?.[currentClaim - 1]?.Date) ? 1 : 0.5,
                      }}
                      disableUnderline
                      onChange={(e) => handleUpdate(currentClaim - 1, "Date", e)}
                    />
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">{t("desc")}</div>
                  <div
                    className="policy-table-mobile__right"
                    style={{ backgroundColor: "#FCE7D2" }}
                  >
                    <Input
                      value={tableDetails?.[currentClaim - 1]?.Description}
                      sx={{
                        color: "#312f2f",
                        fontFamily: "Rooneysans",
                        fontWeight: 600,
                        fontVariantNumeric: "lining-nums proportional-nums",
                        opacity: (initialTableDetails[currentClaim - 1]?.["Description"] !== tableDetails?.[currentClaim - 1]?.Description) ? 1 : 0.5,
                      }}
                      disableUnderline
                      multiline
                      maxRows={4}
                      onChange={(e) => handleUpdate(currentClaim - 1, "Description", e)}
                    />
                  </div>
                </div>
                {mode === USER_MODE.ADMIN &&
                  <div className="policy-table-mobile__row">
                    <div className="policy-table-mobile__left">{t("got")}</div>
                    <div
                      className="policy-table-mobile__right"
                      style={{ backgroundColor: "#FCE7D2" }}
                    >
                      <CustomSelect
                        variant="modal-editable-invoice"
                        options={gotList}
                        formatCreateLabel={(i) => i.label}
                        placeholder={tableDetails?.[currentClaim - 1]?.GOT || "-"}
                        value={tableDetails?.[currentClaim - 1]?.GOT || ""}
                        onChange={(e) => handleUpdate(currentClaim - 1, "GOT", e.value)}
                        isClearable={false}
                        isOpacity={initialTableDetails[currentClaim - 1]?.["GOT"] !== tableDetails?.[currentClaim - 1]?.GOT ? 1 : 0.5}
                      />
                    </div>
                  </div>}
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">{t("quantity")}</div>
                  <div
                    className="policy-table-mobile__right"
                    style={{ backgroundColor: "#FCE7D2" }}
                  >
                    <Input
                      sx={{
                        color: "#B83739",
                        fontFamily: "Rooneysans",
                        fontWeight: 600,
                        fontVariantNumeric: "lining-nums proportional-nums",
                        opacity: (initialTableDetails[currentClaim - 1]?.["Quantity"] !== tableDetails?.[currentClaim - 1]?.Quantity) ? 1 : 0.5,
                      }}
                      disableUnderline
                      value={tableDetails?.[currentClaim - 1]?.Quantity}
                      type="number"
                      align="center"
                      onChange={(e) => handleUpdate(currentClaim - 1, "Quantity", e)}
                    />
                  </div>
                </div>
                <div className="policy-table-mobile__row">
                  <div className="policy-table-mobile__left">{t("amount")}</div>
                  <div
                    className="policy-table-mobile__right CellAmountRed"
                    style={{ backgroundColor: "#FCE7D2" }}
                  >
                    <Input
                      sx={{
                        color: "#B83739",
                        fontFamily: "Rooneysans",
                        fontWeight: 600,
                        fontVariantNumeric: "lining-nums proportional-nums",
                        opacity: (initialTableDetails[currentClaim - 1]?.["Amount"] !== tableDetails?.[currentClaim - 1]?.Amount) ? 1 : 0.5,
                      }}
                      disableUnderline
                      value={tableDetails?.[currentClaim - 1]?.Amount}
                      type="number"
                      align="center"
                      onChange={(e) => handleUpdate(currentClaim - 1, "Amount", e)}
                      startAdornment={
                        <InputAdornment position="start">
                          {tableDetails?.[currentClaim - 1]?.Currency == "$" ? "$" : "€"}
                        </InputAdornment>
                      }
                    />
                  </div>
                </div>
                {tableDetails?.length > 1 &&
                  <div className="policy-table-mobile__pagination">
                    <Pagination
                      className="reset-pagination"
                      count={tableDetails?.length}
                      page={currentClaim}
                      siblingCount={1}
                      boundaryCount={1}
                      onChange={handlePage}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowLeftRed, next: ArrowRightRed }}
                          {...item}
                        // sx={{
                        //   color: '#FFF',
                        //   backgroundColor: item.type === 'page' && item.page === currentClaim ? '#FE4B4E' : '#DF9F9B',
                        // }}
                        />
                      )}
                    />
                  </div>}
              </div>
            </div>
            : <TableRestyle
              initialTableDetails={initialTableDetails}
              tableDetails={tableDetails}
              handleUpdate={handleUpdate}
              editValues={true}
              selectedTableDetails={selectedTableDetails}
              resultTableDetails={resultTableDetails}
              gotList={gotList}
              handleNew={handleNew}
            />}
          <div className="invoice-modal-buttons">
            {isMobile &&
              <div
                className={`button_red_invoice ${isMobileOnly ? "mobile" : ""}`}
                onClick={() => handleNew()}
              >
                {t("addNewLine")}
              </div>}
          </div>
          <div className="invoice-modal-buttons">
            <div
              className={`button_red_invoice ${isMobileOnly ? "mobile" : ""} ${isTouched ? "" : "disabled"}`}
              onClick={isTouched
                ? () => setTableDetails(structuredClone(initialTableDetails))
                : () => { }}
            >
              {t("resetChanges")}
            </div>
            <div
              className={`button_red_invoice ${isMobileOnly ? "mobile" : ""} ${isTouched ? "" : "disabled"}`}
              onClick={isTouched
                ? handleClose
                : () => { }}
            >
              {t("saveChanges")}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalEditableInvoice;
