import React, { useState, useEffect } from "react";
import PreviewFileModal from "./components/PreviewFileModal";
import PreviewFile from "./components/PreviewFile";
import MultipleFilesTable from "./components/MultipleFilesTable";
import GroupButton from "./components/GroupButton";
import AnalyzeButton from "./components/AnalyzeButton";
import Uploader from "./components/Uploader";

function InvoiceUpload({
  handleParagraphOcr,
  onDrop,
  ocrDetails,
  setOcrDetails,
  groupsList,
  setGroupsList,
  setDisableNext,
  setPicGroup,
  picGroup,
  history,
  setHistory,
}) {
  const [showOnePicture, setShowOnePicture] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileType, setFileType] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (ocrDetails?.picture.length === 0) {
      setDisableNext(true);
    }
    if (ocrDetails?.picture.length >= 1) {
      setDisableNext(false);
    }
  }, [ocrDetails]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  return (
    <div className="flow-ocr-wrapper">
      <Uploader
        onDrop={onDrop}
        ocrDetails={ocrDetails}
        groupsList={groupsList}
        showOnePicture={showOnePicture}
      />

      {/* <PreviewFile
        ocrDetails={ocrDetails}
        groupsList={groupsList}
        showOnePicture={showOnePicture}
      /> */}
      <MultipleFilesTable
        ocrDetails={ocrDetails}
        groupsList={groupsList}
        showOnePicture={showOnePicture}
        setGroupsList={setGroupsList}
        setOcrDetails={setOcrDetails}
        picGroup={picGroup}
        setPicGroup={setPicGroup}
        setFileType={setFileType}
        setSelectedFile={setSelectedFile}
        history={history}
        setHistory={setHistory}
      />
      <PreviewFileModal
        preview={preview}
        selectedFile={selectedFile}
        fileType={fileType}
        setSelectedFile={setSelectedFile}
      />
    </div>
  );
}

export default InvoiceUpload;
