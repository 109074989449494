import React from "react";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import { useTranslation } from "react-i18next";

function Status({ data }) {
  const { t } = useTranslation();
  return (
    <QuestionContainer>
      <div className="NavButtonContainer">
        <FormButtonFM name="Back" route="back" />

        <div className="Distance" />

        <FormButtonFM name="Finish" route="next" data={data} />
      </div>

      <div className="TitleQuestion">{t("answersCompleted")}</div>
    </QuestionContainer>
  );
}

export default Status;
