import React, { useEffect, useState } from "react";
import FormButtonFM from "./FormButtonFM";
import QuestionContainer from "./QuestionContainer";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Request } from "../../../../config/requests";
import useQuestionContainer from "../../../../hooks/useQuestionContainer";
import Loading from "../../../common/Loading";

function Payment({ data, setData, onUpdate, index }) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [questionContainer, buttonContainer] = useQuestionContainer();
  const [isPaymentLoading, setIsPaymentLoading] = useState(true);

  const [newPayment, setNewPayment] = useState({
    name: "",
    iban: "",
    checked: true,
  });
  const [previousPaypent, setPreviousPayment] = useState({
    name: "Name",
    iban: "000000000000",
    checked: false,
    visible: false,
  });

  // output -> new files { ...accountHolder, iban } for endpoint UPDATE
  // remove update-claim from flow
  // type add existing claim type

  const getPaymentMethods = () => {
    setIsPaymentLoading(true);
    Request({
      method: "get",
      route: `api/users/last_payment?policy_id=${id}`,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const previousPayment = res.data;

        if (previousPayment) {
          setPreviousPayment((prev) => ({
            ...prev,
            visible: true,
            checked: true,
            name: previousPayment?.accountHolder,
            iban: previousPayment?.iban,
          }));

          setNewPayment((prev) => ({
            ...prev,
            checked: false,
          }));
        }
      })
      .finally(() => {
        setIsPaymentLoading(false);
      });
  };

  const nextBtnHandler = async () => {
    const paymentData = previousPaypent.checked
      ? {
          paymentAccountHolder: previousPaypent.name,
          paymentIban: previousPaypent.iban,
        }
      : {
          paymentAccountHolder: newPayment.name,
          paymentIban: newPayment.iban,
        };

    onUpdate(index + 1, { paymentData });
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const isNextBtnEnabled =
    previousPaypent.checked ||
    (newPayment.checked && newPayment.name && newPayment.iban);

  return (
    <QuestionContainer>
      <Box style={{ width: "100%" }} ref={questionContainer}>
        <div className="NavButtonContainer" ref={buttonContainer}>
          <FormButtonFM name="Back" route="back" />

          <div className="Distance" />

          <FormButtonFM
            name="Next"
            route="next"
            onUpdate={nextBtnHandler}
            disabled={!isNextBtnEnabled}
          />
        </div>
        <Box className="payment-wrapper">
          <div className="invoice-table-title">
            <div className="invoice-table-title__title">{t("paymentTile")}</div>
          </div>
          <div
            className="invoice-table-message column-centered"
            style={{ margin: "5vh 0 0 0" }}
          >
            {t("paymentDescription")}
          </div>

          {isPaymentLoading ? (
            <Loading noText fast height={"500px"} />
          ) : (
            <FormGroup>
              <Box className="payment-cards__wrapper">
                {previousPaypent.visible && (
                  <label
                    className={`payment-cards__item payment-cards__item_previous ${
                      previousPaypent.checked
                        ? "payment-cards__item_checked"
                        : ""
                    }`}
                    style={{
                      backgroundColor: previousPaypent.checked
                        ? "#E3767A"
                        : "#FFF9F0",
                    }}
                  >
                    <RadioGroup
                      className="payment-cards__radio"
                      name="payment-radio-group"
                    >
                      <FormControlLabel
                        label=""
                        control={
                          <Radio
                            onClick={() => {
                              setPreviousPayment((prev) => ({
                                ...prev,
                                checked: true,
                              }));
                              setNewPayment((prev) => ({
                                ...prev,
                                checked: false,
                              }));
                            }}
                            checked={previousPaypent.checked}
                          />
                        }
                      />
                    </RadioGroup>
                    <div className="payment-cards__inputs">
                      <TextField
                        id="payment-name"
                        label="Name"
                        value={previousPaypent.name || t("noInfo")}
                        style={{ pointerEvents: "none" }}
                        variant="standard"
                      />
                      <TextField
                        id="payment-iban"
                        label="IBAN"
                        value={previousPaypent.iban || t("noInfo")}
                        style={{ pointerEvents: "none" }}
                        variant="standard"
                      />
                    </div>
                  </label>
                )}

                <label
                  className={`payment-cards__item ${
                    newPayment.checked ? "payment-cards__item_checked" : ""
                  }`}
                  style={{
                    backgroundColor: newPayment.checked ? "#E3767A" : "#FFF9F0",
                  }}
                >
                  <RadioGroup
                    className="payment-cards__radio"
                    name="payment-radio-group"
                  >
                    <FormControlLabel
                      label=""
                      control={
                        <Radio
                          checked={newPayment.checked}
                          onClick={() => {
                            setPreviousPayment((prev) => ({
                              ...prev,
                              checked: false,
                            }));
                            setNewPayment((prev) => ({
                              ...prev,
                              checked: true,
                            }));
                          }}
                        />
                      }
                    />
                  </RadioGroup>
                  <div className="payment-cards__inputs">
                    <TextField
                      id="payment-name"
                      placeholder="Name"
                      value={newPayment.name}
                      onChange={(e) =>
                        setNewPayment((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                      variant="outlined"
                    />
                    <TextField
                      id="payment-iban"
                      placeholder="IBAN"
                      value={newPayment.iban}
                      onChange={(e) =>
                        setNewPayment((prev) => ({
                          ...prev,
                          iban: e.target.value,
                        }))
                      }
                      variant="outlined"
                    />
                  </div>
                </label>
              </Box>
            </FormGroup>
          )}
        </Box>
      </Box>
    </QuestionContainer>
  );
}

export default Payment;
