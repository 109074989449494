import React, { useEffect, useMemo, useRef, useState } from "react";
import TableContainerComponent from "./tableComponents/TableContainerComponent.js";
import TotalContainer from "./tableComponents/TotalContainer.js";
import Fuse from "fuse.js";
import { Request } from "../../../config/requests";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loading from "../../../components/common/Loading.js";
import {
  checkDetailsInit,
  CLAIM_PROPS,
  FUSE_INIT_DATA,
} from "../../../constants/ApplicationConstants.js";
import { DatePicker } from "@mui/x-date-pickers";
import { Button, TextField } from "@mui/material";
import moment from "moment";
import FormButton from "../../../components/forms/Forms/FormButton.js";
import { useTranslation } from "react-i18next";
import useLocalStorage from "use-local-storage";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFlowCurrentStep,
  updateFlowQuestions,
  updateFlowCompletion,
  setFlowLoading,
  updateUploadValues,
} from "../../../config/actions.js";
import { compareData, showToast } from "../../../config/helpers";
import i18n from "../../../lang/i18n.js";
import useQuestionContainer from "../../../hooks/useQuestionContainer.js";

function InvoiceTable({
  data,
  index,
  claimIdTemp,
  ocrDetails,
  handleInput,
  details,
  isEditMode = false,
  ocrKey,
}) {
  const { t } = useTranslation();
  const [localLoad, setLocalLoad] = React.useState(true);
  const [tableDetails, setTableDetails] = React.useState([]);
  const [gotMatch, setGotMatch] = React.useState([]);
  const [detailedPrepared, setDetailedPrepared] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalInfo, setOpenModalInfo] = React.useState(false);
  const [gotDetails, setGotDetails] = useState([]);
  const [checkDetails, setCheckDetails] = React.useState(checkDetailsInit);
  const [themeSetting] = useLocalStorage("theme", "panda");
  const [searchParams] = useSearchParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const currentStep = useSelector((s) => s.flow.flowCurrentStep);
  const currentPetInfo = useSelector((s) => s.flow.selectedPetInfo);
  const flowType = useSelector((s) => s.flow.flowType);
  const { id } = useParams();
  const [initialTableDetails, setInintialTableDetails] = React.useState([]);
  const ref = useRef(null);
  const [selectedTableDetails, setSelectedTableDetails] = useState([]);
  const [resultTableDetails, setResultTableDetails] = useState([]);
  const [gotList, setGotList] = useState([]);

  // console.log("IT > ocrDetails", ocrDetails)

  useEffect(() => {
    const options = [{ label: "-", value: "" }];
    gotDetails?.forEach((el) => {
      options.push({ label: el["GOT Nummer"], value: el["GOT Nummer"] });
    });
    setGotList(options);
  }, [gotDetails])

  function rowSelectHandler(row) {
    setSelectedTableDetails((prevDetails) =>
      prevDetails.map((item, index) => ({
        ...item,
        selected: index === row
          ? item.selected === true
            ? false
            : true
          : item.selected,
      }))
    );
  }

  useEffect(() => {
    if (tableDetails) {
      setSelectedTableDetails(tableDetails)
    }
  }, [tableDetails])

  useEffect(() => {
    if (selectedTableDetails) {
      setResultTableDetails(selectedTableDetails?.filter(el => el.selected !== true))
    }
  }, [selectedTableDetails])

  const [questionContainer, buttonContainer] = useQuestionContainer(localLoad);

  const fuse = useMemo(
    () => new Fuse(gotDetails, FUSE_INIT_DATA),
    [gotDetails]
  );

  const getGotDetails = () => {
    Request({ method: "get", route: "api/claims/got" })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setGotDetails(json.got);
      });
  };

  useEffect(() => {
    getGotDetails();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const IDate = ocrDetails.details?.InvoiceDate?.content;
    const d = IDate ? moment(IDate, "DD.MM.YYYY") : null;

    if (!ocrDetails.details) return;

    if (!detailedPrepared) {
      let tableDetailsPrep = [];

      for (var i = 0; i < ocrDetails?.details?.Items?.values?.length; i++) {
        let tempRow = ocrDetails.details.Items.values[i].properties;
        tempRow[CLAIM_PROPS.PET] = currentPetInfo.name;
        const id = "id" + Math.random().toString(16).slice(2)
        tempRow.IdToCompare = id
        tempRow.IsNewLine = false

        // Code below used before for pet names

        // tempRow[CLAIM_PROPS.PET] = currentPetInfo.attributes.name;
        // tempRow[CLAIM_PROPS.PET] = ocrDetails.ocr.petName
        //   ? ocrDetails.ocr.petName.toLocaleLowerCase()
        //   : ocrDetails.manual.pet &&
        //     ocrDetails.manual?.pet[0]?.toLocaleLowerCase();

        // Code below used before for datepickers

        // if (!tempRow.Date) {
        //   tempRow[CLAIM_PROPS.DATE] = d ? d.toDate() || null : null;
        // } else {
        //   if (tempRow[CLAIM_PROPS.DATE].content) {
        //     tempRow[CLAIM_PROPS.DATE] = moment(
        //       tempRow[CLAIM_PROPS.DATE]?.content
        //     ).toDate();
        //   }
        // }

        tempRow[CLAIM_PROPS.DATE] = tempRow[CLAIM_PROPS.DATE]?.content || tempRow[CLAIM_PROPS.DATE] || "";

        if (typeof tempRow.Description === "object") {
          tempRow.Description = tempRow.Description?.content;
        }
        tempRow.Quantity = tempRow.Quantity?.value || tempRow?.Quantity || 1;

        if (ocrDetails.tables.length > 0) {
          ocrDetails.tables[0].cells
            .filter((c) => c.kind === CLAIM_PROPS.CONTENT)
            .forEach((c) => {
              if (
                gotDetails
                  .map((g) =>
                    g[CLAIM_PROPS.GOT_NUMBER]
                      .toString()
                      .toLocaleLowerCase()
                      .replace(/[^a-zA-Z0-9]/g, "")
                  )
                  .includes(
                    c.content
                      .toString()
                      .toLocaleLowerCase()
                      .replace(/[^a-zA-Z0-9]/g, "")
                  )
              ) {
                if (
                  ocrDetails.tables[0].cells
                    .filter(
                      (mc) =>
                        mc.kind === CLAIM_PROPS.CONTENT &&
                        mc.rowIndex === c.rowIndex
                    )
                    .map((mc) => mc.content)
                    .includes(tempRow.Description) &&
                  c.content
                    .toString()
                    .toLocaleLowerCase()
                    .replace(/[^a-zA-Z0-9]/g, "") !==
                  tempRow.Quantity.toString().toLocaleLowerCase()
                ) {
                  tempRow.GOT = c.content
                    .toString()
                    .toLocaleLowerCase()
                    .replace(/[^a-zA-Z0-9]/g, "");
                }
              }
            });
        }

        if (!tempRow.GOT) {
          try {
            let resultSearch = fuse.search(tempRow.Description);
            if (resultSearch.length > 0) {
              tempRow.GOT = resultSearch[0].item[CLAIM_PROPS.GOT_NUMBER]
                .toString()
                .toLocaleLowerCase();
            }
          } catch (e) {
            console.log("Error: ", e);
          }
        }

        if (ocrDetails.details.Items.values[i].properties[CLAIM_PROPS.AMOUNT]?.value?.currencySymbol) {
          tempRow[CLAIM_PROPS.CURRENCY] = ocrDetails.details.Items.values[i].properties[CLAIM_PROPS.AMOUNT]?.value?.currencySymbol;
        } else {
          tempRow[CLAIM_PROPS.CURRENCY] = null;
        }

        [CLAIM_PROPS.UNIT_PRICE, CLAIM_PROPS.AMOUNT, CLAIM_PROPS.TAX].map(
          (k) => {
            if (ocrDetails.details.Items.values[i].properties[k]) {
              if (
                typeof ocrDetails.details.Items.values[i].properties[k] ===
                "object"
              ) {
                tempRow[k] = ocrDetails.details.Items.values[i].properties[k]?.value?.amount;
              } else {
                tempRow[k] = ocrDetails.details.Items.values[i].properties[k];
              }
            } else {
              tempRow[k] = 0;
            }
          }
        );

        if (!tempRow.UnitPrice) {
          tempRow.UnitPrice = tempRow.Amount / tempRow.Quantity;
        }

        if (
          (tempRow.UnitPrice + tempRow.Tax) * tempRow.Quantity ===
          tempRow.Amount ||
          tempRow.UnitPrice * 1.19 * tempRow.Quantity === tempRow.Amount
        ) {
          tempRow.Amount = tempRow.UnitPrice * tempRow.Quantity;
        }
        tableDetailsPrep.push(tempRow);
      }
      // console.log("IT > tableDetailsPrep", tableDetailsPrep)
      setTableDetails(tableDetailsPrep);
      console.log('table details prep');
      if(initialTableDetails.length === 0) {
        console.log('Setted new Initials');
        setInintialTableDetails(structuredClone(tableDetailsPrep));
      }

      let listGot = [];

      async function hanldeComparison() {
        let promiseComparison = new Promise((resolve, reject) => {
          try {
            Request({ method: "get", route: "api/claims/got" }).then((res) => {
              resolve(res.json());
            });
          } catch (e) {
            console.log("Error: ", e);
          }
        });
        let comparisonReturn = await promiseComparison;
        tableDetailsPrep.forEach((row, index) => {
          if (row.GOT) {
            let listRes = comparisonReturn.got.filter(
              (a) =>
                a[CLAIM_PROPS.GOT_NUMBER].toString().toLocaleLowerCase() ===
                row.GOT
            );
            if (listRes[0]) {
              if (listRes[0]["3-fach"] >= row[CLAIM_PROPS.AMOUNT]) {
                listGot.push({ index, match: true });
              } else {
                listGot.push({ index, match: false });
              }
            } else {
              listGot.push({ index, match: false });
            }
          } else {
            listGot.push({ index, match: false });
          }
          if (listGot.length === tableDetailsPrep.length) {
            setGotMatch(listGot);
            setLocalLoad(false);
          }
        });

        if (!tableDetailsPrep.length && !listGot.length) {
          setGotMatch(listGot);
          setLocalLoad(false);
        }

        Request({
          method: "post",
          route: "api/claims/update-claim",
          values: {
            id: claimIdTemp ? claimIdTemp : data.claimId,
            tableDetails: tableDetailsPrep,
            gotMatch: listGot,
          },
        });
      }
      hanldeComparison();

      let itemsTotal = tableDetailsPrep
        .map((d) => d.Amount)
        .reduce(function (a, b) {
          return a + b;
        }, 0);

      const percentFromTotal = (itemsTotal * 19) / 100;
      const localInvoiceTotal =
        Math.round((percentFromTotal + itemsTotal) * 100) / 100;
      const percentFromTotalRounded = Math.round(percentFromTotal * 100) / 100;

      let newTotalValues = { values: {}, data: false, test: {} };

      [
        CLAIM_PROPS.SUB_TOTAL,
        CLAIM_PROPS.TOTAL_TAX,
        CLAIM_PROPS.INVOICE_TOTAL,
      ].map((t) => {
        if (ocrDetails.details[t]) {
          newTotalValues.values[t] = ocrDetails.details[t]?.value?.amount;
          if ([CLAIM_PROPS.SUB_TOTAL, CLAIM_PROPS.INVOICE_TOTAL].includes(t)) {
            newTotalValues.data = true;
          }
        }
      });
      // Check local calculated with recognized one
      newTotalValues.test[CLAIM_PROPS.TOTAL] =
        itemsTotal == newTotalValues.values.SubTotal ||
        localInvoiceTotal == newTotalValues.values.InvoiceTotal;
      newTotalValues.test[CLAIM_PROPS.VAT] =
        percentFromTotalRounded == newTotalValues.values.TotalTax;
      if (
        newTotalValues.values.TotalTax &&
        newTotalValues.values.InvoiceTotal &&
        newTotalValues.values.SubTotal
      ) {
        if (
          newTotalValues.values.TotalTax.toString() ===
          (newTotalValues.values.InvoiceTotal - newTotalValues.values.SubTotal)
            .toFixed(2)
            .toString()
        ) {
          newTotalValues.test[CLAIM_PROPS.VAT] = true;
        } else {
          newTotalValues.values.TotalTax =
            newTotalValues.values.InvoiceTotal - newTotalValues.values.SubTotal;
          newTotalValues.test[CLAIM_PROPS.VAT] = false;
        }
      }
      setCheckDetails({
        ...checkDetails,
        total: newTotalValues,
      });
    }

    setDetailedPrepared(true);
  }, [ocrDetails]);

  function handleUpdate(index, key, event, editOnlyThis) {
    let newTableDetails = [...tableDetails];
    let val = null;
    if ([CLAIM_PROPS.PET, CLAIM_PROPS.DESCRIPTION, CLAIM_PROPS.DATE].includes(key)) {
      val = event.target.value;
    } else if ([CLAIM_PROPS.GOT].includes(key)) {
      val = event;

      // Code below used before for datepickers

      // } else if ([CLAIM_PROPS.DATE].includes(key)) {
      //   const date = new Date(event);
      //   const year = date.getFullYear();
      //   const month = String(date.getMonth() + 1).padStart(2, '0');
      //   const day = String(date.getDate()).padStart(2, '0');
      //   val = `${year}-${month}-${day}`;
    } else {
      val = (parseFloat(event.target.value) > 0) ? parseFloat(event.target.value) : 0;
      // logic below used for auto recalculation of amount

      // if (key === CLAIM_PROPS.UNIT_PRICE) {
      //   if (val > 0) {
      //     newTableDetails[index].Amount =
      //       Number((val * newTableDetails[index].Quantity).toFixed(2));
      //   } else {
      //     newTableDetails[index].Amount = 0
      //   }
      // } else if (key === CLAIM_PROPS.QUANTITY) {
      //   if (!editOnlyThis) {
      //     if (val > 0) {
      //       newTableDetails[index].Amount =
      //         Number((val * newTableDetails[index].UnitPrice).toFixed(2));
      //     } else {
      //       newTableDetails[index].Amount = 0
      //     }
      //   }
      // }
    }
    newTableDetails[index][key] = val;
    setTableDetails(newTableDetails);

    let itemsTotal = newTableDetails
      .map((d) => d.Amount)
      .reduce(function (a, b) {
        return a + b;
      }, 0);

    let newCheckDetails = { ...checkDetails.total };
    newCheckDetails.values.SubTotal = itemsTotal.toFixed(2);
    newCheckDetails.values.TotalTax = (itemsTotal * 0.19).toFixed(2);
    newCheckDetails.values.InvoiceTotal = (itemsTotal * 1.19).toFixed(2);

    setCheckDetails({
      ...checkDetails,
      total: newCheckDetails,
    });
  }

  function handleDelete(index) {
    let newTableDetails = [...tableDetails];
    newTableDetails.splice(index, 1);
    setTableDetails(newTableDetails);
    Request({
      method: "post",
      route: "api/claims/update-claim",
      values: {
        id: claimIdTemp ? claimIdTemp : data.claimId,
        tableDetails: newTableDetails,
      },
    });

    let itemsTotal = newTableDetails
      .map((d) => d.Amount)
      .reduce(function (a, b) {
        return a + b;
      }, 0);

    let newCheckDetails = { ...checkDetails.total };
    newCheckDetails.values.SubTotal = itemsTotal.toFixed(2);
    newCheckDetails.values.TotalTax = (itemsTotal * 0.19).toFixed(2);
    newCheckDetails.values.InvoiceTotal = (itemsTotal * 1.19).toFixed(2);

    setCheckDetails({
      ...checkDetails,
      total: newCheckDetails,
    });
  }

  function handleNew() {
    let newTableDetails = [...tableDetails];
    const id = "id" + Math.random().toString(16).slice(2)
    newTableDetails.push({
      Amount: 0,
      Description: "",
      Currency: null,
      Quantity: 1,
      Tax: "New line",
      UnitPrice: 0,
      Pet: currentPetInfo?.name,
      Date: "",
      GOT: "",
      IdToCompare: id,
      IsNewLine: true,
    });
    setTableDetails(newTableDetails);
    Request({
      method: "post",
      route: "api/claims/update-claim",
      values: {
        id: claimIdTemp ? claimIdTemp : data.claimId,
        tableDetails: newTableDetails,
      },
    });
  }

  async function approveTable() {
    try {
      // const tableDetailsToCompare = structuredClone(tableDetails)
      const comparedData = compareData(initialTableDetails, resultTableDetails);

      await Request({
        method: "post",
        route: "api/claims/update-claim",
        values: {
          id: claimIdTemp ? claimIdTemp : data.claimId,
          // tableDetails: tableDetails,
          tableDetails: comparedData,
        },
      });
      const updatedDetails = {
        ...ocrDetails, details: {
          ...ocrDetails.details,
          Items: {
            ...ocrDetails.details.Items, values: comparedData.map(el => {
              return { properties: el }
            })
          }
        }
      }
      dispatch(updateUploadValues({ [ocrKey]: updatedDetails }));
      if (flowQuestions.length - 1 === currentStep) {
        dispatch(setFlowLoading(true));
        const manual = flowQuestions.flat().map((i) => ({
          name: i.content.title,
          value: i.content.answer,
          selected: i.content.selected,
        }));
        const currentLanguage = i18n.language;
        const res = await Request({
          method: "post",
          route: `api/claims/update-claim?policy_id=${id}&lang=${currentLanguage}`,
          values: {
            id: searchParams.get("claimId"),
            type: flowType,
            approve: true,
            manual,
          },
        });
        const data = await res.json();
        if (data.flow && data.flow.data) {
          const content = data?.flow?.data.content.map((i, ind) => {
            const currData = data?.flow?.data.autofill[ind];
            i.group = currData.group;
            if (i.type === "upload") {
              i.content.ocr = currData.ocr;
              i.content.uniqueId = currData.uniqueId;
            }
            if (i.type === "dropdown") {
              i.content.selected = currData.selected;
            }
            i.content.isOcr = currData.isOcr;
            i.content.uploadId = currData.uploadId;
            i.content.defaultValue = currData.defaultValue;
            i.uniqueId = currData.uniqueId;
            i.content.groupTitle = currData.groupTitle;
            i.content.width = currData.width;
            return i;
          })
          const status = content[content.length - 1].content.value;
          localStorage.setItem("flowStatus", status);
          const d =
            content.filter(
              (i) => !["new_claim", "type", "status", "existing_claim", "trigger"].includes(i.type)
            ) || [];
          const groups = d.reduce((acc, i) => {
            return (acc = acc.includes(i.group) ? acc : [...acc, i.group]);
          }, []);
          const values = groups.map((i) => {
            return d.filter((j) => j.group === i && j.type !== 'existing_claim' && j.type !== 'trigger');
          }).filter(arr => arr.length > 0);
          dispatch(updateFlowQuestions(values));
          dispatch(updateFlowCurrentStep(0));
          dispatch(updateFlowCompletion(true));
        } else {
          dispatch(setFlowLoading(false));
          dispatch(updateFlowCurrentStep(0));
          showToast('The claim has been registered successfully');
          history('/flow-completed');
        }
        dispatch(setFlowLoading(false));
      } else {
        await handleInput([], index + 1, "manual");
        const updatedDetails = {
          ...ocrDetails, details: {
            ...ocrDetails.details,
            SubTotal: { ...ocrDetails.details.SubTotal, value: { ...ocrDetails.details.SubTotal.value, amount: checkDetails.total.values.SubTotal } },
            TotalTax: { ...ocrDetails.details.TotalTax, value: { ...ocrDetails.details.TotalTax.value, amount: checkDetails.total.values.TotalTax } },
            InvoiceTotal: { ...ocrDetails.details.InvoiceTotal, value: { ...ocrDetails.details.InvoiceTotal.value, amount: checkDetails.total.values.InvoiceTotal } },
            Items: {
              ...ocrDetails.details.Items, values: comparedData.map(el => {
                return { properties: el }
              })
            }
          }
        }
        dispatch(updateUploadValues({ [ocrKey]: updatedDetails }));
        dispatch(setFlowLoading(false));
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Previous version

  // async function approveTable() {
  //   try {
  //     await Request({
  //       method: "post",
  //       route: "api/claims/update-claim",
  //       values: {
  //         id: claimIdTemp ? claimIdTemp : data.claimId,
  //         tableDetails: tableDetails,
  //       },
  //     });
  //     await handleInput([], index + 1, "manual");
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const backHandler = () => {
    handleInput([], index - 1);
    const updatedDetails = {
      ...ocrDetails, details: {
        ...ocrDetails.details,
        SubTotal: { ...ocrDetails.details.SubTotal, value: { ...ocrDetails.details.SubTotal.value, amount: checkDetails.total.values.SubTotal } },
        TotalTax: { ...ocrDetails.details.TotalTax, value: { ...ocrDetails.details.TotalTax.value, amount: checkDetails.total.values.TotalTax } },
        InvoiceTotal: { ...ocrDetails.details.InvoiceTotal, value: { ...ocrDetails.details.InvoiceTotal.value, amount: checkDetails.total.values.InvoiceTotal } },
        Items: {
          ...ocrDetails.details.Items, values: tableDetails.map(el => {
            return { properties: el }
          })
        }
      }
    }
    dispatch(updateUploadValues({ [ocrKey]: updatedDetails }));
  }

  return (
    <>
      {localLoad && (
        <div className="QuestionContainer">
          <Loading noText subText={t("loaderSubText2")} />
        </div>
      )}

      {!localLoad && (
        <div className="InvoiceTableCointainer" ref={questionContainer}>
          {!isEditMode && (
            <div className="NavButtonContainer NavButtonContainer_initial" style={{ margin: "0" }} ref={buttonContainer}>
              <FormButton
                name="Back"
                route="back"
                handleInput={backHandler}
                // handleInput={handleInput}
                selection={[]}
                index={index}
                details={details.entry}
                condition={true}
              />
              <div className="Distance"></div>
              <FormButton
                name={flowQuestions.length - 1 === currentStep ? "Finish" : "Next"}
                route="next"
                handleInput={approveTable}
                selection={[]}
                index={index}
                details={details.entry}
                condition={true}
                ref={ref}
                hideNext={true}
              />
            </div>
          )}
          <TableContainerComponent
            data={data}
            gotDetails={gotDetails}
            gotMatch={gotMatch}
            tableDetails={tableDetails}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            handleNew={handleNew}
            themeSetting={themeSetting}
            setTableDetails={setTableDetails}
            selectedTableDetails={selectedTableDetails}
            resultTableDetails={resultTableDetails}
            initialTableDetails={initialTableDetails}
            rowSelectHandler={rowSelectHandler}
            gotList={gotList}
            ref={ref}
          />

          {/* Total section for previous table version */}

          {/* <TotalContainer
            ocrDetails={ocrDetails}
            checkDetails={checkDetails}
            tableDetails={tableDetails}
          /> */}
        </div>
      )}
    </>
  );
}

export default InvoiceTable;
