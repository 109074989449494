import React, { useState } from "react";
import ChatBubble from "../../assets/iconsSmall/chat_bubble.svg";
import ModalChatBubble from "../../features/claims/modals/ModalChatBubble";

export default function BubbleChat() {
  const [openModalChatBubble, setOpenModalChatBubble] = useState(false);

  return (
    <div className="chat-bubble">
      <img src={ChatBubble} alt="BubbleChat" onClick={() => setOpenModalChatBubble(true)} />
      {openModalChatBubble && (
        <ModalChatBubble
          openModalChatBubble={openModalChatBubble}
          setOpenModalChatBubble={setOpenModalChatBubble}
        />
      )}
    </div>
  );
}
