export const en = {
  myTransactions: "My Transactions",
  customerNumber: "Customer number",
  noTransactions: "There are no transactions",
  insurances: "Insurances",
  reportDamage: "Report damage",
  tryAsClient: "Try as a client",
  adminMode: "Admin mode",
  personalData: "Personal data",
  flowManagement: "Flow Management",
  auditClaims: "Audit Claims",
  myDamages: "My claims",
  protectedInsuranceArea: "Protected insurance area",
  loginInfo: `To view your insurance documents, please log in to our protected area. Here you will find, among other things, information on the subject of reporting a claim. After entering your e-mail address, you will receive an access link. Also you can send your Policy ID and get confirmation code on youre-mail address.`,
  // loginInfo: `Contact us to view your insurance documents
  //                      please visit our protected area. Find here
  //                      among other things, you will find information on the subject of claims reporting.
  //                      After entering your e-mail address you will receive one
  //                      access link.`,
  yourEmail: "Your new e-mail address",
  yourPassword: "Your password",
  requestLoginLink: "Request login link",
  pandaPetIns: "Panda pet insurance",
  pandaInsurTech: "Panda InsurTech GmbH",
  riskTaker: "Risk carrier",
  riskTakerAddress: `ELEMENT Insurance AG
             <br />
             Saarbrücker Str. 37A
             <br />
             10405 Berlin
             <br />
             Germany`,
  commercial: "Commercial Register:",
  commercialRegister: `<span>HRB 182671B</span>
             <br />
             District Court of Charlottenburg
             <br />
             VAT ID: DE309829780`,
  member: "Members of the Board:",
  memberBoard: `<span>
               Dr. Astrid Stange (Chairwoman),
               <br />
               Dr. Michael Bongartz,
               <br />
               Philipp Hartz
             </span>
             <br />
             <strong>Chairman of the Supervisory Board:</strong>
             <br />
             Ralf Wohltmann`,
  imprint: "Imprint",
  dataProtection: "Data protection",
  contact: "Contact",
  faq: "FAQ",
  flows: "Flows",
  noFlowsCreateNew: "No flows. Create a new one!",
  diagnosis: "Diagnosis",
  got: "GOT",
  edit: "edit",
  delete: "delete",
  weWorkingClaims: "We are working on your claims.",
  goBackSeeHistory: `You can go back to the claims' history to see the status of your claims and fill any missing information.`,
  close: "Close",
  hallo: "Hello",
  fifiChipMissing: `Kelly's chip number is still missing.`,
  addDetails: "Add details",
  pandaPetHealth: "Panda Pet Health Insurance",
  productVariant: "Product variant: Panda full health insurance exclusive",
  active: "active",
  commOfIns: "Commencement of insurance",
  renewsOn: "Renews on",
  insNumber: "Insurance number",
  petHealthDesc: `Your pet health insurance protects you from them
                           Costs in case of a necessary operation or
                           Curative treatment (if agreed) due to illness
                           or accident of your animal.`,
  details: "Details",
  monthly: "Monthly",
  paymentInfo: "Payment information",
  transactionHis: "Transaction history",
  reimCost: "Reimbursement of costs in the event of a claim",
  insDog: "Insured dog",
  chipNumber: "Chip number",
  downloadDoc: "Download documents",
  showLess: "Show less",
  moreInfoDoc: "More information and documents",
  faqLong: "Frequently Asked Questions",
  selectInvoice: "Select the document",
  name: "Name",
  type: "Type",
  size: "Size",
  group: "Group",
  item_one: "item",
  item_other: "items",
  groupIntoOne: "Group into one claim",
  analyzeNow: "Analyze now!",
  preview: "Preview",
  back: "back",
  Back: "Back",
  Next: "Next",
  infoAboutDamage: "Information about the damage",
  date: "Date",
  time: "Time",
  invoiceNumber: "Invoice number",
  describeWhatHappen: "Please describe what happened as precisely as possible",
  specificInfo: "Specific information on the refund request",
  pet: "Pet",
  yourDogChip: `Your dog's microchip number`,
  whatTreatmentType: "What type of treatment did your dog receive?",
  whatTreatmentTakePlace: "When did the treatment take place?",
  preIdentifyDiag: "Pre-indentifed diagnosis (select one)",
  noneAbove: "None of the above",
  info: "Information",
  symptoms: "Symptoms",
  cause: "Cause(s)",
  confirmSelection: "Confirm selection",
  otherDiag: "Other diagnosis (select one)",
  diag: "Diagn",
  invoiceDate: "Date of invoice",
  desc: "Description",
  count: "Count",
  unitPrice: "Unit Price",
  total: "Total",
  addGot: "Add GOT",
  gotCheckPass: "GOT check passed.",
  gotCheckFailed: "GOT check failed.",
  addLine: "add a new line",
  gotSelection: "GOT Selection",
  noGotSelection: "No GOT selected",
  updateGot: "Update GOT",
  updateInfo: `If you update your information, the progress will not be saved and
            you will have to upload this invoice. You can also finish this step
            and update your information later.`,
  profilePage: "Profile page",
  updateVendorInfo: "Update vendor information",
  updateInformation: "Update information",
  paymentInformation: `In the event of a refund, payment should be made to the following
        account:`,
  iban: "IBAN",
  accountOwner: "Account owner",
  questionOrSuggestion: "Do you have a question or suggestion?",
  send: "Send",
  customerNum: "Customer No.",
  element: "ELEMENT",
  title: "Title",
  firstName: "First name",
  lastName: "Last name",
  address: "Address",
  street: "Street",
  houseNumber: "House number",
  postcode: "ZIP code",
  city: "City",
  birthDate: "Birth date",
  contactDetails: "Contact details",
  email: "E-mail address",
  phone: "Phone number",
  saveChanges: "Save changes",
  weAreAnalyzing: "We are analyzing your information",
  from: "From",
  to: "To",
  answer: "Answer",
  answersCompleted: "All answers in this flow were completed!",
  fillMissingInfo: "Please fill the missing information",
  taxAndName: "Tax ID and Name",
  status: "Status",
  moreDetails: "more details",
  id: "id",
  quantity: "Quantity",
  amount: "Amount",
  noClaims: "There are no claims",
  updateFlow: "Update flow",
  createFlow: "Create flow",
  goBack: "Go back",
  editView: "Edit view",
  zoom: "Zoom",
  zoomView: "Zoom view",
  yourInfo: "Your information",
  myPets: "My pets",
  yourInfoNotUpDate: "Are your information not up to date?",
  yourVendorInfo: `Your vendor's information`,
  editVendorInfo: `Edit your vendor's information`,
  taxId: "Tax ID",
  totalNet: "Total net",
  tax: "Tax",
  VatCheckPassed: "VAT check passed.",
  VatCheckFailed: "VAT check failed.",
  totalGross: "Total gross",
  invoiceTotalCheckPassed: "Invoice total check passed.",
  invoiceTotalCheckFailed: "Invoice total check failed.",
  inProgress: "in progress",
  fillInfo: "fill the info",
  "complete the flow": "complete the flow",
  Finish: "Finish",
  lineItem_one: "line item",
  lineItem_other: "line items",
  about: "About",
  updateLogo: "Update logo",
  auditDuplicated: "Audit Duplicated",
  auditPrevention: "Audit Prevention",
  auditOCR: "Audit OCR",
  auditClaimsBack: "Back to audit page",
  policyID: "Policy ID",
  petName: "Pet name",
  veterinarian: "Vet name",
  match: "Match",
  totalTax: "Tax",
  claimsPendingReview: "Claims pending review",
  claimsReviewed: "Claims reviewed",
  checked: "Checked",
  identifiedSuccess: "Identified correctly",
  identifiedWrong: "Identified wrong",
  petTriggerTooltip:
    "Please write the word which helped you to identify the pet",
  chipTriggerTooltip:
    "Please write the word which helped you to identify the chipnummer",
  comment: "Comment",
  isOfPending: "claims is pending out of",
  allCompared: "All items compared.",
  yes: "Yes",
  no: "No",
  rate: "Success rate",
  empty: "Empty",
  allDamages: "All Damages",
  search: "Search",
  confirm: "Confirm",
  invoiceInfo: "Invoice info",
  petInfo: "Pet info",
  chipNumberTrigger: "Chip trigger word",
  petNameTrigger: "Pet name trigger word",
  incidentDate: "Incident Date",
  gender: "Gender",
  breed: "Breed",
  firstBreed: "First breed",
  secondBreed: "Second breed",
  damage: "Damage",
  damageLocation: "Damage location",
  seeInitDoc: "See initial document",
  seeLineItems: "See line items",
  duplTrue: "Duplicate true",
  prevTrue: "Prevention true",
  duplFalse: "Duplicate false",
  prevFalse: "Prevention false",
  confirmed: "Confirmed!",
  ocrLoaded: "OCR loaded.",
  update: "Update",
  totalInfo: "Total info",
  itemInfo: "Itemized info",
  preventionEmpty: "No more items.",
  Category: "Category",
  Trigger: "Trigger",
  actions: "Actions",
  Options: "Options",
  BackToFlow: "Back to flow",
  createDropdown: "Create dropdown",
  editDropdown: "Edit dropdown",
  Logout: "Logout",
  Font: "Font",
  Settings: "Settings",
  PrimaryColor: "Primary color",
  SecondaryColor: "Secondary color",
  TreatmentType: "Treatment type",
  TreatmentDate: "Treatment date",
  vendorTaxId: "Vendor Tax ID",
  vendorAddress: "Vendor address",
  save: "Save",
  ShowUnread: "Show unread messages",
  areYouSure: "Are you sure?",
  itMight:
    "It might restart the claim process and hence delay the adjudication.",
  Cancel: "Cancel",
  editDamage: "Edit damage",
  Invoice: "Invoice",
  invoice: "invoice",
  payment: "Payment",
  vendorName: "Vendor name",
  add: "Add",
  question: "Question",
  done: "Done",
  notSure: "Not sure",
  width: "Width",
  recTriggers: "Recognized triggers",
  prevNotSure: "Prevention not sure",
  prevMixedBill: "Prevention mixed bill",
  mixedBill: "Mixed bill",
  English: "English",
  German: "German",
  diagnosisTrigger: "Diagnosis Trigger",
  allDatesSame: "Set all dates as first",
  claim: "Claim",
  noDiag: "No diagnose",
  askForEdit: "Do you want to change something?",
  vet: "Veterinarian",
  userMode: "User mode",
  getConfirmCode: "Get confirmation code",
  submitCode: "Submit code",
  confirmCode: "Confirmation code",
  confirmEmail: "Confirm your new e-mail",
  submitPolicy: "Submit policy",
  submitEmail: "Submit your e-mail",
  whichPet: "Which animal would you like to choose?",
  allAnimals: "All animals",
  uploadFoto: "Upload a picture of your your four-legged friend.",
  petProduct: "'s products",
  firstSight: "at a glance",
  backTo: "back to the ",
  Selection: "selection",
  changeData: `Change data`,
  krankenExklusiv: "Exclusive health insurance",
  insPremium: "Insurance premium",
  paymentMethod: "Payment method",
  changePayment: `<span>Change</span><br/><span>payment</span><br/>method<strong> ?</strong>`,
  whatCovered: `What is covered by your <span>tariff</span>?`,
  compensations: "Reimbursement of costs",
  annMaxOPs: "Annual maximum OPs",
  annMaxTreat: "Annual maximum treatments",
  waitingStart: "Waiting period from start of insurance",
  pandaBudget: "PANDA protection budget",
  changeContract: "Do you want to change your contract?",
  whatToDo: "What would you like to do?",
  accident: "Accident",
  prevention: "Prevention",
  treatment: "Treatment",
  preventionTreatment: "Prevention & Treatment",
  PreventionTreatment: "Prevention & Treatment",
  typeSelect: "Please select type of claim",
  noStatus: "No status",
  firstTrigger: "First trigger",
  secondTrigger: "Second trigger",
  preventionStatus: "Prevention status",
  noTrigger: "No trigger",
  noCategory: "No category",
  sendEmail: "Send email",
  callBack: "Request a callback",
  cdFirst: "Change",
  cdSecond: "data",
  sendBill: "submit new invoice",
  resendDocs: "Submit an additional document",
  costCalc: "Cost estimate",
  billDir: "Direct billing with vet/veterinary clinic",
  uploadPhoto: "upload a picture here",
  sendUsEmail: "Send us an e-mail to",
  replyAsap: "We will answer immediately!",
  yourName: "Your name",
  message: "Message",
  arrangeCall: "Arrange a call appointment",
  willDiscuss: "We will call you and discuss all your questions with you!",
  sendChoose: `Please just leave us your phone <span>number</span> and choose your <span>appointment</span>.`,
  yourPhone: "Your phone number",
  selectDate: "Select your preferred date",
  whatChange: "What would you like to change?",
  changeEmail: "Change email address",
  changeBD: "Change date of birth",
  changeElse: "Change something else",
  Next2: "Next",
  enterBD: "Enter your date of birth here:",
  enterEmail: "Enter your new e-mail address here:",
  Rechnungen: "Claims",
  rechnungen: "claims",
  AdditionalProperties: "Additional Properties",
  dateRangePlaceHolder: "Start - End",
  disabled: "Disabled",
  halfWidth: "Half width",
  fullWidth: "Full width",
  "Open question": "Open question",
  "New claim": "New Document",
  "New Document": "New Document",
  Type: "Type",
  "End flow": "End flow",
  "Multiple Choice Question": "Multiple Choice Question",
  Upload: "Upload",
  Date: "Date",
  "Invoice Table": "Invoice Table",
  "Date range": "Date range",
  "Existing claim": "Additional questions",
  "Additional questions": "Additional questions",
  "Default value": "Default value",
  values: "Values",
  Accident: "Accident",
  Prevention: "Prevention",
  "to be paid": "to be paid",
  "to reject": "to reject",
  "to review": "to review",
  selectDefaultValue: "Select default value",
  "Customer address": "Customer address",
  "Customer address recipient": "Customer address recipient",
  "Customer id": "Customer id",
  "Customer name": "Customer name",
  "Invoice date": "Invoice date",
  "Invoice id": "Invoice id",
  "Invoice total": "Invoice total",
  "Sub total": "Sub total",
  "Total tax": "Total tax",
  "Vendor address": "Vendor address",
  "Vendor address recipient": "Vendor address recipient",
  "Vendor name": "Vendor name",
  "Vendor tax id": "Vendor tax id",
  yourAttachments: "Your attachments",
  enter: "Enter your request HERE.",
  createNewApproach: "Create a new approach",
  sendRequest: "Send request",
  sendChange: "Send change request",
  otherRequest: "Other Request",
  enterDate: "Enter date",
  retriever: "Nova Scotia Duck Tolling Retriever",
  thankMsg: "We have received your request. You will receive feedback shortly.",
  thankMsgFooter: "The PANDA team wishes you a nice day!",
  seeDetails: "See the details of your product",
  callbackConfirmed: "Your request has been accepted. Wait for our call.",
  emailConfirmed: "Your message has been sent. Please wait for our reply.",
  notInvoice: "Not invoice",
  variants: "Variants",
  errorMsg: "Oops, something went wrong. Please try again.",
  Amount: "Amount",
  next: "next",
  loginWelcome: "Welcome to the PANDA Customer Portal",
  register: "Log in here with your e-mail address.",
  codeSent:
    "We have now sent you a login code to your email address. Please enter the code we sent to your email address here.",
  errorEmail: `ATTENTION!<br/>Please enter the email address you used when you purchased the insurance.<br/><br/>You can't remember your email address?`,
  errorAdminEmail: `ATTENTION!<br/>Please enter the email address you used when you purchased the insurance.<br/><br/>`,
  support: "Then contact our SUPPORT",
  supportContact: "Support contact",
  toSend: "send",
  tooltip1: `Castration/sterilisation is medically necessary in the presence of gynaecological or oncological diseases (e.g. inflammation or tumorous changes in the reproductive organs or other hormone-dependent tumours).<br/><br/>Otherwise reimbursement within the PANDA protection budget up to € 70 possible.`,
  tooltip2: `You have insured a tariff with XX% excess, so we can only reimburse XX% of your costs.`,
  tooltip3: `a) Preventive medical check-ups/ health checks/ blood tests;<br/>b) Dental prophylaxis (tooth cleaning/tartar removal);<br/>c) Worming, flea and tick prevention;<br/>d) Vaccinations;<br/>f) Castration or sterilisation without medical indication;<br/>g) Shortening the claws;<br/>h) Preparation of health certificates/appraisals;<br/>i) Therapeutic food (e.g. special food for allergies, vitamin supplements, diet food);<br/>k) Treatments due to pregnancy and delivery/birth (does not apply to breeding animals) with a special waiting period of 2 months from the start of insurance cover.<br/><br/>Your protection budget has already been used up in this insurance year.`,
  tooltip4: "Please note the special waiting times in the table below.",
  tooltip5: `Insurance ends on`,
  dataChangesConfirmed:
    "Your request has been sent. Please wait for our reply.",
  addDocument: "Add another document",
  pandaLoading: "Thank you! We are now processing this information.",
  month: "month",
  perInsYear: "per insurance year",
  tariffInfo: "Information about your tariff",
  specialWaitingPreiod: "Special waiting period of 12 months",
  notInsured: "Unfortunately, this is not insured",
  check1: "Hip joint dysplasia (HD), elbow dysplasia (ED)",
  li1: "Osteochondrosis dissecans (OCD)",
  li2: "Fractured coronoid process (FCP)",
  li3: "Isolated Processus Anconaeus (IPA)",
  li4: "Radius Curvus (Short Ulna Syndrome)",
  check2: "Heart disease, allergies and thyroid diseases",
  check3: "Gold acupuncture or prostheses/implants",
  check4: "Brachycephalic syndrome",
  check5: "Entropion",
  check6: "Ectropion",
  check7: "Umbilical hernia",
  check8: "Arthroses",
  check9: "Cryptorchidism",
  check10: "Epilepsy",
  check11: "Patellar luxation",
  check12: "Dachshund paralysis",
  cross1:
    "Illnesses that occurred before the start of the insurance or during the waiting period",
  cross2: "Jaw anomalies (e.g. persistent milk teeth)",
  cross3: "Benefits from pregnancy (e.g. emergency caesarean section)",
  cross4: "Castration or sterilisation that is not medically necessary",
  userLogin: "User Login",
  dateRangeError: "Please choose correct dates for date range",
  noInfo: "No information",
  claimSuccess: "The claim has been registered successfully",
  "Error, reloading...": "Error, reloading...",
  "Something going wrong...": "Something going wrong...",
  cancelContract: "Cancel contract",
  whichPetCancel: "For which animal would you like to cancel the contract?",
  whicContractCancel: "Which contract would you like to cancel?",
  selectOption: "Please select an option:",
  selectOption2: "Please select an option",
  reason1: "Cancellation at the end of the contract term",
  reason2: "Cancellation at the earliest possible date",
  reason3: "Cancellation for other reasons",
  reason4: "Revocation",
  hintModal: "If the contract is revoked/cancelled, you can no longer activate the contract.",
  hint: "Note",
  afterReason1: "The contract ends at the end of the contract, XX.XX.XXXX, subject to the contractually agreed cancellation period.",
  afterReason2: "The contract ends on XX.XX.XXXX.",
  afterReason3: "Please specify the circumstances:",
  selectHere: "Select here",
  opt1: "Animal given away",
  opt2: "Animal sold",
  opt3: "Animal handed over to animal welfare",
  opt4: "Animal deceased",
  opt5: "Policyholder deceased",
  opt6: "Moving abroad",
  opt7: "Miscellaneous",
  afterReason2start: "Are you sure you want to cancel",
  afterReason2end: "your contract?<br/>You can no longer reactivate it afterwards.",
  emailSent: "We have received your cancellation request. You will receive a confirmation by email shortly.",
  toStartPage: "To start page",
  pleaseUpload: "Please upload a verification document here:",
  uploadedDocuments: "Uploaded documents",
  uploadHere: "Store documents for upload HERE",
  uploadDocument: "Upload document",
  "1. Vers. Jahr": "1st ins. year:",
  "ab 2. Vers. Jahr:": "from 2nd ins. year:",
  "1 Monat": "1 month",
  "bei Unfall:": "In case of accident:",
  "keine": "none",
  invoiceUploadPlaceholder: "Place documents to upload here",
  invoiceUploadSubmitText: "Upload invoice",
  uploadedDocuments: "Uploaded documents",
  submitInvoice: "Submit invoice",
  submitDocument: "Submit document",
  whichPetContinue: "For which animal would you like to continue?",
  myProducts: "My products",
  myInvoices: "My claims",
  quantity2: "Quantity",
  pleaseCheckData: "Please check whether the data has been recognised correctly and completely. If not, please correct and/or complete the data. Then please confirm that the data is correct.",
  pleaseSelectClaim_G: "Please select the claim for which the document is to be submitted.",
  pleaseSelectClaim_CE: "Please select the claim for which the cost estimation is to be submitted.",
  invoiceDetails: "Invoice details",
  attention: "*Attention, there are legal consequences if data is knowingly provided incorrectly.",
  correctData: "Correct data",
  resetChanges: "Reset changes",
  pleaseRemove: "Please remove the items that you do NOT want us to refund",
  areNotRefund: "Are there any invoice items that we should NOT refund?",
  invoicesForVerification: "These invoice items are forwarded for verification",
  justClick: "Please simply click in the fields to correct the data.",
  missingDiag: "Diagnosis missing",
  missingChip: "Chip number missing",
  cantFindDiag: "Unfortunately, we couldn't find a diagnosis on your invoice, but we need it to process your claim. Is the diagnosis included on your invoice?",
  cantFindChip1: "Unfortunately, we are unable to determine the chip number",
  cantFindChip2: " stored in your contract on the vet invoice. Please send us a vet invoice with the correct chip number. Does the vet invoice already contain the correct chip number?",
  hasDuplicate1: "This invoice has already been submitted and is being processed under service case number ",
  hasDuplicate2: "Has data – such as diagnosis or chip number – been added?",
  sureReuploadClaim: "Are you sure you still want to resubmit the invoice?",
  thankModal: "Thank you very much. If you have any questions about the processing status of an invoice that has already been submitted, you can view this under “My claims“.",
  customerPortal: "Customer portal",
  thankForUpload_G: "Thank you for uploading your invoice!",
  thankForUpload_G_few: "Thank you for uploading your invoices!",
  thankForUpload_CE: "Thank you for uploading your cost estimation!",
  thankForUpload_CE_few: "Thank you for uploading your cost estimations!",
  thankForUpload_LC: "Thank you for uploading your document!",
  thankForUpload_LC_few: "Thank you for uploading your documents!",
  nowProcessed_G: "This is now being processed by us and we will get back to you shortly.",
  nowProcessed_GV: "This is now being processed by us.",
  nowProcessed_EC: "We will forward it for processing.",
  nowProcessed_LC: "These are now being processed by us and we will get back to you shortly.",
  nowProcessed_CE: "This is now being processed by us and we will get back to you shortly.",
  loaderText: "Please wait a moment",
  loaderSubText: "Your documents are being uploaded.",
  loaderSubText_CE: "Your cost estimation are being uploaded.",
  loaderSubText2: "We are processing your document, this can take a few moments...",
  seeAdvice: "See advice",
  adjudication: "Adjudication recommendations",
  CostEstimation: "Cost estimation",
  costEstimation: "cost estimation",
  reportLiab: "Report liability claim",
  doesBelongExistingClaim1: "Does the ",
  doesBelongExistingClaim2: " belong to an existing claim?",
  confirmChangeData_1: "Thank you very much,",
  confirmChangeData_2: "We will forward your change request. We will contact you when the change has been made.",
  confirmChangeData_3: "The PANDA team wishes you a great day!",
  "Welches Dokument möchtest Du nachreichen?": "Which document do you want to submit?",
  "Your benefit cases": "Your benefit cases",
  addNewLine: "Add line",
  paymentTile: "Desired account for transfer",
  paymentDescription: "Which account should we transfer the payment to?",
  Payment: "Payment",
  chipWrong: "Incorrect chip number. It must contain 15 digits.",
  emailWrong: "Incorrect email.",
  confirmEmailWrong: "Please double-check your email and confirmation email. They do not match.",
  document: "document",
  claimType: "Claim type",
  "Cost estimation_": "Cost estimation",
  "Liability claim_": "Liability claim",
  "General to Vets_": "Direct billing with vet/veterinary clinic",
  "General_": "Invoice",
  docPreview: "Document preview",
  "Existing Claim_": "Existing Claim",
  duplicateTo: "Duplicate to: ",
  thankYouPageIngo: "It may turn out during the invoice verification that we cannot pay all the amounts invoiced by the vet in full because they are not covered or not covered in full under your contract (e.g. excess or annual maximum benefit). Once the invoice has been checked, you will receive a statement from us showing the amounts charged directly. If we were unable to pay the invoice in full, we will inform you of the amount to be reimbursed by you to the vet.",
  achtung: "Please note!",
  achtungChip_CE: "*Attention, your cost estimation must include a chip number!",
  achtungChip: "*Attention, your invoice must include a chip number!",
  LoginAsUser: "Login as user",
  Submit: "Submit",
  "Logout as user": "Logout as user",
  createTicket: "Create ticket",
  ticket: "Ticket",
  bug: "Bug",
  error: "Error",
  enhancements: "Enhancements",
  petPage: "Pet`s page",
  page: "Page",
  ticketConfirmed: "Ticket created",
  noteScreenshot: "Please note that the screesnhot of the page would be taken automatically.",
  Unbekannt: "Unknown",
  "Unbekannt": "Unknown",
  needChip: "We need the chip number to identify your pet in order to process your invoice. Please have this completed by your vet and upload the invoice again.",
  noClaimsToAdd: "You don't have any claims to add to yet.",
  claimChecks: "Claim checks",
  wasChipOnInvoice: "Was chipnmuber on invoice:",
  wasDiagOnInvoice: "Was diagnosis on invoice:",
  isItPolicyDR: "Is it policy date range:",
  isItDupl: "Is it a duplicate:",
  containPrevent: "Does it contain prevention:",
  canceled: "canceled",
  somethingWentWrong: "Something went wrong!",
  errorReloading: "Error, reloading...",
  successfullyUpdated: "Successfully updated!",
  maxRequstLimitReached: "Your last claim didn't completed yet, please try again later.",
  loadingFlowText: "We are loading data. It may take some time.",
  sendChangeRequest: "Send change request",
  submissionDate: "Submission date",
};

export const de = {
  submissionDate: "Datum der Einreichung",
  sendChangeRequest: "Änderungsanfrage senden",
  loadingFlowText: "Wir laden Daten. Es kann einige Zeit dauern.",
  maxRequstLimitReached: "Votre dernière demande n'a pas encore été traitée, veuillez réessayer plus tard.",
  successfullyUpdated: "Erfolgreich aktualisiert!",
  errorReloading: "Fehler, neu laden...",
  somethingWentWrong: "Etwas ist schief gelaufen!",
  canceled: "aufgehoben",
  containPrevent: "Enthält es Prävention:",
  isItDupl: "Ist es ein Duplikat:",
  isItPolicyDR: "Ist es der Datumsbereich der Police:",
  wasDiagOnInvoice: "Wurde die Diagnose im Leistungsfall gestellt:",
  wasChipOnInvoice: "War Chipnmuber auf dem Leistungsfall:",
  claimChecks: "Leistungskontrolle",
  noClaimsToAdd: "Sie haben bis jetzt noch keinen Leistungsfall hinzugefügt.",
  needChip: "Wir benötigen zur Bearbeitung Ihrer Rechnung die Chipnummer zur Identifizierung Ihres Tieres. Bitte lassen diese von Ihrem Tierarzt ergänzen und laden die Rechnung erneut hoch.",
  Unbekannt: "Unbekannt",
  "Logout as user": 'Als Benutzer abmelden',
  noteScreenshot: "Bitte beachten Sie, dass der Screenshot der Seite automatisch erstellt wird.",
  ticketConfirmed: "Ticket erstellt",
  page: "Seite",
  petPage: "Seite für Haustiere",
  enhancements: "Erweiterungen",
  error: "Fehler",
  bug: "Bug",
  ticket: "Ticket",
  createTicket: "Ticket erstellen",
  Submit: "Einreichen",
  LoginAsUser: "Melden Sie sich als Benutzer an",
  achtungChip: "*Achtung, Deine Rechnung muss eine Chipnummer beinhalten!",
  achtungChip_CE: "*Achtung, Deine Rechnung muss eine Chipnummer beinhalten!",
  achtung: "Achtung!",
  thankYouPageIngo: "Möglicherweise stellt sich bei der Rechnungsprüfung heraus, dass wir nicht alle vom Tierarzt verrechneten Beträge in voller Höhe bezahlen können, weil sie in Deinem Vertrag nicht oder nicht in voller Höhe gedeckt sind (z.B. Selbstbeteiligung oder Jahreshöchstleistung). Nach erfolgter Rechnungsprüfung erhältst Du von uns eine Aufstellung über die direktverrechneten Beträge. Falls wir die Rechnung nicht in voller Höhe bezahlen konnten, teilen wir Dir den von Dir an den Tierarzt zu erstattenden Betrag mit.",
  duplicateTo: "Duplizieren nach: ",
  docPreview: "Dokumentvorschau",
  "Existing Claim_": "Bestehende Leistungsfall",
  "General_": "Rechnung",
  "General to Vets_": "Direktverrechnung mit Tierarzt/Tierklinik",
  "Liability claim_": "Haftpflichtschaden",
  "Cost estimation_": "Kostenvoranschlag",
  claimType: "Art der Leistungsfalles",
  document: "Dokument",
  chipWrong: "Falsche Chipnummer. Sie muss 15 Ziffern enthalten.",
  emailWrong: "Falsche E-Mail.",
  confirmEmailWrong: "Bitte überprüfen Sie Ihre E-Mail und Ihre Bestätigungs-E-Mail. Sie stimmen nicht überein.",
  addNewLine: "Zeile hinzufügen",
  Payment: "Zahlung",
  paymentDescription: "An welches Konto sollen wir die Zahlung überweisen?",
  paymentTile: "Gewünschtes Konto für Überweisung",
  "Your benefit cases": "Deine Leistungsfälle",
  "Welches Dokument möchtest Du nachreichen?": "Welches Dokument möchtest Du nachreichen?",
  confirmChangeData_3: "Das PANDA Team wünscht einen schönen Tag!",
  confirmChangeData_2: "Wir leiten Deinen Änderungswunsch weiter. Wir melden uns bei Dir, wenn die Änderung durchgeführt wurde.",
  confirmChangeData_1: "Vielen Dank,",
  doesBelongExistingClaim1: "Gehört die ",
  doesBelongExistingClaim2: " zu einem bereits bestehenden Leistungsfall?",
  reportLiab: "Haftpflichtschaden melden",
  costEstimation: "Kostenvoranschlag",
  CostEstimation: "Kostenvoranschlag",
  adjudication: "Empfehlungen zur Adjudikation",
  seeAdvice: "Siehe Ratschläge",
  loaderSubText2: "Wir verarbeiten gerade Ihr Dokument, dies kann einige Momente in Anspruch nehmen…",
  loaderSubText_CE: "Dein Kostenvoranschlag wird gerade hochgeladen.",
  loaderSubText: "Deine Dokumente werden gerade hochgeladen.",
  loaderText: "Bitte warte einen kurzen Moment",
  nowProcessed_CE: "Dieser ist nun bei uns in Bearbeitung und wir melden uns in Kürze bei Dir.",
  nowProcessed_LC: "Diese sind nun bei uns in Bearbeitung und wir melden uns in Kürze bei Dir.",
  nowProcessed_EC: "Wir leiten diese zur Bearbeitung weiter.",
  nowProcessed_GV: "Diese ist nun bei uns in Bearbeitung.",
  nowProcessed_G: "Diese ist nun bei uns in Bearbeitung und wir melden uns in Kürze bei Dir.",
  thankForUpload_LC: "Danke für das Hochladen Deines Dokuments!",
  thankForUpload_LC_few: "Danke für das Hochladen Deiner Dokumente!",
  thankForUpload_CE: "Danke für das Hochladen Deines Kostenvorschlags!",
  thankForUpload_CE_few: "Danke für das Hochladen Deiner Kostenvoranschläge!",
  thankForUpload_G: "Danke für das Hochladen Deiner Rechnung!",
  thankForUpload_G_few: "Danke für das Hochladen Deiner Rechnungen!",
  customerPortal: "Kundenportal",
  thankModal: "Vielen Dank. Wenn Du Fragen zum Bearbeitungsstand einer bereits eingereichten Rechnung hast, kannst Du diesen unter “Meine Leistungsfälle” einsehen.",
  sureReuploadClaim: "Bist Du sicher, dass Du die Rechnung trotzdem erneut einreichen möchtest?",
  hasDuplicate1: "Diese Rechnung wurde bereits eingereicht und wird unter der Leistungsfallnummer ",
  hasDuplicate2: " bearbeitet. Wurden Daten – wie z.B. Diagnose oder Chipnummer ergänzt?",
  cantFindChip1: "Wir können auf der Rechnung leider nicht die im Vertrag hinterlegte Chipnummer",
  cantFindChip2: " feststellen.  Ist diese gespeicherte Chipnummer auf der Rechnung vorhanden?",
  cantFindDiag: "Wir konnten leider auf Deiner Rechnung keine Diagnose finden, diese benötigen wir aber zur Bearbeitung. Ist die Diagnose auf Deiner Rechnung enthalten?",
  missingChip: "Chipnummer fehlt",
  missingDiag: "Diagnose fehlt",
  justClick: "Bitte klicke einfach in die Felder, um Daten zu korrigieren.",
  invoicesForVerification: "Diese Rechnungsposten werden zur Prüfung weitergeleitet",
  areNotRefund: "Gibt es Rechnungsposten, die wir NICHT erstatten sollen?",
  pleaseRemove: "Bitte entferne jene Posten aus, die wir NICHT erstatten sollen",
  resetChanges: "Änderungen zurücksetzen",
  correctData: "Daten korrigieren",
  attention: "*Achtung, es hat rechtliche Folgen, wenn Daten wissentlich falsch angegeben werden.",
  invoiceDetails: "Rechnungsdetails",
  pleaseSelectClaim_G: "Bitte wähle den Leistungsfall aus, zu dem Du das Dokument nachreichen möchtest.",
  pleaseSelectClaim_CE: "Bitte wähle den Leistungsfall aus, zu dem Du die Folgerechnung nachreichen möchtest.",
  pleaseCheckData: "Bitte überprüfe, ob die Daten richtig und vollständig erkannt wurden. Wenn nein, dann bitte korrigiere und/oder ergänze die Daten. Danach bestätige bitte die Korrektheit der Daten.",
  quantity2: "Anzahl",
  myInvoices: "Meine Leistungsfälle",
  myProducts: "Meine Produkte",
  whichPetContinue: "Für welches Tier möchtest Du fortfahren?",
  submitDocument: "Dokument einreichen",
  submitInvoice: "Rechnung einreichen",
  uploadedDocuments: "Hochgeladene Dokumente",
  invoiceUploadSubmitText: "Rechnung hochladen",
  invoiceUploadPlaceholder: "Dokumente zum Hochladen HIER ablegen",
  "keine": "keine",
  "bei Unfall:": "bei Unfall:",
  "1 Monat": "1 Monat",
  "ab 2. Vers. Jahr:": "ab 2. Vers. Jahr:",
  "1. Vers. Jahr": "1. Vers. Jahr",
  uploadDocument: "Dokument hochladen",
  uploadHere: "Dokumente zum Hochladen HIER ablegen",
  uploadedDocuments: "Hochgeladene Dokumente",
  pleaseUpload: "Bitte lade hier ein Nachweisdokument hoch:",
  toStartPage: "Zur Startseite",
  emailSent: "Wir haben Deinen Kündigungswunsch erhalten. Du erhältst in Kürze eine Bestätigung per E-Mail.",
  afterReason2end: "kündigen möchtest?<br/>Du kannst ihn danach nicht mehr reaktivieren.",
  afterReason2start: "Bist Du sicher, dass Du Deinen Vertrag",
  opt7: "Sonstiges",
  opt6: "Umzug ins Ausland",
  opt5: "Versicherungsnehmer verstorben",
  opt4: "Tier verstorben",
  opt3: "Tier dem Tierschutz übergeben",
  opt2: "Tier verkauft",
  opt1: "Tier verschenkt",
  selectHere: "Hier auswählen",
  afterReason3: "Bitte gib die näheren Umstände an:",
  afterReason2: "Der Vertrag endet am XX.XX.XXXX.",
  afterReason1: "Der Vertrag endet unter Einhaltung der vertraglich geregelten Kündigungsfrist zum Vertragsende, dem XX.XX.XXXX",
  hint: "Hinweis",
  hintModal: "Wenn der Vertrag widerrufen/gekündigt wird, kannst Du den Vertrag nicht mehr aktivieren.",
  reason4: "Widerruf",
  reason3: "Kündigung aus sonstigen Gründen",
  reason2: "Zum nächstmöglichen Zeitpunkt kündigen",
  reason1: "Zum Ende der Vertragslaufzeit kündigen",
  selectOption: "Bitte wähle eine Option:",
  selectOption2: "Bitte wähle eine Option",
  whicContractCancel: "Welchen Vertrag möchtest Du kündigen?",
  whichPetCancel: "Für welches Tier möchtest Du den Vertrag kündigen?",
  cancelContract: "Vertrag kündigen",
  "Something going wrong...": "Irgendetwas läuft schief...",
  "Error, reloading...": "Fehler, neu laden...",
  claimSuccess: "Die Leistungsfall wurde erfolgreich registriert",
  noInfo: "Keine Informationen",
  userLogin: "Benutzer-Login",
  dateRangeError:
    "Bitte wählen Sie die korrekten Daten für den Datumsbereich aus",
  cross4: "Nicht medizinisch notwendige Kastration oder Sterilisation",
  cross3: "Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)",
  cross2: "Kieferanomalien (z.B. Persistierende Milchzähne)",
  cross1:
    "Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit angelegt sind",
  check12: "Dackellähmung",
  check11: "Patellaluxation",
  check10: "Epilepsie",
  check9: "Kryptorchismus",
  check8: "Arthrosen",
  check7: "Nabelbruch",
  check6: "Ektropium",
  check5: "Entropium",
  check4: "Brachyzephales Syndrom",
  check3: "Goldakupunktur bzw. Prothesen/Implantate",
  check2: "Herzerkrankungen, Allergien und Schild -drüsenerkrankungen",
  li4: "Radius Curvus (Short-Ulna-Syndrom)",
  li3: "Isolierter Processus Anconaeus (IPA)",
  li2: "Frakturierter Processus Coronoideus (FPC)",
  li1: "Osteochondrosis dissecans (OCD)",
  check1: "Hüftgelenksdysplasie (HD), Ellenbogen - dysplasie (ED)",
  notInsured: "Das ist leider nicht versichert",
  specialWaitingPreiod: "Besondere Wartezeit von 12 Monaten",
  tariffInfo: "Infos zu Deinem Tarif",
  perInsYear: "je Versicherungsjahr",
  month: "Monat",
  pandaLoading:
    "Wir danken Ihnen! Wir sind gerade dabei, diese Informationen zu bearbeiten.",
  addDocument: "Weiteres Dokument hinzufügen",
  dataChangesConfirmed:
    "Ihre Anfrage wurde abgeschickt. Bitte warten Sie auf unsere Antwort.",
  tooltip5: `Versicherung endet am`,
  tooltip4: "Bitte beachte die Besonderen Wartezeiten unten in der Tabelle.",
  tooltip3: `a) Vorsorgeuntersuchungen/ Gesundheitschecks/ Blutuntersuchungen;<br/>b) Zahnprophylaxe (Zahnreinigung/Zahnsteinent - fernung);<br/>c) Wurmkuren, Floh- und Zeckenvorsorge;<br/>d) Schutzimpfungen;<br/>f) Kastration oder Sterilisation ohne medizinische Indikation;<br/>g) Kürzen der Krallen;<br/>h) Erstellung von Gesundheitszeugnissen/Gutach - ten;<br/>i) Therapeutische Nahrung (z.B. Spezialfutter bei Allergien, Vitaminpräparate, Diätfutter);<br/>k) Behandlungen aufgrund von Trächtigkeit und Entbindung/Geburt (gilt nicht für Zuchttiere) mit besondere Wartezeit von 2 Monaten ab Versiche - rungsbeginn.<br/><br/>Dein Schutzbudget ist in diesem Versicherungsjahr bereits verbraucht.`,
  tooltip2: `Du hast einen Tarif mit XX% Selbstbehalt versichert, daher können wir nur XX% deiner Kosten ersetzen.`,
  tooltip1: `Medizinisch notwendig ist eine Kastration/Sterilisation bei Vor - liegen gynäkologischer oder on - kologischer Erkrankungen (z. B. Entzündungen oder tumoröse Veränderungen der Geschlechts - organe oder hormonabhängiger sonstiger Tumore).<br/><br/>Sonst Prüfung im Rahmen des PANDA Schutzbudgets bis zu 70 € möglich.`,
  toSend: "senden",
  supportContact: "Support Kontakt",
  support: "Dann kontaktiere HIER unseren SUPPORT",
  errorEmail: `ACHTUNG!<br/>Bitte gib hier die E-Mail-Adresse ein, die Du bei Abschluss der Versicherung angegeben hast.<br/><br/>Du kannst Dich nicht an Deine E-Mail Adresse erinnern?`,
  errorAdminEmail: `ACHTUNG!<br/>Bitte gib hier die E-Mail-Adresse ein, die Du bei Abschluss der Versicherung angegeben hast.<br/><br/>`,
  codeSent:
    "Wir haben Dir nun an Deine E-Mail Adresse einen Login Code gesendet. Bitte gib hier den Code ein, den wir an Deine E-Mail Adresse gesendet haben.",
  register: "Melde Dich hier mit Deiner E-Mail-Adresse an.",
  loginWelcome: "Herzlich willkommen im PANDA Kundenportal",
  next: "weiter",
  Amount: "Betrag",
  empty: "Leer",
  English: "Englisch",
  German: "Deutsch",
  errorMsg:
    "Ups, da ist etwas schief gelaufen. Bitte versuchen Sie es später noch einmal",
  emailConfirmed:
    "Ihre Nachricht wurde gesendet. Bitte warten Sie auf unsere Antwort.",
  callbackConfirmed:
    "Ihre Anfrage wurde angenommen. Warten Sie auf unseren Anruf.",
  seeDetails: "Sehen Sie die Details Ihres Produkts",
  thankMsg:
    "wir haben Deinen Anfrage erhalten.  Du wirst in Kürze eine Rückmeldung erhalten. ",
  thankMsgFooter: "Das PANDA Team wünscht einen schönen Tag!",
  yourAttachments: "Ihre Anhänge",
  "Vendor tax id": "Umsatzsteuer id",
  "Vendor name": "Name des Anbieters",
  "Vendor address recipient": "Adresse des Lieferanten Empfänger",
  "Vendor address": "Adresse des Anbieters",
  "Total tax": "Steuer insgesamt",
  "Sub total": "Zwischensumme",
  "Invoice total": "Rechnungsbetrag",
  "Invoice id": "Rechnung id",
  "Invoice date": "Datum der Rechnung",
  "Customer name": "Name des Kunden",
  "Customer id": "Kunde id",
  "Customer address recipient": "Kundenadresse Empfänger",
  "Customer address": "Adresse des Kunden",
  selectDefaultValue: "Standardwert auswählen",
  "to review": "zur Überprüfung",
  "to reject": "abzulehnen",
  "to be paid": "zu entrichten",
  Prevention: "Prävention",
  Accident: "Unfall",
  values: "Werte",
  "Default value": "Standardwert",
  "Existing claim": "Weitere Fragen",
  "Additional questions": "Weitere Fragen",
  "Date range": "Datumsbereich",
  "Invoice Table": "Tabelle der Rechnungen",
  Date: "Datum",
  Upload: "Hochladen",
  "Multiple Choice Question": "Multiple Choice Frage",
  "End flow": "Fluss beenden",
  Type: "Typ",
  "New claim": "Neues Dokument",
  "New Document": "Neues Dokument",
  "Open question": "Offene Frage",
  fullWidth: "Volle Breite",
  halfWidth: "Halbe Breite",
  disabled: "Behinderte",
  enterEmail: "Geben Sie hier Ihre neue E-Mail-Adresse ein:",
  enterBD: "Gebe hier Dein Geburtsdatum ein:",
  Next2: "Weiter",
  changeElse: "Etwas anderes ändern",
  changeBD: "Geburtsdatum ändern",
  changeEmail: "E-Mail Adresse ändern",
  whatChange: "Was möchtest Du ändern?",
  selectDate: "Deinen Wunschtermin auswählen",
  yourPhone: "Deine Telefonnummer",
  sendChoose: `Bitte hinterlasse uns einfach Deine <span>Telefonnummer</span> und wähle Deinen <span>Wunschtermin</span> aus.`,
  willDiscuss:
    "Wir rufen Dich an und besprechen gerne mit Dir alle Deine Fragen!",
  arrangeCall: "Anruftermin vereinbaren",
  message: "Nachricht",
  yourName: "Dein Name",
  replyAsap: "Wir antworten umgehend!",
  sendUsEmail: "Sende uns eine E-Mail an",
  uploadPhoto: "lade hier ein Bild hoch",
  billDir: "Direktverrechnung mit Tierarzt/Tierklinik",
  costCalc: "Kostenvoranschlag",
  resendDocs: "Dokument nachreichen",
  sendBill: "neue Rechnung einreichen",
  claim: "Leistungsfall",
  cdSecond: "ändern",
  cdFirst: "Daten",
  callBack: "Rückruf anfordern",
  sendEmail: "E-Mail senden",
  variants: "Varianten",
  notInvoice: "Keine Rechnung",
  noCategory: "Keine Kategorie",
  noTrigger: "Kein Auslöser",
  preventionStatus: "Status der Prävention",
  secondTrigger: "Zweiter Auslöser",
  firstTrigger: "Erster Auslöser",
  noStatus: "Kein Status",
  typeSelect: "Bitte wählen Sie die Art der Leistungsfall",
  prevention: "Prävention",
  accident: "Unfall",
  treatment: "Behandlung",
  preventionTreatment: "Prävention & Behandlung",
  PreventionTreatment: "Prävention & Behandlung",
  submitEmail: "E-Mail abschicken",
  submitPolicy: "ID-Politik einreichen",
  confirmEmail: "Bestätigen Sie Ihre neue E-Mail",
  confirmCode: "Bestätigungscode",
  submitCode: "Code einreichen",
  getConfirmCode: "Bestätigungscode erhalten",
  whatToDo: "Was möchtest Du tun?",
  changeContract: "Möchtest Du Deinen Vertrag ändern?",
  pandaBudget: "PANDA Schutzbudget",
  waitingStart: "Wartezeit ab Versicherungsbeginn",
  annMaxTreat: "Jahreshöchstleistung Behandlungen",
  annMaxOPs: "Jahreshöchstleistung OPs",
  compensations: "Kostenersatz",
  whatCovered: `Was ist in Deinem <span>Tarif</span> gedeckt?`,
  changePayment: `Zahlungs-<br/>weise<br/><span>ändern</span><strong> ?</strong>`,
  paymentMethod: "Zahlungsmethode",
  insPremium: "Versicherungsprämie",
  krankenExklusiv: "Krankenschutz Exklusiv",
  changeData: `Datenänderungswunsch`,
  Selection: "Auswahl",
  backTo: "zurück zur ",
  firstSight: "auf einen Blick",
  petProduct: "s Produkte",
  uploadFoto: "Lade hier ein Bild Deines Vierbeiners hoch.",
  allAnimals: "Alle Tiere",
  whichPet: "Welches Tier möchtest Du auswählen?",
  userMode: "Benutzermodus",
  vet: "Tierarzt",
  noDiag: "Keine Diagnose",
  add: "Hinzufügen",
  allDatesSame: "Alle Daten als erste einstellen",
  askForEdit: "Möchtest Du etwas ändern?",
  diagnosisTrigger: "Diagnose Auslöser",
  mixedBill: "Gemischte Rechnung",
  prevTrue: "Prävention wahr",
  prevFalse: "Prävention falsch",
  prevMixedBill: "Prävention gemischte Rechnung",
  prevNotSure: "Prävention nicht sicher",
  recTriggers: "Erkannte Auslöser",
  width: "Breite",
  notSure: "Nicht sicher",
  done: "Erledigt",
  question: "Frage",
  vendorName: "Name des Anbieters",
  payment: "Zahlung",
  Invoice: "Rechnung",
  invoice: "Rechnung",
  editDamage: "Leistungsfall bearbeiten",
  Cancel: "Abbrechen",
  itMight:
    "Dies könnte das Antragsverfahren wieder in Gang setzen und somit die Entscheidung verzögern.",
  areYouSure: "Sind Sie sicher?",
  ShowUnread: "zeige ungelesene Nachrichten an",
  save: "Speichern Sie",
  vendorAddress: "Adresse des Anbieters",
  vendorTaxId: "Steuer-ID des Verkäufers",
  TreatmentDate: "Datum der Behandlung",
  TreatmentType: "Art der Behandlung",
  SecondaryColor: "Sekundäre Farbe",
  PrimaryColor: "Primäre Farbe",
  Settings: "Einstellungen",
  Font: "Schriftart",
  Logout: "Abmeldung",
  createDropdown: "Dropdown erstellen",
  editDropdown: "Dropdown bearbeiten",
  BackToFlow: "Zurück zum Fluss",
  Options: "Optionen",
  actions: "Aktionen",
  Trigger: "Auslöser",
  Category: "Kategorie",
  preventionEmpty: "Keine weiteren Gegenstände.",
  itemInfo: "Aufgeschlüsselte Informationen",
  totalInfo: "Gesamt info",
  update: "Aktualisierung",
  ocrLoaded: "OCR geladen.",
  confirmed: "Bestätigt!",
  duplTrue: "Duplizieren wahr",
  duplFalse: "Duplizieren falsch",
  seeLineItems: "Siehe Einzelposten",
  seeInitDoc: "Siehe Ausgangsdokument",
  damageLocation: "Ort der Beschädigung",
  damage: "Leistungsfall",
  breed: "Rasse",
  firstBreed: "Erste Rasse",
  secondBreed: "Zweite Rasse",
  gender: "Geschlecht",
  incidentDate: "Datum des Vorfalls",
  petNameTrigger: "Kosename Auslösewort",
  chipNumberTrigger: "Chip-Triggerwort",
  petInfo: "Haustier-Infos",
  invoiceInfo: "Informationen auf der Rechnung",
  confirm: "Bestätigen Sie",
  search: "Suche",
  allDamages: "Alle Schäden",
  auditClaims: "Audit-Leistungsfälle",
  auditDuplicated: "Audit Dupliziert",
  auditPrevention: "Audit Prävention",
  auditOCR: "Audit OCR",
  rate: "Erfolgsquote",
  auditClaimsBack: "Zurück zur Audit-Seite",
  yes: "Ja",
  no: "Nein",
  allCompared: "Alle Artikel im Vergleich.",
  isOfPending: "Leistungsfälle anhängig ist aus",
  comment: "Kommentar",
  petTriggerTooltip:
    "Bitte schreiben Sie das Wort, das Ihnen geholfen hat, das Haustier zu identifizieren",
  chipTriggerTooltip:
    "Bitte schreiben Sie das Wort, das Ihnen geholfen hat, die Chipnummer zu identifizieren",
  identifiedWrong: "Falsch identifiziert",
  identifiedSuccess: "Korrekt identifiziert",
  checked: "Geprüft",
  claimsReviewed: "Überprüfte Leistungsfälle",
  claimsPendingReview: "Zu prüfende Leistungsfälle",
  totalTax: "Steuer",
  match: "Passen",
  veterinarian: "Name des Tierarztes",
  petName: "Name des Haustiers",
  policyID: "ID-Politik",
  updateLogo: "Logo aktualisieren",
  about: "Um",
  Finish: "Beenden",
  lineItem_one: "Einzelposten",
  lineItem_other: "Einzelposten",
  myTransactions: "Meine Transaktionen",
  "complete the flow": "runden den Ablauf ab",
  fillInfo: "Füllen Sie die Informationen aus",
  inProgress: "im Gange",
  invoiceTotalCheckPassed: "Rechnungssummenprüfung bestanden.",
  invoiceTotalCheckFailed: "Rechnungssummenprüfung fehlgeschlagen.",
  totalNet: "Total netto",
  totalGross: "Gesamtbrutto",
  VatCheckPassed: "Umsatzsteuerprüfung bestanden.",
  VatCheckFailed: "Mehrwertsteuerprüfung fehlgeschlagen.",
  tax: "Steuer",
  taxId: "Steuer ID",
  yourVendorInfo: "Die Informationen Ihres Anbieters",
  editVendorInfo: "Bearbeiten Sie die Informationen Ihres Anbieters",
  yourInfoNotUpDate: "Ihre Daten sind nicht aktuell?",
  customerNumber: "Kundennummer",
  myPets: "Meine Haustiere",
  yourInfo: "Ihre Informationen",
  zoomView: "Zoom-Ansicht",
  zoom: "Zoomen",
  goBack: "Geh zurück",
  editView: "Ansicht bearbeiten",
  updateFlow: "Fluss aktualisieren",
  status: "Status",
  noClaims: "Keine Leistungsfälle vorhanden",
  quantity: "Quantität",
  amount: "Menge",
  id: "id",
  moreDetails: "mehr Details",
  taxAndName: "Steuernummer und Name",
  answersCompleted: "Alle Antworten in diesem Flow wurden abgeschlossen!",
  fillMissingInfo: "Alle Antworten in diesem Flow wurden abgeschlossen!",
  from: "Aus",
  to: "Zu",
  answer: "Antworten",
  customerNum: "Kunden-Nr.",
  email: "E-Mail Addresse",
  weAreAnalyzing: "Wir analysieren Ihre Informationen",
  saveChanges: "Änderungen speichern",
  phone: "Telefonnummer",
  element: "ELEMENT",
  address: "Anschrift",
  street: "Straße",
  houseNumber: "Hausnummer",
  contactDetails: "Kontaktdaten",
  city: "Stadt",
  birthDate: "Geburtsdatum",
  postcode: "PLZ",
  noTransactions: "Es gibt keine Transaktionen",
  insurances: "Versicherungen",
  reportDamage: "Rechnung einreichen",
  tryAsClient: "Versuchen Sie es als Kunde",
  adminMode: "Admin-Modus",
  personalData: "Persönliche Daten",
  flowManagement: "Flussmanagement",
  myDamages: "Meine Leistungsfälle",
  protectedInsuranceArea: "Geschützter Versicherungsbereich",
  loginInfo: `Für die Einsicht Ihrer Versicherungsunterlagen melden Sie
                      sich bitte in unserem geschützten Bereich an. Hier finden
                      Sie unter anderem Informationen zum Thema Schadenmeldung.
                      Nach Angabe Ihrer E-Mail-Adresse erhalten Sie einen
                      Zugangslink. Sie können auch Ihre Policen-ID senden und 
                      einen Bestätigungscode an Ihre E-Mail-Adresse senden.`,
  yourEmail: "Ihre neue E-Mail Adresse",
  yourPassword: "Ihr Passwort",
  requestLoginLink: "Login Link anfordern",
  pandaPetIns: "Panda Tierversicherungen",
  pandaInsurTech: "Panda InsurTech GmbH",
  riskTaker: "Risikoträger",
  riskTakerAddress: `ELEMENT Insurance AG
            <br />
            Saarbrücker Straße 37A
            <br />
            10405 Berlin
            <br />
            Deutschland`,
  commercial: "Handelsregister (Commercial Register):",
  commercialRegister: `<span>HRB&nbsp;182671B</span>
            <br />
            Amtsgericht Charlottenburg
            <br />
            USt-IdNr: DE309829780`,
  member: "Mitglieder des Vorstandes:",
  memberBoard: `<span>
              Dr. Astrid Stange (Vorsitzende/Chairwoman),
              <br />
              Dr. Michael Bongartz,
              <br />
              Philipp Hartz
            </span>
            <br />
            <strong>
            Vorsitzende des Aufsichtsrates:
            </strong>
            <br />
            Ralf Wohltmann`,
  imprint: "Impressum",
  dataProtection: "Datenschutz",
  contact: "Kontakt",
  faq: "FAQ",
  createFlow: "Fluss erzeugen",
  flows: "Fließt",
  noFlowsCreateNew: "Keine Strömungen. Erstellen Sie eine neue!",
  diagnosis: "Diagnose",
  got: "GOT",
  edit: "bearbeiten",
  delete: "löschen",
  weWorkingClaims: "Wir arbeiten an Ihrem Leistungsfall.",
  goBackSeeHistory: `Sie können zum Verlauf der Leistungsfälle zurückkehren, um den Status Ihrer Leistungsfälle anzuzeigen Leistungsfälle und füllen Sie alle fehlenden Informationen aus.`,
  close: "Schließen",
  hallo: "Hallo",
  fifiChipMissing: "Die Chipnummer von Kelly fehlt noch.",
  addDetails: "Details ergänzen",
  pandaPetHealth: "Panda Tierkrankenversicherung",
  productVariant: "Produktvariante: Panda Vollkrankenschutz Exklusiv",
  active: "aktiv",
  commOfIns: "Versicherungsbeginn",
  renewsOn: "Erneuert sich am",
  insNumber: "Versicherungsnummer",
  petHealthDesc: `Ihre Tierkrankenversicherung schützt Sie vor den
                          Kosten im Falle einer notwendigen Operation oder
                          Heilbehandlung (sofern vereinbart) wegen Krankheit
                          oder Unfall Ihres Tieres.`,
  details: "Einzelheiten",
  monthly: "monatlich",
  paymentInfo: "Zahlungsinformation",
  transactionHis: "Transaktionsverlauf",
  reimCost: "Kostenerstattung im Leistungsfall",
  insDog: "Versicherter Hund",
  chipNumber: "Chipnummer",
  downloadDoc: "Dokumente herunterladen",
  showLess: "Weniger anzeigen",
  moreInfoDoc: "Weitere Informationen & Dokumente",
  faqLong: "Häufige gestellte Fragen",
  selectInvoice: "Wählen Sie das Dokument",
  name: "Name",
  type: "Typ",
  size: "Größe",
  group: "Gruppe",
  item_one: "Artikel",
  item_other: "Artikel",
  groupIntoOne: "In einem Anspruch gruppieren",
  analyzeNow: "Jetzt analysieren!",
  preview: "Vorschau",
  back: "zurück",
  Back: "Zurück",
  Next: "Weiter",
  infoAboutDamage: "Angaben zum schadenfall",
  date: "Datum",
  time: "Uhrzeit",
  invoiceNumber: "Rechnungsnummer",
  describeWhatHappen:
    "Bitte beschreiben Sie so genau wie möglich, was passiert ist",
  specificInfo: "1. Spezifische Angaben zum Erstattungsantrag",
  pet: "Haustier",
  yourDogChip: "Die Mikrochipnummer Ihres Hundes",
  whatTreatmentType: "Welche Art von Behandlung hat Ihr Hund erhalten?",
  whatTreatmentTakePlace: "Wann fand die Behandlung statt?",
  preIdentifyDiag: "Voridentifizierte Diagnose (eine auswählen)",
  noneAbove: "Nichts des oben Genannten",
  info: "Information",
  symptoms: "Symptome",
  cause: "Ursache(n)",
  confirmSelection: "Auswahl bestätigen",
  otherDiag: "Andere Diagnose (eine auswählen)",
  diag: "Diagnose",
  invoiceDate: "Rechnungsdatum",
  desc: "Beschreibung",
  count: "Zählen",
  unitPrice: "Einzelpreis",
  total: "Gesamt",
  addGot: "GOT hinzufügen",
  gotCheckPass: "GOT-Check bestanden",
  gotCheckFailed: "GOT-Prüfung fehlgeschlagen.",
  addLine: "füge eine neue Zeile hinzu",
  gotSelection: "GOT-Auswahl",
  noGotSelection: "Kein GOT ausgewählt",
  updateGot: "GOT aktualisieren",
  updateInfo: `Wenn Sie Ihre Informationen aktualisieren, wird der Fortschritt nicht gespeichert und
             Sie müssen diese Rechnung hochladen. Sie können diesen Schritt auch beenden
             und aktualisieren Sie Ihre Informationen später.`,
  profilePage: "Profilseite",
  updateVendorInfo: "Lieferanteninformationen aktualisieren",
  updateInformation: "Informationen aktualisieren",
  paymentInformation: `Im Falle einer Rückerstattung sollte die Zahlung wie folgt erfolgen
         Konto:`,
  iban: "IBAN",
  accountOwner: "Kontoinhaber",
  questionOrSuggestion: "Sie haben eine Frage oder Anregung?",
  send: "Abschicken",
  title: "Titel",
  firstName: "Vorname",
  lastName: "Nachname",
  attach: "Dokument hinzufügen",
  rechnungen: "Leistungsfälle",
  Rechnungen: "Leistungsfälle",
  AdditionalProperties: "Zusätzliche Eigenschaften",
  dateRangePlaceHolder: "Anfang - Ende",
  enter: "Gebe HIER Deine Anfrage ein.",
  createNewApproach: "Neuen Anahng erstellen",
  sendRequest: "Anfrage senden",
  sendChange: "Änderungswunsch senden",
  otherRequest: "Andere Anfrage",
  enterDate: "Datum eingeben",
  retriever: "Nova Scotia Duck Tolling Retriever",
};
