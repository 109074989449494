import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowLeft from "../../assets/PetPage/arrow_left_dashed_small.svg";
import ArrowRight from "../../assets/PetPage/arrow_right_dashed.svg";
import PandaMain from "../../assets/UserInfo/panda-logo-main.svg";
import { Pagination, PaginationItem } from "@mui/material";
import { ReactComponent as ArrowLeftRed } from "../../assets/iconsSmall/arrow_left_solid_red.svg";
import { ReactComponent as ArrowRightRed } from "../../assets/iconsSmall/arrow_right_solid_red.svg";
import { TileTableContext } from "../../config/context";

export const TileRow = ({ children, label, red }) => (
  <div className="policy-table-mobile__row">
    <div className="policy-table-mobile__left">{label}</div>
    <div className={`policy-table-mobile__right ${red ? "CellAmountRed" : ""}`}>{children}</div>
  </div>
);

export default function TileTable({ data, children }) {
  const [currentClaim, setCurrentClaim] = useState(1);
  const { t } = useTranslation();

  const handlePage = useCallback((event, value) => {
    setCurrentClaim(value);
  }, []);

  const decreasePageNumber = () => {
    if (currentClaim > 1) {
      setCurrentClaim(currentClaim - 1);
    }
  };

  const increasePageNumber = () => {
    if (currentClaim < data.length) {
      setCurrentClaim(currentClaim + 1);
    }
  };

  return (
    <div className="SlowShow policy-table-mobile">
      <div className="policy-table-mobile__header">
        <div
          className="policy-table-mobile__back"
          style={data.length < 2 ? { opacity: "0.5" } : {}}
        >
          <div
            className="button_red_mobile back margin-0"
            onClick={decreasePageNumber}
          >
            <img src={ArrowLeft} alt="ArrowLeft" />
            {/* {t("back")} */}
          </div>
        </div>
        <div className="policy-table-mobile__img">
          <img
            src={PandaMain}
            alt="Panda_Main_Logo"
            className="main-logo-policy__mobile"
          />
        </div>
        <div
          className="policy-table-mobile__next"
          style={data.length < 2 ? { opacity: "0.5" } : {}}
        >
          <div
            className="button_red_mobile back margin-0"
            onClick={increasePageNumber}
          >
            {/* {t("next")} */}
            <img src={ArrowRight} alt="ArrowRight" />
          </div>
        </div>
      </div>
      <TileTableContext.Provider value={{ currentClaim }}>
        {children}
      </TileTableContext.Provider>
      {data.length > 1 && (
        <div className="policy-table-mobile__pagination">
          <Pagination
            className="reset-pagination"
            count={data.length}
            page={currentClaim}
            siblingCount={1}
            boundaryCount={1}
            onChange={handlePage}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowLeftRed, next: ArrowRightRed }}
                {...item}
                // sx={{
                //   color: '#FFF',
                //   backgroundColor: item.type === 'page' && item.page === currentClaim ? '#FE4B4E' : '#DF9F9B',
                // }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}
