import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import AuditClaims from "./auditClaims/AuditClaims";
import useLocalStorage from "use-local-storage";

export default function Management() {
  const [themeSetting] = useLocalStorage("theme", "panda");
  return (
    <div className="main-container">
      <Header />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto">
              <AuditClaims themeSetting={themeSetting} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
