import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateQuestionRoute,
  updateFlowCurrentStep,
  updateWtdModalOpen,
} from "../../../../config/actions";
import { useTranslation } from "react-i18next";
import { FMConstants } from "../../../../constants/Forms.js";
import useCompleteFlow from "../../../../hooks/useCompleteFlow.js";

function FormButtonFM({ name, route, onClick, disabled, onUpdate }) {
  const history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.flow.flowCurrentStep);
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const flowType = useSelector((s) => s.flow.flowType);
  const selectedFlowType = useSelector((s) => s.flow.selectedFlowTypeToSkip);
  const { compleFlow } = useCompleteFlow();

  const isLastQuestion = flowQuestions.length - 1 === currentStep;
  const lastQuestion = flowQuestions[currentStep];
  if (isLastQuestion && name === "Next" && lastQuestion.type !== "mcq") {
    name = "Finish";
  }
  const { id } = useParams();

  const onBackClicked = () => {
    if (currentStep < 1 || (currentStep === 1 && flowType === "Existing Claim" && selectedFlowType !== "")) {
      // history(`/pet/${id}`);
      history(`/`);
      dispatch(updateWtdModalOpen(true));
    } else {
      dispatch(updateFlowCurrentStep(currentStep - 1));
    }
  };

  const onNextClicked = async () => {
    await onUpdate(currentStep + 1);
  };

  async function onClickHandler() {
    dispatch(updateQuestionRoute(route));
    if (name === "Finish") {
      const current = flowQuestions[currentStep];
      const isLastStep = currentStep === flowQuestions.length - 1;
      const isLastStepUploadOcr =
        flowQuestions[currentStep][0]?.type === FMConstants.UPLOAD &&
        flowQuestions[currentStep][0]?.content?.ocr;

      if (current[0].type === "upload" || current[0].type === "payment") {
        await onNextClicked();
      }

      if (isLastStep && isLastStepUploadOcr) {
        // for last step OCR modals are shown
      } else {
        setTimeout(() => {
          compleFlow();
        }, 2000);
      }

      return;
    }
    if (route === "next") {
      // const current = flowQuestions[currentStep];
      // if (current[0].type === "mcq") onClick();
      // else onNextClicked();
      onNextClicked();
    } else onBackClicked();
  }

  return (
    <div
      className={`ButtonTransparent TemporaryStepsButton ${disabled ? "DisabledButton" : ""
        }`}
      onClick={onClickHandler}
    >
      {route === "back" && (
        <ArrowBackIcon sx={{ width: "1.7rem", height: "1.7rem" }} />
      )}
      <div>{t(name)}</div>
      {route !== "back" && (
        <ArrowForwardIcon sx={{ width: "1.7rem", height: "1.7rem" }} />
      )}
    </div>
  );

  // Old version below (until "Back" btn)

  // return (currentStep < 1 || (currentStep === 1 && flowType === "Existing Claim" && selectedFlowType !== "")) ? (
  //   <div
  //     className={`ButtonTransparent TemporaryStepsButton ${
  //       disabled ? "DisabledButton" : ""
  //     }`}
  //     onClick={onClickHandler}
  //   >
  //     {route !== "back" && <div>{t(name)}</div>}
  //     {route !== "back" && (
  //       <ArrowForwardIcon sx={{ width: "1.7rem", height: "1.7rem" }} />
  //     )}
  //   </div>
  // ) : (
  //   <div
  //     className={`ButtonTransparent TemporaryStepsButton ${
  //       disabled ? "DisabledButton" : ""
  //     }`}
  //     onClick={onClickHandler}
  //   >
  //     {route === "back" && (
  //       <ArrowBackIcon sx={{ width: "1.7rem", height: "1.7rem" }} />
  //     )}
  //     <div>{t(name)}</div>
  //     {route !== "back" && (
  //       <ArrowForwardIcon sx={{ width: "1.7rem", height: "1.7rem" }} />
  //     )}
  //   </div>
  // );
}

export default FormButtonFM;
