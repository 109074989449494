import { Skeleton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Welcome = () => {
  const { t } = useTranslation();
  const userInfo = useSelector((s) => s.form.user);

  return userInfo ? (
    <div className="question-flow-text">
      {t("hallo")},
      <br />
      <span>
        {userInfo.firstName} {userInfo.lastName}
      </span>
    </div>
  ) : (
    <div className="question-flow-text">
      <Skeleton variant="rounded" width={180} height={50} />
    </div>
  );
};

export default Welcome;
