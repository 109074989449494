import React from 'react';
import Modal from "@mui/material/Modal";
import Close from "../../assets/modal/close-x-white.svg";
import PandaHeader from "../../assets/modal/panda-header-logo.svg";
import PandaFooter from "../../assets/modal/panda-footer-logo.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateFlowCurrentStep } from '../../config/actions';

const ErrorModal = ({
    openModal,
    setErrorModal,
    errorMsg = "errorMsg",
    timeout = 3000,
    autoClose = true,
    isRedirect = true,
}) => {
    const { t } = useTranslation();
    const router = useNavigate();
    const dispatch = useDispatch();

    function handleClose() {
        setErrorModal(false);
    };

    if (autoClose) {
        setTimeout(() => {
            dispatch(updateFlowCurrentStep(0));
            if (isRedirect) router("/");
            handleClose();
        }, timeout)
    }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            className="custom_blured_background"
            sx={{ overflowY: "scroll" }}
        >
            <div className="modal_container" style={{ height: "100%" }}>
                <div className="modal_container__main">
                    <div className="modal_container__header">
                        <img className="modal_container__header__close" src={Close} onClick={handleClose} />
                        <img className="modal_container__header__logo" src={PandaHeader} />
                        {/*<div>Error</div>*/}
                    </div>
                    <div className="modal_container__body mt-5">
                        <p>{t(errorMsg)}</p>
                    </div>

                    <img className="modal_container__footer" src={PandaFooter} />
                </div>
            </div>
        </Modal>
    );
};

export default ErrorModal;