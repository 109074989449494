import { styled, Menu } from "@mui/material";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPopover-paper": {
    background: "none",
    boxShadow: "none",
  },
  "& .MuiMenu-list": {
    background: "none",
    color: "black",
    borderRadius: "10px",
    padding: "0",
  },
  "& .MuiMenuItem-root": {
    height: "35px",
    background: "#B83739",
    color: "#FFF",
    margin: "8px",
    borderRadius: "14px",
    fontFamily: "RooneySans",
    fontSize: "16px",
    boxShadow: "4px 8px 5px -3px rgba(0,0,0,0.2)",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiMenuItem-root:hover": {
    color: "#EB5955",
    background: "#FFF",
  },
}));

export const StyledTypeMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPopover-paper": {
    background: "none",
    boxShadow: "none",
  },
  "& .MuiMenu-list": {
    background: "none",
    color: "black",
    borderRadius: "10px",
    padding: "0",
  },
  "& .MuiMenuItem-root": {
    width: "200px",
    height: "35px",
    background: "#B83739",
    color: "#FFF",
    margin: "7px",
    borderRadius: "14px",
    fontFamily: "RooneySans",
    fontSize: "16px",
    boxShadow: "4px 8px 5px -3px rgba(0,0,0,0.2)",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const StyledContactMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPopover-paper": {
    background: "none",
    boxShadow: "none",
  },
  "& .MuiMenu-list": {
    background: "none",
    color: "black",
    borderRadius: "10px",
    padding: "0",
  },
  "& .MuiMenuItem-root": {
    position: "relative",
    height: "35px",
    width: "175px",
    color: "#FFF",
    background: "#B83739",
    margin: "8px 0px 8px 25px",
    padding: "0 0 0 25px",
    borderRadius: "0 14px 14px 0",
    fontFamily: "RooneySans",
    fontSize: "16px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  "& .MuiListItemIcon-root": {
    width: "40px",
    height: "40px",
    position: "absolute",
    left: "-25px",
    borderRadius: "50%",
    boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.25)",
  },
}));

export const TextFieldStyle = () => ({
  descriptionRoot: {
    paddingTop: "0px",
  },
  editRoot: {
    width: "80%",
  },
  editInput: {
    backgroundColor: "#FFF3E1",
    height: "20px",
    width: "100%",
    padding: "4px 4px 5px 4px",
    fontSize: "12px",
    borderRadius: "5px",
    border: "thin solid rgb(154, 154, 154)",
  },
  descriptionInput: {
    backgroundColor: "white",
    height: "20px",
    width: "200px",
    padding: "4px 4px 5px 4px",
    fontSize: "12px",
    borderRadius: "5px",
    border: "thin solid rgb(154, 154, 154)",
  },
  gotInput: {
    backgroundColor: "white",
    height: "31px",
    width: "100px",
    padding: "4px 4px 5px 4px",
    fontSize: "12px",
    borderRadius: "5px",
    border: "thin solid rgb(154, 154, 154)",
    textAlignLast: "right",
  },
  input: {
    backgroundColor: "white",
    height: "31px",
    width: "100px",
    padding: "4px 4px 5px 4px",
    fontSize: "12px",
    borderRadius: "5px",
    border: "thin solid rgb(154, 154, 154)",
    textAlignLast: "right",
  },
  select: {
    fontFamily: "DynaPuff",
    color: "#FE4B4E !important",
    backgroundColor: "white",
    height: "20px !important",
    fontSize: "12px",
    borderRadius: "5px",
    padding: "4px 4px 5px 4px",
    width: "100px",
    border: "thin solid rgb(154, 154, 154)",
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
  datePickerInput: {
    margin: "0px !important",
  },
  selection: {
    fontSize: "12px",
  },
  selectionSelected: {
    color: "#FE4B4E !important",
    backgroundColor: "#fe4b4e15 !important",
    fontFamily: "DynaPuff",
  },
  // rootTabLarge: {
  //     width: "250px",
  //     height: "calc(95vh - 200px)",
  //     borderTop: "#B6CBE1 solid thin",
  //     justifyContent: "flex-start",
  //     "& .MuiTabScrollButton-root": {
  //         color:"#FFF"
  //     },
  //     "& .MuiTab-root": {
  //         alignContent: "flex-start",
  //         alignItems: "flex-start",
  //     }
  // },
  // rootTabSmall: {
  //     width: "70px",
  //     height: "calc(95vh - 80px)",
  //     borderTop: "#B6CBE1 solid thin",
  //     justifyContent: "flex-start",
  //     "& .MuiTabScrollButton-root": {
  //         color:"#FFF"
  //     },
  //     "& .MuiTab-root": {
  //         alignContent: "flex-start",
  //         alignItems: "flex-start",
  //         paddingLeft: "0px",
  //     }
  // },
  // indicator: {
  //     width: "5px !important",
  //     backgroundColor: "#B6CBE1 !important"
  // },
  // activeTab: {
  //     alignContent: "flex-start",
  //     alignItems: "flex-start",
  //     paddingLeft: "10px",
  //     color: "#FFF",
  //     fontWeight: "bold",
  // },
  // otherTabs: {
  //     alignContent: "flex-start",
  //     alignItems: "flex-start",
  //     paddingLeft: "10px",
  //     color: "#B6CBE1",
  // },
  // rootIcon: {
  //     width: "30px"
  // }
});
